import { connect } from 'react-redux'
import {PreviousPatientConnector, PreviousPatientCardioConnector} from '../../../../shared/components/PreviousPatient'
import { BeforeYouScheduleOBGYNConnector } from '../../../../shared/components/SchedulingModal/OBGYN'
import ProviderCard from './ProviderCard.jsx'

const mapStateToProps = (state, ownProps) => {
  if (state.doctorSearch.disableFirstAvailable) {
    ownProps.options.displayAvailability = false
  }
  return {
    options: ownProps.options,
    flow: state.persistentData.schedulingType,
    distance: ownProps.provider.distance,
    pageNumber: state.doctorSearch.currentPage,
    sort: state.doctorSearch.sortOrder
  }
}

const mapDispatchToProps = (dispatch) => ({
  scheduleLinkClick: (url) => {
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: PreviousPatientConnector })
  },
  cardioScheduleLinkClick: (url) => {
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: PreviousPatientCardioConnector })
  },
  obgynScheduleLinkClick: (url, phone, provider) => {
    dispatch({ type: 'SET_DOCTOR', value: provider })
    dispatch({ type: 'SET_DOCTOR_PHONE', value: phone })
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: BeforeYouScheduleOBGYNConnector })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProviderCard)
