const AffiliatedDoctorSection = (props) => {
  return <div className={`affiliated-doctors-message`}>
    {
      props.docCount > 0 && props.appCount === 0 ? (props.docCount > 1
        ? <div>Your refined search returned 0 affiliated physicians, but there are <span className='provider-count'>{props.docCount} active physicians</span> who meet your criteria.</div>
        : <div>Your refined search returned 0 affiliated physicians, but there is <span className='provider-count'>1 active physician</span> who meets your criteria.</div>)
        : <div>The physicians listed below are affiliated with OhioHealth, but do not primarily practice at an OhioHealth hospital.</div>
    }
    {props.docCount > 0 && <div className='btn' onClick={props.toggleAffiliatedDoctors}>{`${props.docCount > 0 && props.appCount === 0 ? 'SEE ACTIVE PHYSICIANS' : 'BACK TO MAIN RESULTS'}`}</div>}
  </div>
}

export default AffiliatedDoctorSection
