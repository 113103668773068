import { connect } from 'react-redux'
import LookingFor from './LookingFor.jsx'

const mapStateToProps = (state, ownProps) => ({
  setLookingFor: ownProps.setLookingFor,
  lookingFor: ownProps.lookingFor,
  autoCompleteData: state.locationSearch.autoCompleteDataLocations,
  placeholder: 'Enter location name or location type',
  enterSubmit: ownProps.enterSubmit,
  isMobile: ownProps.mobile
})

export default connect(mapStateToProps)(LookingFor)
