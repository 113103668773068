import { race, put, take, delay } from 'redux-saga/effects'
import { TIMEOUT } from '../../shared/utility-functions/timeout.js'

export default function * timeoutSaga () {
  while (true) {
    yield take('START_TIMEOUT')

    const { userTimedOut, stopTimeout } = yield race({
      stopTimeout: take('STOP_TIMEOUT'),
      userTimedOut: delay(TIMEOUT)
    })

    if (userTimedOut) {
      yield put({ type: 'SESSION_EXPIRED' })
    } else if (stopTimeout) {
      continue
    }
  }
}
