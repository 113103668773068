import React from 'react'
import SVGInline from 'react-svg-inline'
import ChevronDown from '../../../../shared/assets/chevron-down.svg'
import '../ProviderCard/provider-card.scss'

const AffiliatedProviderCard = ({ enableAffiliatedDoctors }) => {
  return (
    <div className='provider-card-container affiliated-doctor-card'>
      <a onClick={enableAffiliatedDoctors}>
        <div className='see-other-doctors'>SEE OTHER DOCTORS AFFILIATED WITH OHIOHEALTH <SVGInline svg={ChevronDown} /></div>
      </a>
    </div>
  )
}

export default AffiliatedProviderCard
