import { createBrowserHistory } from 'history'
import { getSpecialtyID } from '../../shared/data-mappers/specialty-ids'

let base = window.location.pathname
base = base.replace(/\/$/, '')

const history = createBrowserHistory({
  basename: base
})

const listener = (location, action) => {
  if (action === 'PUSH') {
    window.scroll(0, 0)
  }
}

history.listen(listener)

export { listener }

export const convertSpecialtiesToIDs = (specialties) => {
  return specialties.map((specialty) => {
    return getSpecialtyID(specialty)
  })
}

export const convertSort = (sort) => {
  switch (sort) {
    case 'A TO Z':
      return 'az'
    case 'Z TO A':
      return 'za'
    case 'Distance':
      return 'distance'
    case 'Most Reviewed':
      return 'most-reviewed'
    case 'Top Rated':
      return 'top-rated'
    case 'Random':
      return 'rand'
    case 'First Available':
      return 'first-available'
    default:
      return 'az'
  }
}

export const convertAvailability = (availability) => {
  return availability.map((type) => {
    if (type === 'Doctors Accepting New Patients') {
      return 'anp'
    } else if (type === 'Doctors with Online Scheduling') {
      return 'oos'
    }
  })
}

// TODO Build method to Convert Hospitals to IDs

export const stringifyArrayParameters = (paramArray) => {
  let stringifiedArray = ''
  paramArray.map((param, index) => {
    if (index < paramArray.length - 1) {
      stringifiedArray = stringifiedArray += param += '|'
    } else {
      stringifiedArray = stringifiedArray += param
    }
  })
  return stringifiedArray
}

export const insertBrowserHistory = (paramsObject, page = null, id = null) => {
  let optionalParams = ''
  let params = paramsObject || {}
  let searchParams = params.search ? params.search : {}
  let filterParams = params.filters ? params.filters : {}
  let sortParam = params.sort ? params.sort : null
  let showAll = params.showAll ? params.showAll : null
  let paramsArray = []

  if (searchParams.q && searchParams.q.length) paramsArray.push('q=' + searchParams.q)
  if (searchParams.locq) paramsArray.push('locq=' + searchParams.locq)
  if (searchParams.lat) paramsArray.push('lat=' + searchParams.lat)
  if (searchParams.lng) paramsArray.push('lng=' + searchParams.lng)
  if (searchParams.dist) paramsArray.push('dist=' + searchParams.dist)
  if (searchParams.app) paramsArray.push('app=' + searchParams.app)
  if (searchParams.searchRadius) paramsArray.push('searchRadius=' + searchParams.searchRadius)
  if (showAll) paramsArray.push('showAll=true')

  if (filterParams.selectedAvailability && filterParams.selectedAvailability.length) paramsArray.push('accpat=' + stringifyArrayParameters(convertAvailability(filterParams.selectedAvailability)))
  if (filterParams.selectedRating && filterParams.selectedRating.length) paramsArray.push('rating=' + stringifyArrayParameters(filterParams.selectedRating))
  if (filterParams.selectedSpecialty && filterParams.selectedSpecialty.length) paramsArray.push('spec=' + stringifyArrayParameters(convertSpecialtiesToIDs(filterParams.selectedSpecialty)))
  if (filterParams.selectedDoctorType && filterParams.selectedDoctorType.length) paramsArray.push('doctype=' + stringifyArrayParameters(filterParams.selectedDoctorType))
  if (filterParams.selectedGender && filterParams.selectedGender.length) paramsArray.push('gender=' + stringifyArrayParameters(filterParams.selectedGender))
  if (filterParams.selectedLanguage && filterParams.selectedLanguage.length) paramsArray.push('lang=' + stringifyArrayParameters(filterParams.selectedLanguage))
  if (filterParams.selectedHospital && filterParams.selectedHospital.length) paramsArray.push('hosp=' + stringifyArrayParameters(filterParams.selectedHospital))
  if (filterParams.selectedAffiliation && filterParams.selectedAffiliation.length) paramsArray.push('affiliation=' + stringifyArrayParameters(filterParams.selectedAffiliation))
  if (filterParams.selectedTimeSlots && filterParams.selectedTimeSlots.length) paramsArray.push('times=' + stringifyArrayParameters(filterParams.selectedTimeSlots))
  if (filterParams.selectedAppointmentDate) paramsArray.push('apptDate=' + filterParams.selectedAppointmentDate)
  if (filterParams.selectedWeekendHours && filterParams.selectedWeekendHours.length) paramsArray.push('weekendHrs=' + stringifyArrayParameters(filterParams.selectedWeekendHours))
  if (filterParams.selectedProviders && filterParams.selectedProviders.length) paramsArray.push('providers=' + stringifyArrayParameters(filterParams.selectedProviders))
  if (filterParams.selectedLocationGroups && filterParams.selectedLocationGroups.length) paramsArray.push('groups=' + stringifyArrayParameters(filterParams.selectedLocationGroups))
  if (filterParams.selectedClassType && filterParams.selectedClassType.length) paramsArray.push('classType=' + stringifyArrayParameters(filterParams.selectedClassType))
  if (filterParams.selectedCategories && filterParams.selectedCategories.length) paramsArray.push('categories=' + stringifyArrayParameters(filterParams.selectedCategories))
  if (filterParams.selectedLocations && filterParams.selectedLocations.length) paramsArray.push('locations=' + stringifyArrayParameters(filterParams.selectedLocations))

  if (sortParam) paramsArray.push('sort=' + convertSort(sortParam))
  if (page) paramsArray.push('page=' + page)
  if (id) paramsArray.push('id=' + id)

  if (paramsArray.length) {
    paramsArray.map((segment, index) => {
      if (index === 0) {
        optionalParams = optionalParams.concat('?' + segment)
      } else {
        optionalParams = optionalParams.concat('&' + segment)
      }
    })
    window.history.pushState(null, null, (window.location.pathname + encodeURI(optionalParams)))
  } else {
    window.history.pushState(null, null, window.location.pathname)
  }
}

export default history
