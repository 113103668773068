import '../Credentials/credentials.scss'

const Credentials = ({ certifications, education }) => {
  const renderSpecialty = (specialty) => {
    return specialty.toLowerCase() !== 'no value specified' ? 'in ' + specialty : ''
  }
  const renderEducation = () => {
    let keys = []
    education.forEach(edu => {
      let found = keys.filter(key => key.type === edu.type)
      if (!found || !found.length) {
        keys.push({ type: edu.type, educationList: education.filter(e => e.type === edu.type) })
      }
    })
    return (
      keys.map((key, index) => {
        if (key.educationList && key.educationList.length > 0) {
          return (
            <div key={index} className='provider-credentials-text test-education'>
              <div className='test-type'><b>{key.type}:</b></div>
              <dl className=''>
                {
                  key.educationList.map((edu, index2) => {
                    return (
                      <div key={index2}>
                        <dd className=''>{edu.school} ({edu.date.substring(0, 4)}) {renderSpecialty(edu.specialty)}</dd>
                      </div>
                    )
                  })
                }
              </dl>
            </div>
          )
        }
      })
    )
  }

  const renderCertifications = () => {
    return (
      certifications.map((certification, index) => {
        return (
          <div key={index} className='test-certification'>
            <dd className='provider-credentials-text'>{certification.Name} ({certification.YearMostRecentlyRecertified})</dd>
          </div>
        )
      })
    )
  }

  return (
    <div>
      <h4 className='provider-credentials-header'>Credentials</h4>
      <div className='provider-credentials-container'>
        {
          (education && education.length > 0) &&
          <React.Fragment>
            <h5>Education</h5>
            {renderEducation()}
          </React.Fragment>
        }
        {
          (certifications && certifications.length > 0) &&
          <React.Fragment>
            <h5>Board Certifications</h5>
            {renderCertifications()}
          </React.Fragment>
        }
      </div>

      <div className='flex justify-center'>
        <div className='drawer-divider' />
      </div>
    </div>

  )
}

export default Credentials
