import { create } from 'apisauce'

export const api = create({
  baseURL: window.mohWebServicesUrl
})

export const WAIT_TIMES_ENDPOINT = '/facilities/Locations.svc/rest/FetchWaitTimes'

export const fetchWaitTimes = async () => {
  const appointmentResponse = await api.get(WAIT_TIMES_ENDPOINT)
  return appointmentResponse
}

let waitTimes = []
let started = false
export const updateWaitTimes = () => {
  return fetchWaitTimes().then((response) => {
    if (response.data) {
      waitTimes = response.data.map(time => {
        if (time && time.AverageWaitTime) {
          return {id: time.Id, waitTime: time.AverageWaitTime}
        } else if (time && !time.AverageWaitTime) {
          return {id: time.Id, waitTime: '--'}
        }
      })
      let event = document.createEvent('Event')
      event.initEvent('waitTimesUpdated', true, true)
      window.dispatchEvent(event)
    }
  }).finally(() => {
    startTimer()
  })
}
updateWaitTimes()

const startTimer = () => {
  if (!started) {
    setTimeout(() => {
      updateWaitTimes()
      started = false
    }, 60000)
    started = true
  }
}

const getWaitTimes = () => {
  return waitTimes.map(time => time)
}

export default getWaitTimes
