const SkeletonMap = () => {
  return <div className='multi-container'>
    <div className='component-wrapper map-wrapper'>
      <div className='epi-blocks'>
        <div className='fake-sidebar'>
          <div className='sidebar-card-results-container'>
            <div className='fake-micro-card' key={1} />
            <div className='fake-micro-card' key={2} />
            <div className='fake-micro-card' key={3} />
            <div className='fake-micro-card' key={4} />
            <div className='fake-micro-card' key={5} />
          </div>
        </div>
        <div className='fake-map-container epi-block' />
      </div>
    </div>
  </div>
}

export default SkeletonMap
