import React from 'react'
import SVGInline from 'react-svg-inline'
import xToClose from '../../../assets/x-to-close.svg'
import './alert-message-bar.scss'

export default class AlertMessageBar extends React.Component {
  render () {
    return (
      <div className={`alert-container ${this.props.className}`}>
        <div className='alert-message-info'>
          <div className='alert-icon'>{this.props.children}</div>
          <div className='text-container'>
            <div className='alert-message-text'>{this.props.text}</div>
            {
              this.props.dismissable
                ? <SVGInline className='dismiss-icon' svg={xToClose} onClick={this.props.actionHandler} />
                : <div className='action-text' onClick={this.props.actionHandler}>{this.props.actionText}</div>
            }
          </div>
        </div>
      </div>
    )
  }
}
