import { connect } from 'react-redux'
import FindFilters from './FindFilters.jsx'
import { getSelectedDate } from '../../../selectors/location-search-filter-selectors'

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  selectedDate: getSelectedDate(state)
})

export default connect(mapStateToProps)(FindFilters)
