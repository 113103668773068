import { connect } from 'react-redux'
import BeforeYouScheduleOBGYN from './BeforeYouScheduleOBGYN.jsx'
import PreviousPatientConnector from '../../PreviousPatient/PreviousPatientConnector.jsx'

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    phone: state.schedulingModal.doctorPhone,
    doctor: state.schedulingModal.doctor
  }
}

const mapDispatchToProps = dispatch => ({
  continueButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: PreviousPatientConnector})
})

export default connect(mapStateToProps, mapDispatchToProps)(BeforeYouScheduleOBGYN)
