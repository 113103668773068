import { connect } from 'react-redux'
import PainAtRest from './PainAtRest.jsx'
import PainWorsenedConnector from '../PainWorsened'
import Call911 from '../Call911'

const mapDispatchToProps = dispatch => ({
  yesButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: Call911}),
  noButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: PainWorsenedConnector})
})

export default connect(null, mapDispatchToProps)(PainAtRest)
