import React from 'react'
import moment from 'moment'
import { formatPhoneNumber, cleanNumber } from '../../../shared/utility-functions/phoneHelpers'
import './classes-card.scss'

export default class ClassesCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      displayRegisterOnline: this.props.class && this.props.class.RegistrationUrl && this.props.class.RegistrationUrl.indexOf('99') === -1 && this.props.class.RegistrationUrl.indexOf('&&') === -1
    }
    this.displayText = this.displayText.bind(this)
    this.toggleText = this.toggleText.bind(this)
    this.formatTimes = this.formatTimes.bind(this)
  }

  toggleText () {
    let current = this.state.open
    this.setState({ open: !current })
  }

  componentWillReceiveProps (props) {
    if (props.class !== this.props.class) {
      this.setState({ open: false })
    }
  }

  formatTimes (startTime, endTime) {
    let start = moment(startTime, 'hh:mm:ss').format(`h${startTime.split(':')[1] !== '00' ? ':mm A' : ' A'}`)
    let end = moment(endTime, 'hh:mm:ss').format(`h${endTime.split(':')[1] !== '00' ? ':mm A' : ' A'}`)
    let spansAMtoPM = start.includes('AM') && end.includes('PM')
    return `${start} ${spansAMtoPM ? ' to ' : ' - '} ${end}`
  }

  displayText () {
    return this.state.open ? <div className='description-container'>
      <p className='description'>{this.props.class.Description}</p>
      {this.props.class.Instructor && <p className='instructor'><strong>Instructor: {this.props.class.Instructor}</strong></p>}
      {
        this.props.class.Dates && this.props.class.Dates.length > 0 &&
        <div className='dates-table'>
          <div className='date-title'>
            <div className='date'>Date: </div>
            <div className='date'>Day: </div>
            <div className='date'>Time: </div>
          </div>
          {this.props.class.Dates.map((date, index) => {
            return date.isCurrent ? <div key={index} className='date-row'>
              <div className='date'><span className='show-for-small-only'>Date: </span>{moment(date.Date, 'M/DD/YYYY hh:mm:ss A').format('MMMM D')}</div>
              <div className='day'><span className='show-for-small-only'>Day: </span>{moment(date.Date, 'M/DD/YYYY hh:mm:ss A').format('dddd')}</div>
              <div className='time'><span className='show-for-small-only'>Time: </span>{this.formatTimes(date.StartTime, date.EndTime)}</div>
            </div> : null
          })}
        </div>
      }
      {
        this.props.class.Weeks && this.props.class.Weeks.length > 0 &&
        <div className='weeks-table'>
          <div className='date-title'>
            <div className='date'>Weeks: </div>
            <div className='date'>Day: </div>
            <div className='date'>Time: </div>
          </div>
          {this.props.class.Weeks.map((date) => {
            return date.isCurrent ? date.Days && date.Days.length > 0 ? date.Days.map((day, index) => {
              return day.isCurrent ? <div className='date-row'>
                <div className='date'><span className='show-for-small-only'>Date: </span>{moment(day.StartDate, 'M/DD/YYYY hh:mm:ss A').format('MMMM D')}—{moment(day.EndDate, 'M/DD/YYYY hh:mm:ss A').format('MMMM D')}</div>
                <div className='day'><span className='show-for-small-only'>Day: </span>{day.Day}</div>
                <div className='time'><span className='show-for-small-only'>Time: </span>{this.formatTimes(day.StartTime, day.EndTime)}</div>
              </div> : null
            }) : null
              : null
          })}
        </div>
      }
      <a className='open-text less' onClick={this.toggleText}>See Less</a>
    </div> : <p className='text-description'>{this.props.class.Description.slice(0, 280)}...<a className='open-text' onClick={this.toggleText}>See More</a></p>
  }

  render () {
    let fees = this.props.class ? this.props.class.Fees.reduce((a, b) => {
      let found = a.find(x => x.target && b.Target && x.target === b.Target)
      if (found) {
        found.units.push({ cost: b.Cost, session: b.Session, unit: b.Unit })
      } else if (b.Target) {
        a.push({ target: b.Target, units: [{ cost: b.Cost, session: b.Session, unit: b.Unit }] })
      }
      return a
    }, []) : null
    let isOnline = !!(this.props.class && this.props.class.Tags.length && this.props.class.Tags.filter(x => x === 'Online Class')[0])
    return this.props.class ? <div className='class-card'>
      <div className='title-container'>
        <h4 className='text-left title'>{this.props.class.Name}</h4>
        <p className='location-label'>Location: {this.props.class.Location} {isOnline && '(Offered Online)'}</p>
      </div>
      <div className='button-container'>
        {this.state.displayRegisterOnline && <a className='register-button' href={this.props.class.RegistrationUrl}>Register Online</a>}
        {this.state.displayRegisterOnline && this.props.class.PhoneNumber && <p className='or-divider'>or</p>}
        {this.props.class.PhoneNumber && <a className='phone-button' href={`tel:${cleanNumber(this.props.class.PhoneNumber)}`}>CALL {formatPhoneNumber(this.props.class.PhoneNumber)}</a>}
      </div>
      {this.displayText()}
      <div className='bottom-container'>
        <p className='cost'><strong>Cost: </strong>
          {this.props.class.IsFree && 'Free'}
          {(!this.props.class.IsFree && (!fees || fees.length === 0)) && 'Not Available'}
          {fees && fees.length > 0 && !this.props.class.IsFree &&
            fees.map((fee, index) => {
              return <span className='fee' key={index}>
                <span className='fee-target'>{fee.target} </span>
                {fee.units.map((unit, i) => {
                  return <span>
                    <span className='fee-amount'>{parseInt(unit.cost) > 0 ? '$' + unit.cost : 'Free'}</span>
                    {parseInt(unit.session) > 1 && <span className='session'> per {unit.session} {unit.unit}</span>}
                    {i + 1 < fee.units.length && <span>, </span>}
                  </span>
                })}
                {index + 1 < fees.length && <br />}
              </span>
            })
          }
        </p>
      </div>
    </div> : null
  }
}
