import React from 'react'
import SVGInline from 'react-svg-inline'
import './quick-link.scss'

export default class QuickLink extends React.Component {
  handleClick () {
    const url = this.props.url ? this.props.url + '/' : ''
    let urlbase = window.location.pathname[window.location.pathname.length - 1] === '/' ? window.location.pathname.substr(0, window.location.pathname.length - 1) : window.location.pathname
    window.location.href = `http://${window.location.hostname}${urlbase}/${url}`
  }

  render () {
    return (
      <a className='fad-links-group' target={this.props.target} href={this.props.url}>
        <SVGInline className='fad-links-icon' svg={this.props.icon} />
        <p className='fad-links-link'>{this.props.title}</p>
      </a>
    )
  }
}
