import { connect } from 'react-redux'
import Modal from '../../../shared/components/Modal'

const mapStateToProps = state => ({
  showModal: state.persistentData.sessionExpiredError,
  hideCloseButton: false
})

const mapDispatchToProps = dispatch => ({
  hideFunction: () => dispatch({ type: 'HIDE_USER_SESSION_TIMEOUT_ERROR' })
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
