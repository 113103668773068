const locationSorter = (filteredLocations, sortOrder) => {
  switch (sortOrder) {
    case 'A TO Z':
      return filteredLocations.sort((a, b) => {
        let aName = a.Name
        if (a.SearchName) {
          aName = a.SearchName
        }
        if (a.SearchNameOverride) {
          aName = a.SearchNameOverride
        }

        let bName = b.Name
        if (b.SearchName) {
          bName = b.SearchName
        }
        if (b.SearchNameOverride) {
          bName = b.SearchNameOverride
        }

        if (aName && bName) {
          return aName.toUpperCase() > bName.toUpperCase() ? 1 : -1
        } else {
          return -1
        }
      }).map(a => a)
    case 'Z TO A':
      return filteredLocations.sort((a, b) => {
        let aName = a.Name
        if (a.SearchName) {
          aName = a.SearchName
        }
        if (a.SearchNameOverride) {
          aName = a.SearchNameOverride
        }

        let bName = b.Name
        if (b.SearchName) {
          bName = b.SearchName
        }
        if (b.SearchNameOverride) {
          bName = b.SearchNameOverride
        }

        if (aName && bName) {
          return aName.toUpperCase() > bName.toUpperCase() ? -1 : 1
        } else {
          return -1
        }
      }).map(a => a)
    case 'Distance':
      return locationsSortedByDistance(filteredLocations)
    case 'Address':
      return filteredLocations.sort((a, b) => {
        if (a.City && b.City) {
          let aCity = a.City.toUpperCase()
          let bCity = b.City.toUpperCase()
          if (aCity > bCity) {
            return 1
          } else if (aCity === bCity) {
            let aStreet = a.Address.replace(/[^a-zA-Z]+/g, '').toUpperCase()
            let bStreet = b.Address.replace(/[^a-zA-Z]+/g, '').toUpperCase()
            return aStreet > bStreet ? 1 : -1
          } else if (aCity < bCity) {
            return -1
          }
        } else {
          return 0
        }
      }).map(a => a)
    default:
      return filteredLocations
  }
}

const locationsSortedByDistance = (filteredLocations, key = null) => {
  return filteredLocations.sort((a, b) => distanceComparatorHelper(a, b, key))
}

const distanceComparatorHelper = (a, b, keyAlternate) => {
  let key = keyAlternate || 'Distance'

  if (Number(a[key]) > Number(b[key])) {
    return 1
  } else if (Number(a[key]) < Number(b[key])) {
    return -1
  } else {
    if (a.Name && b.Name) {
      return a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1
    } else {
      return 1
    }
  }
}

const locationsByPrimaryAddressFirst = (addresses) => {
  return addresses.sort((a, b) => {
    if (a.prim && !b.prim) {
      return -1
    } else if (!a.prim && b.prim) {
      return 1
    } else {
      return 0
    }
  })
}

export { locationSorter, locationsSortedByDistance, locationsByPrimaryAddressFirst }
