export const DEFAULT_SEARCH_LOCATION = {
  dist: 5
}

export const DEFAULT_STATE = {
  searchedBounds: null,
  searchedBoundsObj: null,
  autoSearchedLocation: DEFAULT_SEARCH_LOCATION
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_FAD_MAP_SEARCH_BOUNDS':
      return { ...state, ...{ searchedBounds: action.value } }
    case 'CLEAR_FAD_MAP_SEARCH_BOUNDS':
      return { ...state, ...{ searchedBounds: DEFAULT_STATE.searchedBounds } }
    case 'SET_FAD_MAP_SEARCH_BOUNDS_OBJ':
      return { ...state, ...{ searchedBoundsObj: action.value } }
    case 'CLEAR_FAD_MAP_SEARCH_BOUNDS_OBJ':
      return { ...state, ...{ searchedBounds: DEFAULT_STATE.searchedBoundsObj } }
    case 'SET_AUTO_LOCATION_QUERY':
      return { ...state, ...{ autoSearchedLocation: { address: action.value } } }
    case 'SET_AUTO_SELECTED_SEARCH_LOCATION':
      return { ...state, ...{ autoSearchedLocation: action.value } }
  }
  return state
}
