import React from 'react'
import './skeleton-provider-card.scss'

const SkeletonProviderCard = () => {
  return (
    <div className='provider-card-container skeleton'>
      <div className='photo-and-info-container'>
        <div className='mobile-top-container'>
          <div className='img-wrapper'>
            <div className='grey-box' />
          </div>
          <div className='mobile-box hide-for-medium'>
            <div className='grey-box first-box' />
            <div className='grey-box second-box' />
            <div className='grey-box third-box' />
          </div>
        </div>
        <div className='specialty-box'>
          <div className='grey-box first-box' />
          <div className='grey-box second-box' />
          <div className='grey-box third-box' />
          <div className='grey-box third-box' />
        </div>
      </div>
    </div>
  )
}

export default SkeletonProviderCard
