const OBGYN_SPECIALTY_ID = '389'
const GYNOCOLOGY_SPECIALTY_ID = '271'

const isOBGYN = (specialtyIds) => {
  return specialtyIds ? (specialtyIds.includes(OBGYN_SPECIALTY_ID) || specialtyIds.includes(GYNOCOLOGY_SPECIALTY_ID)) : false
}

const isOBGYNInt = (specialtyIds) => {
  return isOBGYN(specialtyIds) ? 1 : 0
}

export { isOBGYN, isOBGYNInt, OBGYN_SPECIALTY_ID, GYNOCOLOGY_SPECIALTY_ID }
