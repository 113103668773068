import { connect } from 'react-redux'
import PainWorsened from './PainWorsened.jsx'
import Call911 from '../Call911'
import ContinueToSchedulingConnector from '../ContinueToScheduling'

const mapDispatchToProps = dispatch => ({
  yesButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: Call911}),
  noButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: ContinueToSchedulingConnector})
})

export default connect(null, mapDispatchToProps)(PainWorsened)
