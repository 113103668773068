import React from 'react'
import SVGInline from 'react-svg-inline'
import hospitalIcon from '../../../assets/hospital.svg'
import { clickToCallEvent } from '../../../../shared/utility-functions/googleTagManager'
import { FlowTypes } from '../../../../shared/types/FlowType'
import { clickToCallTransformer } from '../../../../shared/data-mappers/click-to-call-transformer'

const call911 = () => {
    const clickToCall = () => {
        clickToCallEvent(clickToCallTransformer(FlowTypes.E911));
    }

    return <div className='scheduling-modal-inner-content'>
        <SVGInline svg={hospitalIcon} className='red-image' />
        <div className='modal-text'>
            <div className='message-text'>Get medical assistance</div>
            <div className='sub-heading'>We recommend you that you go to an emergency department or call 911.</div>
        </div>
        <div className='button-container display-on-mobile'>
            <a href='tel:911' className='btn red-button'>
                <span onclick={clickToCall}>CALL 911</span>
            </a>
        </div>
    </div>
}

export default call911
