let called = []
let calls = 0
let waitingElement = null

export const alreadyInit = (key) => {
  let found = called.find(call => key === call)
  if (!found) {
    called.push(key)
    return false
  }
  return true
}

export const waitForLoad = (element, callback, repeatMax) => {
  setTimeout(() => {
    waitingElement = document.querySelector(element)
    calls += 1
    if (waitingElement) {
      callback()
      calls = 0
      waitingElement = null
    } else if (calls <= repeatMax) {
      waitForLoad(element, callback, repeatMax)
    } else if (calls > repeatMax) {
      calls = 0
      waitingElement = null
    }
  }, 750)
}
