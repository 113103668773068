import React from 'react'
import { toChildArray } from 'preact'
import { create } from 'apisauce'
import Autosuggest from 'react-autosuggest'
import SVGInline from 'react-svg-inline'
import SearchIcon from '../../../../shared/assets/MagnifyingGlass.svg'
import './lookingFor.scss'

const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
export const api = create({
  baseURL: window.serverLoginURL
})

export default class LookingFor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      autoCompleteData: [],
      currentData: []
    }

    this.onChange = this.onChange.bind(this)
    this.setAutocompleteData = this.setAutocompleteData.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.clearResults = this.clearResults.bind(this)
    this.keyPressEvent = this.keyPressEvent.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.autoCompleteData !== prevProps.autoCompleteData) {
      this.setAutocompleteData(this.props.autoCompleteData)
    }
  }

  // I think we can refactor this later to make this whole component more generic.
  // We should not rely on parsing/manipulating the data
  setAutocompleteData (data) {
    let currentAutoCompleteData = []
    if (!data) return

    if (data.specialties) {
      let needObject = {
        type: 'Need',
        data: Object.values(data.specialties)
      }
      if (data.conditions && data.conditions.length) {
        data.conditions.forEach(condition => {
          needObject.data.push(condition)
        })
      }
      needObject.data = [...new Set(needObject.data.flatten())]
      currentAutoCompleteData.push(needObject)
    }

    if (data.practices) {
      let practiceObject = {
        type: 'Practices',
        data: Object.values(data.practices)
      }
      practiceObject.data = [...new Set(practiceObject.data.flatten())]
      currentAutoCompleteData.push(practiceObject)
    }

    if (data.names) {
      // temporary duplicate check on predictive
      let namesUnique = data.names.reduce((a, b) => {
        let found = a.find(doctor => doctor.id === b.id)
        if (!found) a.push(b)
        return a
      }, [])

      let doctorObject = {
        type: 'Doctors',
        data: namesUnique.map(name => name.name)
      }
      currentAutoCompleteData.push(doctorObject)
    }

    if (data.locations) {
      let locationNameObject = {
        type: 'Names',
        data: []
      }

      if (data.locations && data.locations.length) {
        data.locations.forEach(location => {
          locationNameObject.data.push(location.name)
        })
      }

      locationNameObject.data = [...new Set(locationNameObject.data.flatten())]
      currentAutoCompleteData.push(locationNameObject)
    }

    if (data.locationtypes) {
      let locationTypeObject = {
        type: 'Types',
        data: Object.values(data.locationtypes)
      }

      locationTypeObject.data = [...new Set(locationTypeObject.data.flatten())]
      currentAutoCompleteData.push(locationTypeObject)
    }

    if (data.services) {
      let servicesObject = {
        type: 'Service',
        data: Object.values(data.services)
      }

      servicesObject.data = [...new Set(servicesObject.data.flatten())]
      currentAutoCompleteData.push(servicesObject)
    }

    if (data.classesKeywords && data.classesKeywords.length) {
      let classesObject = {
        type: 'Keywords',
        data: Object.values(data.classesKeywords)
      }

      classesObject.data = [...new Set(classesObject.data.flatten())]
      currentAutoCompleteData.push(classesObject)
    }

    if (data.classesNames && data.classesNames.length) {
      let classesObject = {
        type: 'Names',
        data: Object.values(data.classesNames)
      }

      classesObject.data = [...new Set(classesObject.data.flatten())]
      currentAutoCompleteData.push(classesObject)
    }

    this.setState({
      autoCompleteData: currentAutoCompleteData
    })
  }

  renderSuggestionsContainer ({ containerProps, children, query }) {
    let doctorData, needData, practiceData, typeData, nameData, serviceData

    if (this.state.currentData) {
      needData = this.state.currentData.filter(data => data.type === 'Need')[0]
      practiceData = this.state.currentData.filter(data => data.type === 'Practices')[0]
      doctorData = this.state.currentData.filter(data => data.type === 'Doctors')[0]
      nameData = this.state.currentData.filter(data => data.type === 'Names')[0]
      typeData = this.state.currentData.filter(data => data.type === 'Types')[0]
      serviceData = this.state.currentData.filter(data => data.type === 'Service')[0]
    }

    let countDiv = []
    let index = 0
    if (children && children[0]) {
      let childArray = toChildArray(children)
      while (index < childArray.length) {
        let currentChildType = toChildArray(childArray[index].props.children)[0].props.section.type
        let currentCount = 0
        if (needData && currentChildType === 'Need') {
          currentCount = needData && needData.data ? needData.data.length : 0
        } else if (practiceData && currentChildType === 'Practices') {
          currentCount = practiceData && practiceData.data ? practiceData.data.length : 0
        } else if (doctorData && currentChildType === 'Doctors') {
          currentCount = doctorData && doctorData.data ? doctorData.data.length : 0
        } else if (nameData && currentChildType === 'Names') {
          currentCount = nameData && nameData.data ? nameData.data.length : 0
        } else if (typeData && currentChildType === 'Types') {
          currentCount = typeData && typeData.data ? typeData.data.length : 0
        } else if (serviceData && currentChildType === 'Service') {
          currentCount = serviceData && serviceData.data ? serviceData.data.length : 0
        }
        countDiv.push(<div className={currentCount > 5 ? 'display-count' : 'hide-count'}>
          {currentCount} or more possible options
        </div>)
        index++
      }
    }

    return (
      <div {... containerProps}>
        {children !== null ? children[0] : ''}
        {countDiv && countDiv[0]}
        {children !== null ? children[1] : ''}
        {countDiv && countDiv[1]}
        {children !== null ? children[2] : ''}
        {countDiv && countDiv[2]}
      </div>
    )
  }

  renderSectionTitle (section) {
    return (<strong>{section.type}</strong>)
  }
  getSectionSuggestions (section) {
    return section.data
  }

  getSuggestionValue (suggestion) {
    return suggestion
  }

  renderSuggestion (suggestion, {query, isHighlighted}) {
    let suggestionString = suggestion.replace(new RegExp('(^|)(' + query + ')(|$)', 'ig'), '$1<div class="highlightQuery">$2</div>$3')
    return (
      <div dangerouslySetInnerHTML={{__html: suggestionString}} />
    )
  }

  onChange (event, newValue) {
    this.props.setLookingFor(newValue.newValue)
  };

  onSuggestionsFetchRequested (value) {
    this.setState({
      currentData: this.getSuggestions(value.value)
    })
  }

  onSuggestionsClearRequested () {
    this.setState({
      currentData: []
    })
  }

  getSuggestions (currentValue) {
    const inputValue = currentValue.trim().toLowerCase()
    const escapedValue = escapeRegexCharacters(inputValue.trim())

    if (escapedValue === '') {
      return []
    }

    return this.state.autoCompleteData.map(section => {
      let filteredSection = section.data.filter(d => {
        return d.toLowerCase().indexOf(escapedValue) > -1
      })
      return {
        type: section.type,
        data: filteredSection,
        length: filteredSection.length
      }
    }).filter(section => section.data.length > 0)
  };

  limitSuggestions (suggestions) {
    return suggestions.map(suggestion => {
      return {
        type: suggestion.type,
        data: suggestion.data && suggestion.data.length > 0 ? suggestion.data.slice(0, 5) : []
      }
    })
  }

  clearResults () {
    this.props.setLookingFor('')
  }

  keyPressEvent (event) {
    if (this.props.enterSubmit) {
      this.props.enterSubmit(event)
    }
  }

  render () {
    return (
      <div className='autosuggest'>
        <SVGInline className='search-input-icon' svg={SearchIcon} />
        <Autosuggest
          className='autosuggest-widget'
          multiSection
          suggestions={this.state.currentData && this.state.currentData.length > 0 ? this.limitSuggestions(this.state.currentData) : []}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSectionTitle={this.renderSectionTitle}
          getSectionSuggestions={this.getSectionSuggestions}
          id={this.props.isMobile ? 'REACT_AUTOCOMPLETE_MOBILE' : 'REACT_AUTOCOMPLETE'}
          inputProps={{
            id: this.props.isMobile ? 'LOOKING_FOR_AUTOSUGGEST_MOBILE' : 'LOOKING_FOR_AUTOSUGGEST',
            autoComplete: 'off',
            placeholder: this.props.placeholder,
            title: 'Looking For Input',
            value: this.props.lookingFor,
            onChange: this.onChange,
            onKeyUp: (event) => this.keyPressEvent(event)
          }}
          renderSuggestionsContainer={({ containerProps, children, query }) => this.renderSuggestionsContainer({ containerProps, children, query })}
          autoCompleteData={this.state.autoCompleteData}
        />
        <div onClick={() => this.clearResults()} className={this.props.lookingFor ? 'clear-input' : 'clear-input-off'} ><img alt='clear' className='clear-icon' src={'/ClientResources/Website/images/x.png'} /></div>
      </div>
    )
  }
}
