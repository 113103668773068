import React from 'react'
import SVGInline from 'react-svg-inline'
import SessionTimeoutModalConnector from './SessionTimeoutModalConnector.jsx'
import notification from '../../../shared/assets/notice.svg'
import '../../../Blocks/QuestionnaireModal/ErrorModal/error-modal.scss'
export default class SessionTimeoutModalContent extends React.Component {
  render () {
    return (
      <SessionTimeoutModalConnector>
        <div className='error-modal'>
          <div className='error-icon text-center'><SVGInline svg={notification} /></div>
          <div className='modal-text'>
            <h2 className='title text-center'>We're sorry...</h2>
            <div className='subheader text-center'>To protect your information, your MyChart session has expired.<br />Please try scheduling again.</div>
          </div>
        </div>
      </SessionTimeoutModalConnector>
    )
  }
}
