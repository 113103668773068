import React from 'react'

export default class InsuranceCard extends React.Component {
  render () {
    return <div className='insurance-card'>
      <h4>{this.props.plan.provider}</h4>
      <p>{this.props.plan.plan}</p>
    </div>
  }
}
