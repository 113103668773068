import { connect } from 'react-redux'
import FindPage from '../FindPage.jsx'
import { getURLParameters } from '../../shared/utility-functions/searchParams.js'
import {getServices, getServicesCount} from '../selectors/service-search-selectors.js'

const mapStateToProps = (state, ownProps) => {
  return {
    flow: 'service',
    searchType: 'service',
    searchOptions: ownProps.searchOptions,
    filterOptions: ownProps.filterOptions,
    headline: ownProps.headline,
    urlParameters: getURLParameters(),
    searchItems: getServices(state),
    searchItemCount: getServicesCount(state),
    filteredProviderCount: getServicesCount(state),
    affiliatedProviderCount: 0,
    dataLoaded: state.serviceSearch.dataLoaded,
    filtersLoading: false,
    searched: state.serviceSearch.searched,
    fetchCallFailed: state.serviceSearch.callFailed,
    filters: [],
    locationId: ownProps.searchOptions.locationId,
    itemIds: ownProps.searchOptions && ownProps.searchOptions.serviceIds ? ownProps.searchOptions.serviceIds.replace(/\s/g, '') : '',
    questionnaireCompleted: true
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPage: (flow) => dispatch({
    type: 'SET_SCHEDULING_TYPE',
    value: flow
  }),
  setSort: (sortOrder) => dispatch({
    type: 'SET_SERVICE_SORT_ORDER',
    value: sortOrder
  }),
  clearPage: () => dispatch({ type: 'CLEAR_STORE' }),
  runSearch: (params) => dispatch({
    type: 'GET_SERVICES',
    params: params
  }),
  setPageNumber: (number) => dispatch({
    type: 'SET_SERVICE_CURRENT_PAGE',
    number: number
  }),
  fetchAutocompleteData: () => dispatch({
    type: 'GET_SERVICES_AUTOCOMPLETE_DATA',
    ids: ''
  }),
  hideSchedulingModal: () => {
    dispatch({ type: 'HIDE_SCHEDULING_MODAL' })
  },
  setFilters: () => {}
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  runSearch: (params) => {
    dispatchProps.setSort('A TO Z')
    dispatchProps.runSearch(params)
  },
  initializePage: () => {
    dispatchProps.fetchAutocompleteData()
    dispatchProps.setPage('service')
    let sort = stateProps.searchOptions && stateProps.searchOptions.defaultSort ? stateProps.searchOptions.defaultSort : 'A TO Z'
    dispatchProps.setSort(sort)
    let searchParam = {}
    let urlParams = getURLParameters().search
    if (stateProps.itemIds && stateProps.itemIds.length) {
      searchParam.ids = stateProps.itemIds
    }
    if (stateProps.locationId) {
      searchParam.locId = stateProps.locationId
    }
    if (urlParams && urlParams.q) {
      searchParam.q = urlParams.q
    }
    dispatchProps.runSearch(searchParam)
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FindPage)
