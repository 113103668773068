import { buildProfilePageUrl, isEmptyObject, titleCaseStringArray } from '../utility-functions'
import {formatIncomingLocationData} from '../utility-functions/locationTransformer'
import { dateComparatorHelper } from '../sorters/provider-sorter'

export default function transcribe (doctorDetailResponse) {
  if (!doctorDetailResponse || isEmptyObject(doctorDetailResponse)) {
    return {}
  }

  let baseDoc = doctorDetailResponse
  let addresses = doctorDetailResponse.a || []
  let allAddresses = doctorDetailResponse.aa || []
  let displayAddress = getPrimaryAddress(baseDoc.a)

  let transformedDoc = {
    id: baseDoc.id,
    title: `${baseDoc.name}, ${baseDoc.sf}`,
    active: baseDoc.act,
    rating: baseDoc.rating,
    profilePageUrl: buildProfilePageUrl('/find-a-doctor/profile/', baseDoc.name, baseDoc.npi),
    phone: extractPrimaryPhone(displayAddress),
    photoUrl: baseDoc.photo ? baseDoc.photo : buildPhotoUrl(baseDoc),
    firstName: baseDoc.fn,
    lastName: baseDoc.ln,
    middleInitial: baseDoc.mi ? baseDoc.mi.substring(0, 1) + '.' : '',
    isActive: baseDoc.act,
    npi: baseDoc.npi,
    hasScheduleOnline: baseDoc.sched,
    medicalSuffix: baseDoc.sf,
    gender: baseDoc.g,
    experience: baseDoc.xp,
    OrganizationalUnit : baseDoc.orgUnit,
    isAcceptingPatients: baseDoc.ap === 'Yes',
    languages: baseDoc.lng,
    specialties: baseDoc.sp,
    affiliations: titleCaseStringArray(baseDoc.gp),
    hospitals: baseDoc.affiliations,
    hideContactNumbers: baseDoc.hcn,
    practices: transcribePractices(baseDoc),
    specialtyIds: baseDoc.spid,
    address: displayAddress,
    areaOfFocus: baseDoc.aoe,
    addresses,
    allAddresses,
    bio: baseDoc.bio,
    philosophy: baseDoc.poc,
    education: baseDoc.education,
    certifications: baseDoc.certifications,
    distance: displayAddress ? displayAddress.d : null,
    videoUrl: baseDoc.video,
    epicId: baseDoc.epicId,
    schedStartDates: baseDoc.schedStartDates && baseDoc.schedStartDates.length ? baseDoc.schedStartDates.sort((a, b) => dateComparatorHelper(a, b)) : [],
    locations: baseDoc.epicLoc && baseDoc.epicLoc.length > 0 ? formatIncomingLocationData(baseDoc.epicLoc) : [],
    top5aoe: baseDoc.topFiveAoe || []
  }

  let ispcpExists = transformedDoc.OrganizationalUnit.findIndex(x => x.ID === "95") !== -1
  
  if(ispcpExists){
    transformedDoc.isAcceptingPatients = baseDoc.schedStartDates && baseDoc.schedStartDates.length ? true : false
  }

  return transformedDoc
}

function getPrimaryAddress (addressCollection) {
  if (addressCollection && addressCollection.length) {
    let primaryAddress = addressCollection.find(address => address.prim)
    return primaryAddress || addressCollection[0]
  } else {
    return null
  }
}

export const buildPhotoUrl = doctor => {
  return doctor.img && doctor.id ? `//ohiohealth.providerconnections.net/Custom/Photos/${doctor.id}.jpg` : null
}

export const transcribePractices = doctor => {
  let practices = {}

  doctor.pr = doctor.pr || []
  doctor.pr.forEach(practice => {
    practices[practice.id] = practice.n
  })

  return practices
}

export const extractPrimaryPhone = address => {
  if (!address) { return null }
  const phones = address.cr || []

  let noFaxPhones = phones.filter(phone => phone.type !== 'Fax')
  let sortedPhones = noFaxPhones.sort((a, b) => {
    if (a.type === 'Provider Phone' && b.type !== 'Provider Phone') return -1
    if (a.type !== 'Provider Phone' && b.type === 'Provider Phone') return 1
    return 0
  })

  return sortedPhones.length > 0 ? sortedPhones[0].Number : null
}
