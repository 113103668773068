import React from 'react'

export default ({ yesButtonFunction, noButtonFunction }) => (
  <div className='scheduling-modal-inner-content'>
    <div className='modal-text'>
      <h2>Pre-qualifying Questions</h2>
      <div className='pre-qualifying-question'>
        Is the pain occurring at rest?
      </div>
    </div>
    <div className='button-container'>
      <div className='btn blue-button yes-button' onClick={yesButtonFunction}>
        YES
      </div>
      <div className='btn blue-button no-button' onClick={noButtonFunction}>
        NO
      </div>
    </div>
  </div>
)
