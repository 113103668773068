import React from 'react'
import Checkbox from '../../../../shared/components/Checkbox'
import SVGInline from 'react-svg-inline'
import emptyStarIcon from '../../../../shared/assets/empty_star.svg'
import fullStarIcon from '../../../../shared/assets/full_star.svg'
import { insertBrowserHistory } from '../../../state/history'
import { getURLParameters, insertFiltersIntoURLParameters } from '../../../../shared/utility-functions'
import './SuperFilter.scss'

class SuperFilter extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: false }
    this.dropdownRef = React.createRef()
    this.pillRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.onPillClick = this.onPillClick.bind(this)
    this.checkboxes = this.checkboxes.bind(this)
    this.ratings = this.ratings.bind(this)
    this.applyFilter = this.applyFilter.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.renderStars = this.renderStars.bind(this)
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside (event) {
    if (this.dropdownRef.current && this.pillRef.current && !this.dropdownRef.current.contains(event.target) && !this.pillRef.current.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  onPillClick () {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  clearFilters (e) {
    e.stopPropagation()

    let sortOrder = 'A TO Z'

    this.props.setFilter([])
    this.props.setSearchOrder(sortOrder)
    insertBrowserHistory({ ...getURLParameters(), sort: sortOrder })

    if (this.props.enableUrl) {
      insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), this.props.dispatchType, []))
    }
  }

  applyFilter (event) {
    let value = event.target && event.target.value ? event.target.value : null
    let checked = event.target ? event.target.checked : false

    if(value === 'Doctors with Online Scheduling'){
      const sortOrder = checked ? 'First Available' : 'A TO Z'

      this.props.setSearchOrder(sortOrder)
      insertBrowserHistory({ ...getURLParameters(), sort: sortOrder })
    }

    if (this.props.title === 'Rating') {
      this.props.setFilter([value])
      if (this.props.enableUrl) {
        insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), this.props.dispatchType, [value]))
      }
    } else {
      let currentFilters
      if (checked) {
        if (this.props.filters[this.props.filterKey]) {
          currentFilters = this.props.filters[this.props.filterKey].concat(value)
        } else {
          currentFilters = [value]
        }
      } else {
        currentFilters = this.props.filters[this.props.filterKey].filter(item => item !== value)
      }
      this.props.setFilter(currentFilters)
      if (this.props.enableUrl) {
        insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), this.props.dispatchType, currentFilters))
      }
    }
  }

  renderStars (num) {
    const stars = Array.from({ length: 5 }, (_, index) => {
      return index < num ? <SVGInline className='full-star' svg={fullStarIcon} /> : <SVGInline className='empty-star' svg={emptyStarIcon} />
    })

    return stars
  }

  ratings () {
    if (this.props.options.length < 1) return
    let maxRating = Math.floor(Math.max(...this.props.options))
    if (maxRating > 4) maxRating = 4
    const numInputs = Array.from({ length: maxRating }, (_, index) => index + 1)

    return numInputs.reverse().map((option, index) => {
      let checkToggle = false
      if (option) {
        checkToggle = this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].includes(option.toString())
      }

      return (
        <div className='rating--container' key={index}>
          <input type='radio' checked={checkToggle} id={`super-radio-${option}`} value={option} onChange={(e) => this.applyFilter(e)} name='rating' />
          <label htmlFor={`super-radio-${option}`}>
            <div className='star-rating'>{this.renderStars(option)}</div>
            <span>{option} Star{option > 1 ? 's' : ''} & Up</span>
          </label>
        </div>
      )
    })
  }

  checkboxes (key) {
    let checkboxLength = this.props.options.length

    return this.props.options.slice(0, checkboxLength).map((option, index) => {
      let checkToggle = false
      if (option) {
        if (typeof option === 'object') {
          checkToggle = this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].includes(option.value)
        } else {
          checkToggle = this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].includes(option)
          option = { value: option, label: option }
        }
        if (key) {
          return checkToggle ? <div key={index}>
            <Checkbox checked={checkToggle} onChangeHandler={this.applyFilter} value={option.value} label={option.label} />
          </div> : null
        } else if (this.props.options && this.props.options.length <= 5) {
          return <div key={index}><Checkbox checked={checkToggle} onChangeHandler={this.applyFilter} value={option.value} label={option.label} /></div>
        } else {
          return checkToggle ? null : <div key={index}>
            <Checkbox checked={checkToggle} onChangeHandler={this.applyFilter} value={option.value} label={option.label} />
          </div>
        }
      }
    })
  }

  render () {
    const { title } = this.props
    const { isOpen } = this.state

    return (
      <div className={`super-filter--pill ${this.props.options.length === 0 ? 'disabled' : ''}`}>
        <span ref={this.pillRef} className={`pill--title ${this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].length > 0 && title !== '' ? 'active' : ''}`} onClick={this.onPillClick}>
          {`${this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].length > 0 && title !== ''
            ? title + ' (' + this.props.filters[this.props.filterKey].length + ')'
            : title + ''
          }
                    `}
        </span>
        <div ref={this.dropdownRef} className={`pill--dropdown ${isOpen ? 'open' : ''}`}>
          <div className='checkbox--container'>
            {this.props.options && this.props.options.length > 5 && this.checkboxes('checked')}
            {title === 'Rating' ? this.ratings() : this.checkboxes()}
          </div>
          <div className='dropdown--footer'>
            {this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].length > 0 && title !== ''
              ? <span className='dropdown--clear' onClick={(e) => { this.clearFilters(e) }}>Clear Filters</span>
              : <span className='dropdown--clear disabled'>Clear Filters</span>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default SuperFilter
