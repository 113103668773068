import { combineReducers } from 'redux'
import schedulingModalReducer from '../../shared/state/scheduling-modal-reducer'
import doctorSearchReducer from './doctor-search-reducer'
import persistentReducer from './persistent-reducer'
import serviceSearchReducer from './service-search-reducer'
import searchInputReducer from './search-input-reducer'
import locationSearchReducer from './location-search-reducer'
import classesSearchReducer from '../../Blocks/Classes/state/classes-reducer'
import profileReducer from '../../Profile/state/profile-reducer.js'
import availableAppointmentsReducer from './available-appointments-reducer'
import fadMapReducer from './fad-map-reducer'

const appReducer = combineReducers({
  persistentData: persistentReducer,
  schedulingModal: schedulingModalReducer,
  doctorSearch: doctorSearchReducer,
  serviceSearch: serviceSearchReducer,
  locationSearch: locationSearchReducer,
  searchInput: searchInputReducer,
  profile: profileReducer,
  availableAppointments: availableAppointmentsReducer,
  fadMap: fadMapReducer
})

export default (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    const clearState = {
      persistentData: state.persistentData,
      doctorSearch: state.doctorSearch,
      serviceSearch: state.serviceSearch,
      locationSearch: state.locationSearch,
      searchInput: state.searchInput,
      profile: state.profile,
      fadMap: state.fadMap
    }
    state = clearState
  }

  return appReducer(state, action)
}

export const classesRoot = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    const clearState = {
      persistentData: state.persistentData,
      classesSearch: state.classesSearch,
      searchInput: state.searchInput
    }
    state = clearState
  }

  return combineReducers({
    persistentData: persistentReducer,
    classesSearch: classesSearchReducer,
    searchInput: searchInputReducer
  })(state, action)
}

export const doctorRoot = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    const clearState = {
      persistentData: state.persistentData,
      doctorSearch: state.doctorSearch,
      searchInput: state.searchInput,
      profile: state.profile,
      fadMap: state.fadMap
    }
    state = clearState
  }

  return combineReducers({
    persistentData: persistentReducer,
    doctorSearch: doctorSearchReducer,
    searchInput: searchInputReducer,
    profile: profileReducer,
    fadMap: fadMapReducer
  })(state, action)
}

export const locationRoot = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    const clearState = {
      persistentData: state.persistentData,
      locationSearch: state.locationSearch,
      searchInput: state.searchInput
    }
    state = clearState
  }

  return combineReducers({
    persistentData: persistentReducer,
    locationSearch: locationSearchReducer,
    searchInput: searchInputReducer
  })(state, action)
}

export const serviceRoot = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    const clearState = {
      persistentData: state.persistentData,
      serviceSearch: state.serviceSearch,
      searchInput: state.searchInput
    }
    state = clearState
  }

  return combineReducers({
    persistentData: persistentReducer,
    serviceSearch: serviceSearchReducer,
    searchInput: searchInputReducer
  })(state, action)
}
