import { connect } from 'react-redux'
import BeforeYouScheduleConnector from '../SchedulingModal/BeforeYouSchedule'
import PreviousPatientCardio from './PreviousPatientCardio.jsx'

const mapDispatchToProps = dispatch => ({
  hideFunction: () => {
    dispatch({ type: 'HIDE_CARDIO_MODAL' })
  },
  continueButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: BeforeYouScheduleConnector})
})

export default connect(null, mapDispatchToProps)(PreviousPatientCardio)
