import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import providerDistanceSaga from '../sagas/provider-distance-saga'
import timeoutSaga from '../sagas/timeout-saga'
import rootReducer from '../state/root-reducer'
import searchSaga from '../sagas/search-saga'
import autocompleteSaga from '../sagas/fetch-autocomplete-data-saga'
import fetchPhysicianCommentsSaga from '../../Profile/sagas/fetch-physician-comments-saga.js'
import availableApointmentsEndPointSaga from '../sagas/fetch-available-api-end-point-data-saga'
import FindADoctorConnector from './FindADoctorConnector.jsx'
import SessionTimeoutModalContent from '../components/SessionTimeoutModalContent'
import { SchedulingModalContentConnector, SchedulingModalConnector } from '../../shared/components/SchedulingModal'
import './fad-root.scss'

export default class FADRoot extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.fadStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))

    sagaMiddleware.run(providerDistanceSaga)
    sagaMiddleware.run(timeoutSaga)
    sagaMiddleware.run(searchSaga)
    sagaMiddleware.run(autocompleteSaga)
    sagaMiddleware.run(fetchPhysicianCommentsSaga)
    sagaMiddleware.run(availableApointmentsEndPointSaga)
  }

  render () {
    return (
      <Provider store={this.fadStore}>
        <div>
          <SessionTimeoutModalContent />
          <SchedulingModalConnector><SchedulingModalContentConnector /></SchedulingModalConnector>
          <FindADoctorConnector searchOptions={this.props.searchOptions} headline={this.props.headline} flow={this.props.searchOptions.flowType} providerCardOptions={this.props.providerCardOptions} filterOptions={this.props.filterOptions} />
        </div>
      </Provider>
    )
  }
}
