import React, { useEffect, useState } from 'react'
import './questions-modal.scss'
import infoIcon from '../../../shared/assets/info.svg'
import SVGInline from 'react-svg-inline'
import { scheduleQuestionEvent } from '../../../shared/utility-functions/googleTagManager'

const QuestionsModalNew = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState(props.service.getCurrentQuestion());
  const [totalQuestions, setTotalQuestions] = useState(props.service.numberOfQuestions);
  const [inputDate, setInputDate] = useState(null);

  useEffect(() => {
    const currentQues = props.service.getCurrentQuestion();
    setCurrentQuestion((prevState) => {
      if (prevState.question != currentQues.question) {
        document.activeElement.blur()
        return currentQues;
      }
      return prevState;
    });
    setTotalQuestions(props.service.numberOfQuestions);
  }, [props.service])

  const actionHandler = (answer) => {

    const dataLayerObject = {
      flow: props.flow,
      scheduleQuestionNumber: undefined,
      scheduleQuestion: currentQuestion.question,
      scheduleQuestionAnswer: answer.text
    }

    scheduleQuestionEvent(dataLayerObject)

    if (answer.error) {
      props.submissionHandler(answer)
    } else {
      props.service.answerQuestion({ ...answer, inputDate: inputDate })
      setCurrentQuestion(props.service.getCurrentQuestion());
      if (currentQuestion === 'done') {
        props.submissionHandler()
      } else {
        let answer = document.querySelector('button.answer')
        if (answer) {
          answer.focus()
        }
      }
    }

  }

  let positionValue = (currentQuestion.number % 2 === 0) ? 'relative' : 'initial' // doing this for a safari bug to force a redraw of question text
  return (
    <div className={`questions-modal ${props.flow}`}>
      {currentQuestion.type && currentQuestion.type === 'notice' && <SVGInline svg={infoIcon} className='error-icon' />}
      {currentQuestion.image ? <SVGInline svg={currentQuestion.image} className='error-icon' /> : null}
      <div className='title'>{currentQuestion.title ? currentQuestion.title : props.title}</div>
      {currentQuestion.type !== 'notice' && totalQuestions > 1 && <p className='step-numbers'>{currentQuestion.number} of {totalQuestions}</p>}
      <p className='question' style={{ position: positionValue }}>{currentQuestion.question}</p>
      {currentQuestion.input ?
        <div className='date-input-container'>
          <input type={currentQuestion.input} onChange={(evt) => {
            setInputDate(new Date(evt.target.value))
          }} />
        </div>
        : null}
      {
        currentQuestion && currentQuestion.answers && currentQuestion.answers.map((answer, index) => {
          return <button
            key={index}
            className={`btn btn-primary answer question-number-${currentQuestion.number} ${answer.className ? answer.className : ''} button-${index}`}
            onClick={() => actionHandler(answer)}
            disabled={answer.required ? inputDate == null ? true : false : false}
          >
            {answer.text}
          </button>
        })
      }
    </div>
  )
}
export default QuestionsModalNew