import { race, put, take, delay } from 'redux-saga/effects'
import fetchData from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { SERVICES_ENDPOINT } from '../../shared/constants/api-helpers.js'
import serviceTransformer from '../../shared/data-mappers/service-transformer.js'

export default function * serviceSearchSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_SERVICES')
    let params = action.params ? Object.assign({}, action.params) : null
    params.endPoint = SERVICES_ENDPOINT
    yield put({ type: 'SEARCH_SERVICES' })
    yield put({ type: 'CLEAR_SERVICES' })
    yield put({ type: 'SET_SERVICE_CALL_FAILED', value: false })

    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts && posts.success) {
      yield put({ type: 'SET_SERVICES', value: serviceTransformer(posts.results) })
    } else {
      yield put({ type: 'SET_SERVICE_CALL_FAILED', value: true })
    }
  }
}
