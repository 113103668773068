import '../Locations/locations.scss'
import { locationsSortedByDistance, locationsByPrimaryAddressFirst } from '../../../../../../shared/sorters/location-sorter'
import { addressBuilder } from '../../../../../../shared/utility-functions'
import { getPhoneNumber } from '../../../../../../shared/utility-functions'
import DirectionsLink from '../../../../../../shared/components/DirectionsLink/index'
import CollapseExpand from '../../../../../../Profile/components/CollapseExpand/index'

const Locations = ({ address, addresses }) => {
  const handleGetPhoneNumber = (addressParam) => {
    const phone = getPhoneNumber(addressParam)
    return (
      <React.Fragment>
        {phone && phone.Number !== '' && <div className='test-phone'>{phone.Number}</div>}
      </React.Fragment>
    )
  }

  const locations = () => {
    if (addresses && addresses.length > 0) {
      return addresses[0] && addresses[0].d ? locationsSortedByDistance(addresses, 'd') : locationsByPrimaryAddressFirst(addresses)
    } else {
      return []
    }
  }

  const location = () => {
    const phone = address ? getPhoneNumber(address) : null
    return (
      <div>
        <h4 className='provider-locations-header'>Locations</h4>
        <div className='provider-locations-container'>
          {
            <div className='provider-location-card'>
              <div className='provider-location-label'>{address.lbl}</div>
              {
                address.d
                  ? <div className='test-full-address-mi'>{address.d} mi - {address.adr} {address.c}, {address.s} {address.z}</div> : <div>{address.adr} {address.c}, {address.s} {address.z}</div>
              }

              {phone && phone.Number !== '' && <div className='test-phone'>{phone.Number}</div>}

              {
                address.adr &&
                <DirectionsLink address={addressBuilder(address)} text='Directions' displayIcon={false} />
              }
            </div>
          }
        </div>
      </div>
    )
  }

  const flexHeight = () => 295

  const expandableLocationList = () => {
    return (
      <div className='provider-locations-container'>
        <CollapseExpand customClass={''}
          total={addresses && addresses.length > 0 ? addresses.length : 0}
          display={{ desktop: 2, tablet: 2, mobile: 2 }}
          text={{ open: 'Show Less Locations', closed: 'Show More Locations' }}
          closedHeight={flexHeight()}
          from='provider-drawer'>
          <h4 className='provider-locations-header'>Locations</h4>
          {
            locations().map((addressItem, index) => (
              <div key={index} className='provider-location-card'>
                <div className='provider-location-label'>{addressItem.lbl}</div>
                {
                  addressItem.d
                    ? <div className='test-full-address-mi'>{addressItem.d} mi - {addressItem.adr} {addressItem.c}, {addressItem.s} {addressItem.z}</div> : <div className='test-full-address'>{addressItem.adr} {addressItem.c}, {addressItem.s} {addressItem.z}</div>
                }

                {/* {phone && phone.Number !== '' && <div className=''><a href={'tel:' + cleanNumber(phone.Number)}>{phone.Number}</a></div>} */}
                {handleGetPhoneNumber(addressItem)}

                {
                  addressItem.adr && 
                  <DirectionsLink address={addressBuilder(addressItem)} text='Directions' displayIcon={false} />
                }
              </div>
            ))
          }
        </CollapseExpand>
      </div>
    )
  }

  const showLocations = () => {
    if (addresses && addresses.length > 1) {
      return expandableLocationList()
    }
    else if (address) {
      return location()
    }
    else {
      return null
    }
  }

  return (
    <div>
      {showLocations()}
      <div className='flex justify-center'>
        <div className='drawer-divider' />
      </div>
    </div>
  )
}

export default Locations
