import { createSelector } from 'reselect'
import serviceSorter from '../../shared/sorters/service-sorter'

const allServices = state => state.serviceSearch.services
const sortOrder = state => state.serviceSearch.sortOrder

export const getServices = createSelector(allServices, sortOrder, (allServices, sortOrder) => {
  return serviceSorter(allServices, sortOrder)
})

export const getServicesCount = createSelector(getServices, (services) => {
  return services.length
})

export const getServiceSortOptions = () => {
  return [
    { value: 'A TO Z', label: 'Name: A TO Z' },
    { value: 'Z TO A', label: 'Name: Z TO A' }
  ]
}
