import { connect } from 'react-redux'
import { PreviousPatientConnector, PreviousPatientCardioConnector } from '../../../../shared/components/PreviousPatient'
import { BeforeYouScheduleOBGYNConnector } from '../../../../shared/components/SchedulingModal/OBGYN'
import SuperResultsCard from './SuperResultsCard.jsx'
import { isOBGYN } from '../../../../shared/utility-functions/isOBGYN'

const mapStateToProps = (state, ownProps) => {
  if (state.doctorSearch.disableFirstAvailable) {
    ownProps.options.displayAvailability = false
  }
  return {
    options: ownProps.options,
    showDetailsId: ownProps.showDetailsId,
    availableSlots: ownProps.availableSlots,
    setshowTimeSlotStatus: ownProps.setshowTimeSlotStatus,
    showTimeSlotsGrid: ownProps.showTimeSlotsGrid,
    getProvidedDetailsid: ownProps.getProvidedDetailsid,
    flow: ownProps.provider && ownProps.provider.specialtyIds && isOBGYN(ownProps.provider.specialtyIds) ? 'OBGYN' : state.persistentData.schedulingType,
    distance: ownProps.provider.distance,
    pageNumber: state.doctorSearch.currentPage,
    sort: state.doctorSearch.sortOrder,
    preliminaryStatus: ownProps.preliminaryStatus,
    setPreliminaryStatus: ownProps.setPreliminaryStatus
  }
}

const mapDispatchToProps = (dispatch) => ({
  profileLinkClick: (provider) => {
    dispatch({ type: 'SET_DOCTOR_DETAILS', value: provider })
    dispatch({ type: 'FETCH_PROVIDER_COMMENTS', providerNPI: provider.npi })
  },
  fetchAvailableAppointments: () => dispatch({
    type: 'SET_AVAILABLE_APPOINTMENTS_ENDPOINT_DATA'
    // schedulingType: 'Open',
    // startDate: "2023-10-01",
    // endDate: "2023-10-31",
    // physicianId: "183736"
  }),
  scheduleLinkClick: (url) => {
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: PreviousPatientConnector })
  },
  cardioScheduleLinkClick: (url) => {
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: PreviousPatientCardioConnector })
  },
  obgynScheduleLinkClick: (url, phone, provider) => {
    dispatch({ type: 'SET_DOCTOR', value: provider })
    dispatch({ type: 'SET_DOCTOR_PHONE', value: phone })
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: BeforeYouScheduleOBGYNConnector })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperResultsCard)
