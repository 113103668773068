import React from 'react'
import Modal from '../Modal'
import './video-modal.scss'

export default class VideoModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      url: null
    }
    this.closeVideo = this.closeVideo.bind(this)
    this.openVideo = this.openVideo.bind(this)
  }

  componentDidMount () {
    let $this = this
    window.addEventListener('launchVideoModal', function (e) {
      $this.openVideo(e.videoUrl)
    }, {passive: true})
  }

  componentWillUnmount () {
    window.removeEventListener('launchVideoModal')
  }

  openVideo (url) {
    if (url !== null && url !== '') {
      this.setState({ url: url, open: true })
    }
  }

  closeVideo () {
    this.setState({ open: false, url: null })
  }

  render () {
    return <Modal customClass='video-modal' showModal={this.state.open} hideFunction={this.closeVideo}>
      <iframe src={`${this.state.url}&autoplay=true`} allowFullScreen webkitallowfullscreen='true' mozallowfullscreen='true' />
    </Modal>
  }
}
