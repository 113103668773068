import { connect } from 'react-redux'
import Dropdown from '../../../shared/components/Dropdown'
import { insertBrowserHistory } from '../../state/history'
import { getURLParameters } from '../../../shared/utility-functions'
import { getSort, getSortOptions, getFilters } from '../../selectors/search-filter-selectors'
import { getSchedulingType } from '../../../shared/selectors/shared-scheduling-selectors'

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  flow: getSchedulingType(state),
  showPrompt: (ownProps.showPrompt) ? ownProps.showPrompt : false,
  enableUrl: ownProps.enableUrl,
  title: 'SORT BY:',
  disabled: ownProps.disabled,
  options: getSortOptions(state),
  currentValue: (ownProps.initialValue !== null) ? ownProps.initialValue : getSort(state), 
  customClass: 'doctorSearchSort',
  filters: getFilters(state),
  scheduleOverriden: state.doctorSearch.overrideScheduling
})

const mapDispatchToProps = (dispatch) => ({
  selectOptionHandler: (type, sortOrder) => dispatch({
    type: type,
    value: sortOrder
  }),
  turnOnOverride: () => dispatch({
    type: 'TURN_ON_ONLINE_SCHEDULING'
  }),
  turnOffOverride: () => dispatch({
    type: 'TURN_OFF_ONLINE_SCHEDULING'
  })
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  selectOptionHandler: (sortOrder) => {
    let type = null
    if (stateProps.disabled) {
      return
    }
    switch (stateProps.flow) {
      case 'fad':
        type = 'SET_DOCTOR_SEARCH_SORT_ORDER'
        break
      case 'cin':
        type = 'SET_DOCTOR_SEARCH_SORT_ORDER'
        break
      case 'sad':
        type = 'SET_DOCTOR_SEARCH_SORT_ORDER'
        break
      case 'mam':
        type = 'SET_LOCATION_SORT_ORDER'
        break
      case 'location':
        type = 'SET_LOCATION_SORT_ORDER'
        break
      case 'service':
        type = 'SET_SERVICE_SORT_ORDER'
        break
      default:
        type = 'CHECK_SORT_FLOW'
        break
    }
    let urlParams = getURLParameters()
    if (sortOrder === 'First Available' && stateProps.flow !== 'sad') {
      let exists = stateProps.filters.selectedAvailability.find(f => f === 'Doctors with Online Scheduling')
      if (!exists) {
        dispatchProps.turnOnOverride()
      }
      urlParams.filters.selectedAvailability = stateProps.filters.selectedAvailability
      let found = urlParams.filters.selectedAvailability.find(f => f === 'Doctors with Online Scheduling')
      if (!found) {
        urlParams.filters.selectedAvailability.push('Doctors with Online Scheduling')
      }
    } else if (stateProps.scheduleOverriden) {
      dispatchProps.turnOffOverride()
    }
    dispatchProps.selectOptionHandler(type, sortOrder)
    if (stateProps.enableUrl) {
      insertBrowserHistory({ ...urlParams, sort: sortOrder })
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Dropdown)
