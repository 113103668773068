import { connect } from 'react-redux'
import SuperFilter from './SuperFilter.jsx'
import { getFilters, getSort } from '../../../selectors/search-filter-selectors.js'

const mapStateToProps = (state, ownProps) => ({
  title: ownProps.title,
  enableUrl: ownProps.searchOptions ? ownProps.searchOptions.enableUrl : false,
  collapseable: ownProps.collapseable,
  filterKey: ownProps.filterKey,
  options: ownProps.getOption(state),
  subFilters: ownProps.getSubFilters && ownProps.getSubFilters(state),
  filters: getFilters(state),
  toggleCollapse: ownProps.toggleCollapse,
  collapse: ownProps.collapse,
  filteredProviderCount: ownProps.filteredProviderCount,
  showHideOptions: ownProps.showHideOptions,
  showMore: ownProps.showMore,
  sort: getSort(state),
  toggles: ownProps.toggles
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFilter: (filterValues) => dispatch({
    type: ownProps.dispatchType,
    value: filterValues
  }),
  setSubFilter: (filterValues) => dispatch({
    type: ownProps.subFilterDispatchType,
    value: filterValues
  }),
  showAlertModal: (value) => dispatch({
    type: 'DISPLAY_FIRST_PCP_MODAL',
    value
  }),
  setSearchOrder: (order) => dispatch({
    type: 'SET_DOCTOR_SEARCH_SORT_ORDER',
    value: order
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperFilter)
