import { createSelector } from 'reselect'
import { providerSorter } from '../../shared/sorters/provider-sorter'
import { getSelectedRange } from './search-input-selectors'

export const allDoctors = state => state.doctorSearch.providers
export const overrideAPP = state => state.doctorSearch.overrideAPP
export const sortOrder = state => state.doctorSearch.sortOrder
const isProvider = state => state.doctorSearch.isOPG || state.persistentData.schedulingType === 'cin'
const showAffiliatedDoctors = state => state.doctorSearch.showAffiliatedDoctors
export const getSearchQuery = state => state.searchInput.searchQuery || ''
export const getSearchLocation = state => state.searchInput.searchLocation || {}
export const getSelectedLanguage = state => state.doctorSearch.filters.selectedLanguage || []
export const getSelectedSpecialty = state => state.doctorSearch.filters.selectedSpecialty || []
export const getSelectedGender = state => state.doctorSearch.filters.selectedGender || []
export const getSelectedRating = state => state.doctorSearch.filters.selectedRating || []
export const getSelectedAvailability = state => state.doctorSearch.filters.selectedAvailability || []
export const getSelectedDoctorType = state => state.doctorSearch.filters.selectedDoctorType || []
export const getSelectedHospital = state => state.doctorSearch.filters.selectedHospital || []
export const getSelectedAffiliation = state => state.doctorSearch.filters.selectedAffiliation || []
export const getAutoCompleteDoctors = state => state.doctorSearch.autoCompleteDoctors

const addAvailabilityOptions = (availableOptions, doctors, option, valueCheck, isProvider) => {
  for (let i = 0; i < doctors.length; i++) {
    let currentDoctor = doctors[i]
    if (currentDoctor[valueCheck]) {
      availableOptions.push({ value: option, label: isProvider ? option.replace('Doctors', 'Providers') : option })
      break
    }
  }
  return availableOptions
}

const addDoctorTypeOptions = (typeOptions, doctors, option, label) => {
  for (let i = 0; i < doctors.length; i++) {
    let optionObject = { value: option, label: label }
    let currentDoctor = doctors[i]
    if (currentDoctor.medicalSuffix === option) {
      typeOptions.push(optionObject)
      break
    }
  }
  return typeOptions
}

const extractUniqueDoctorValues = (allDoctors) => {
  return {
    languages: [...new Set(allDoctors.map(doc => doc.languages).flatten())].sort(),
    medicalSuffix: [...new Set(allDoctors.map(doc => doc.medicalSuffix).flatten())].sort(),
    specialties: [...new Set(allDoctors.map(doc => doc.specialties).flatten())].sort(),
    affiliations: [...new Set(allDoctors.map(doc => doc.affiliations).flatten())].sort(),
    genders: [...new Set(allDoctors.map(doc => doc.gender).flatten())].sort(),
    hospitals: [...new Set(allDoctors.map(doc => doc.hospitals).flatten().flatten())].sort(),
    ratings: [...new Set(allDoctors.map(doc => doc.rating ? doc.rating.Rating : null).flatten())].filter(el => el != null)
  }
}

export const hasDistances = createSelector(allDoctors, (doctors) => {
  let hold = doctors.filter(doctor => doctor.distance != null && doctor.distance > 0)
  return hold.length > 0
})

export const allScheduling = createSelector(allDoctors, (doctors) => {
  let hold = doctors.filter(doctor => !doctor.hasScheduleOnline)
  return hold.length === 0
})

export const getProvidersByLanguage = createSelector(allDoctors, getSelectedLanguage, (allDoctors, selectedLanguage) => {
  if (selectedLanguage && selectedLanguage.length) {
    return allDoctors.filter(doctor => {
      return doctor.languages ? doctor.languages.filter(lang => {
        return selectedLanguage.includes(lang)
      })[0] : false
    })
  }
  return allDoctors
})

export const getProvidersBySpecialty = createSelector(allDoctors, getSelectedSpecialty, (allDoctors, selectedSpecialty) => {
  if (selectedSpecialty && selectedSpecialty.length) {
    return allDoctors.filter(doctor => {
      return doctor.specialties ? doctor.specialties.filter(special => {
        return selectedSpecialty.includes(special)
      })[0] : false
    })
  }
  return allDoctors
})

export const getProvidersByAffiliation = createSelector(allDoctors, getSelectedAffiliation, (allDoctors, selectedAffiliation) => {
  if (selectedAffiliation && selectedAffiliation.length) {
    return allDoctors.filter(doctor => doctor.affiliations ? doctor.affiliations.filter(affiliation => {
      return selectedAffiliation.includes(affiliation)
    })[0] : false)
  }
  return allDoctors
})

export const getProvidersByGender = createSelector(allDoctors, getSelectedGender, (allDoctors, selectedGender) => {
  return selectedGender.length ? allDoctors.filter(doctor => doctor.gender && selectedGender.includes(doctor.gender)) : allDoctors
})

export const getProvidersByRating = createSelector(allDoctors, getSelectedRating, (allDoctors, selectedRating) => {
  const rating = Math.max(...selectedRating)
  return selectedRating.length ? allDoctors.filter(doctor => doctor.rating && doctor.rating.Rating && Number(doctor.rating.Rating) >= rating) : allDoctors
})

export const getProvidersByDistance = createSelector(allDoctors, getSelectedRange, hasDistances, (allDoctors, selectedRange, hasDistances) => {
  return hasDistances ? allDoctors.filter(doctor => doctor.distance != null && Number(doctor.distance) <= Number(selectedRange)) : allDoctors
})

export const getProvidersByAvailability = createSelector(allDoctors, getSelectedAvailability, (allDoctors, selectedAvailability) => {
  if (selectedAvailability && selectedAvailability.length > 0) {
    if (selectedAvailability.length === 2) {
      return allDoctors.filter(doctor => doctor.hasScheduleOnline && doctor.isAcceptingPatients)
    } else {
      return allDoctors.filter(doctor => {
        return (selectedAvailability.includes('Doctors with Online Scheduling') && doctor.hasScheduleOnline) || (selectedAvailability.includes('Doctors Accepting New Patients') && doctor.isAcceptingPatients)
      })
    }
  }
  return allDoctors
})

export const getProvidersByDoctorType = createSelector(allDoctors, getSelectedDoctorType, (allDoctors, selectedDoctorType) => {
  return selectedDoctorType && selectedDoctorType.length ? allDoctors.filter(doctor => {
    return doctor.medicalSuffix && selectedDoctorType.includes(doctor.medicalSuffix)
  }) : allDoctors
})

export const getProvidersByHospital = createSelector(allDoctors, getSelectedHospital, (allDoctors, selectedHospitals) => {
  if (selectedHospitals && selectedHospitals.length > 0) {
    return allDoctors.filter(doctor => {
      return doctor.hospitals ? doctor.hospitals.filter(hos => {
        return selectedHospitals.includes(hos.Name)
      })[0] : false
    })
  }
  return allDoctors
})

export const getFilteredProviders = createSelector(getProvidersByRating, getProvidersByLanguage, getProvidersBySpecialty, getProvidersByGender, getProvidersByDistance, getProvidersByAvailability, getProvidersByDoctorType, getProvidersByHospital, getProvidersByAffiliation, sortOrder, allDoctors,
  (getProvidersByRating, getProvidersByLanguage, getProvidersBySpecialty, getProvidersByGender, getProvidersByDistance, getProvidersByAvailability, getProvidersByDoctorType, getProvidersByHospital, getProvidersByAffiliation, sortOrder, doctors) => {
    return providerSorter(doctors.filter(doctor => {
      return getProvidersByLanguage.includes(doctor) &&
        getProvidersBySpecialty.includes(doctor) &&
        getProvidersByRating.includes(doctor) &&
        getProvidersByGender.includes(doctor) &&
        getProvidersByAvailability.includes(doctor) &&
        getProvidersByDoctorType.includes(doctor) &&
        getProvidersByHospital.includes(doctor) &&
        getProvidersByAffiliation.includes(doctor) &&
        getProvidersByDistance.includes(doctor)
    }), sortOrder)
  })

export const hasAvailableAppointments = createSelector(getFilteredProviders, (doctors) => {
  let hold = doctors.filter(doctor => doctor.schedStartDates && doctor.schedStartDates.length > 0)
  return hold.length > 0
})

export const getAffiliatedProviders = createSelector(getFilteredProviders, (doctors) => {
  return doctors.filter(doctor => doctor.active === false)
})

export const getEmployedProviders = createSelector(getFilteredProviders, (doctors) => {
  return doctors.filter(doctor => doctor.active === true)
})

export const getPracticingProviders = createSelector(getEmployedProviders, getFilteredProviders, overrideAPP, (employed, allDocs, override) => {
  return override ? allDocs : employed
})

export const getAcceptingFromAllDoctors = createSelector(allDoctors, isProvider, (doctors, isProvider) => {
  let availableOptions = []
  availableOptions = addAvailabilityOptions(availableOptions, doctors, 'Doctors Accepting New Patients', 'isAcceptingPatients', isProvider)
  return availableOptions
})

export const getScheduleFromAllDoctors = createSelector(allDoctors, isProvider, (doctors, isProvider) => {
  let availableOptions = []
  availableOptions = addAvailabilityOptions(availableOptions, doctors, 'Doctors with Online Scheduling', 'hasScheduleOnline', isProvider)
  return availableOptions
})

export const getAvailabilityFromAllDoctors = createSelector(allDoctors, isProvider, (doctors, isProvider) => {
  let availableOptions = []

  availableOptions = addAvailabilityOptions(availableOptions, doctors, 'Doctors Accepting New Patients', 'isAcceptingPatients', isProvider)
  availableOptions = addAvailabilityOptions(availableOptions, doctors, 'Doctors with Online Scheduling', 'hasScheduleOnline', isProvider)

  return availableOptions
})

export const getDoctorTypeFromAllDoctors = createSelector(allDoctors, (doctors) => {
  let doctorTypeOptions = []
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'DO', 'DO (Doctor of Osteopathic Medicine)')
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'MD', 'MD (Doctor of Medicine)')
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'DDS', 'DDS (Doctor of Dental Surgery)')
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'DPM', 'DPM (Doctor of Podiatric Medicine)')
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'PhD', 'PhD (Doctor of Philosophy)')
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'PsyD', 'PsyD (Doctor of Psychology)')
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'CNP', 'CNP (Certified Nurse Practitioner)')
  doctorTypeOptions = addDoctorTypeOptions(doctorTypeOptions, doctors, 'PA-C', 'PA-C (Physician Assistant, Certified)')
  return doctorTypeOptions
})

export const getHospitalFromAllDoctors = createSelector(allDoctors, (doctors) => {
  let hospitalsAdded = []

  if (doctors && doctors.length > 0) {
    doctors.forEach(doctor => {
      let currentHospitals = doctor.hospitals
      if (currentHospitals && currentHospitals.length > 0) {
        currentHospitals.forEach(hospital => {
          let hospitalName = hospital.Name
          if (!hospitalsAdded.includes(hospitalName)) {
            hospitalsAdded.push(hospitalName)
          }
        })
      }
    })
  }
  hospitalsAdded.sort()
  return hospitalsAdded
})

export const getAffiliationFromAllDoctors = createSelector(allDoctors, (doctors) => {
  return extractUniqueDoctorValues(doctors).affiliations
})

export const getFilteredProviderCount = createSelector(getPracticingProviders, (practicingProviders) => {
  return practicingProviders.length
})

export const getAffiliatedProviderCount = createSelector(getAffiliatedProviders, (affiliatedProviders) => {
  return affiliatedProviders.length
})

export const getLanguagesFromAllDoctors = createSelector(allDoctors, (doctors) => {
  return extractUniqueDoctorValues(doctors).languages
})

export const getSpecialtiesFromAllDoctors = createSelector(allDoctors, (doctors) => {
  return extractUniqueDoctorValues(doctors).specialties
})

export const getGendersFromAllDoctors = createSelector(allDoctors, (doctors) => {
  return extractUniqueDoctorValues(doctors).genders
})

export const getProvidersWithRatings = createSelector(getFilteredProviders, (doctors) => {
  return extractUniqueDoctorValues(doctors).ratings
})

export const getActiveProviders = createSelector(getFilteredProviders, (doctors) => {
  return extractUniqueDoctorValues(doctors).ratings
})

export const getFADSortOptions = createSelector(getFilteredProviders, getProvidersWithRatings, showAffiliatedDoctors, hasDistances, hasAvailableAppointments, (doctors, allDoctorsRatings, showAffiliates, hasDistances, hasAppts) => {
  let sortOptions = [
    { value: 'Distance', label: 'Distance' },
    { value: 'A TO Z', label: 'Name: A TO Z' },
    { value: 'Top Rated', label: 'Top Rated' },
    { value: 'Most Reviewed', label: 'Most Reviewed' },
    { value: 'First Available', label: 'First Available' }
  ]

  sortOptions[0].disabled = !hasDistances
  sortOptions[1].disabled = !doctors.length
  sortOptions[2].disabled = !allDoctorsRatings.length || showAffiliates
  sortOptions[3].disabled = !allDoctorsRatings.length || showAffiliates
  sortOptions[4].disabled = !hasAppts

  return sortOptions
})

export const getDoctorSearchFilterCount = state => Object.keys(state.doctorSearch.filters).filter(x => state.doctorSearch.filters[x] && state.doctorSearch.filters[x].length).length
