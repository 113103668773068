import React from 'react'
import Modal from '../../../shared/components/Modal'
import { connect } from 'react-redux'
import { getSelectedAvailability } from '../../selectors/doctor-search-filter-selectors'
import { insertBrowserHistory } from '../../state/history'
import { getURLParameters, insertEmptyFilters } from '../../../shared/utility-functions'
import './first-available-modal.scss'

const mapStateToProps = (state, ownProps) => ({
  showModal: state.doctorSearch.showFirstAvailModal,
  enableUrl: ownProps.enableUrl,
  searchItems: ownProps.searchItems,
  clearAll: state.doctorSearch.clearAll,
  availability: getSelectedAvailability(state)
})

const mapDispatchToProps = (dispatch) => ({
  hideFunction: () => dispatch({
    type: 'DISPLAY_FIRST_PCP_MODAL',
    value: false
  }),
  setState: (sort, filters) => dispatch([{
    type: 'SET_DOCTOR_SEARCH_SORT_ORDER',
    value: sort
  }, {
    type: 'SET_SELECTED_AVAILABILITY',
    value: filters
  }]),
  resetFilters: () => dispatch([{
    type: 'RESET_SCHEDULE_A_DOCTOR_FILTERS'
  }, {
    type: 'RESET_CLEAR_ALL'
  }])
})

const mergeProps = (stateProps, dispatchProps) => {
  return {
    showModal: stateProps.showModal,
    hideFunction: dispatchProps.hideFunction,
    setState: () => {
      let sort = 'A TO Z'
      let filters = stateProps.availability.filter(f => f !== 'Doctors with Online Scheduling')
      if (stateProps.searchItems && stateProps.searchItems.length && stateProps.searchItems.filter(t => t.distance)[0]) {
        sort = 'Distance'
      }

      dispatchProps.setState(sort, filters)
      if (stateProps.clearAll) {
        dispatchProps.resetFilters()
      }
      let clear = stateProps.clearAll
      if (stateProps.enableUrl) {
        let urlParams = getURLParameters()
        urlParams.sort = sort
        if (clear) {
          insertBrowserHistory(insertEmptyFilters(urlParams))
        } else {
          urlParams.filters.selectedAvailability = filters
          insertBrowserHistory({ ...urlParams })
        }
      }
      dispatchProps.hideFunction()
    }
  }
}

const FirstAvailableModal = (props) => {
  return (
    <Modal showModal={props.showModal} hideFunction={props.hideFunction}>
      <div className='first-avail-modal'>
        <div className='title'>Turn off sort by first available appointment?</div>
        <div className='subheader'>Only doctors with online scheduling can be sorted by first available appointment.</div>
        <button className='btn btn-primary' onClick={props.hideFunction}>Cancel</button>
        <button className='btn btn-primary' onClick={props.setState}>Turn Off</button>
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FirstAvailableModal)
