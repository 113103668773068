import { connect } from 'react-redux'
import FiltersRow from './FiltersRow.jsx'
import { getDoctorSearchFilterCount } from '../../../selectors/doctor-search-filter-selectors'

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  selectedFiltersCount: getDoctorSearchFilterCount(state)
})

export default connect(mapStateToProps)(FiltersRow)
