import { race, put, take, delay } from 'redux-saga/effects'
import fetchData from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import locationTransformer from '../../shared/data-mappers/location-page-transformer'
import { LOCATION_SEARCH_ENDPOINT } from '../../shared/constants/api-helpers.js'

export default function * locationSearchSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_LOCATIONS')
    let params = action.params ? Object.assign({}, action.params) : null
    params.endPoint = LOCATION_SEARCH_ENDPOINT
    yield put({ type: 'SEARCH_LOCATIONS' })
    yield put({ type: 'CLEAR_LOCATIONS' })
    yield put({ type: 'SET_LOCATION_CALL_FAILED', value: false })

    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts && posts.success) {
      yield put({ type: 'SET_LOCATIONS', value: locationTransformer(posts.results) })
      if (params.lat && params.lng && params.dist) {
        yield put({ type: 'SET_LOCATION_SORT_ORDER', value: 'Distance' })
      }
    } else {
      yield put({ type: 'SET_LOCATION_CALL_FAILED', value: true })
    }
  }
}
