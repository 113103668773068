import React from 'react'
import SVGInline from 'react-svg-inline'
import URLS from '../../../shared/constants/urls'
import { getPageNumber, getShowAll } from '../../../shared/utility-functions/searchParams.js'
import { buildProfilePageUrl, generatePdf, getURLParameters } from '../../../shared/utility-functions'
import { scrollToLocation } from '../../../shared/utility-functions'
import ArrowRight from '../../../shared/assets/arrow-right.svg'
import { insertBrowserHistory } from '../../../Search/state/history'
import { ClassesCard } from '../../../Blocks/Classes/ClassesCard/index.js'
import ScheduleADoctorProviderCardConnector from '../../../Search/components/SearchCards/ProviderCard/ScheduleADoctorProviderCardConnector.jsx'
import ServiceCard from '../../../Search/components/SearchCards/ServiceCard/ServiceCard.jsx'
import { InsuranceCard } from '../../../Blocks/InsuranceSearch/InsuranceCard'
import AffiliatedProviderCard from '../../../Search/components/SearchCards/AffiliatedProviderCard'
import LocationCardConnector from '../../../Search/components/SearchCards/LocationCard/LocationCardConnector.jsx'
import { Tooltip } from 'react-tooltip'

import './pagination.scss'

export default class Pagination extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      start: 0,
      end: this.props.pageSize,
      hasMore: this.props.searchItems.length > this.props.pageSize,
      showAll: getShowAll(),
      mobile: window.innerWidth < 1200,
      totalPages: this.props.searchItems ? Math.ceil(this.props.searchItems.length / this.props.pageSize) : 1
    }
    this.loadItems = this.loadItems.bind(this)
    this.resetPage = this.resetPage.bind(this)
    this.lazyLoadAll = this.lazyLoadAll.bind(this)
    this.handleLazyLoad = this.handleLazyLoad.bind(this)
    this.toggleAffiliated = this.toggleAffiliated.bind(this)
    this.move = this.move.bind(this)
    this.onCurrentPageKeyUp = this.onCurrentPageKeyUp.bind(this)
    this.mobileCheck = this.mobileCheck.bind(this)
    this.addPage = this.addPage.bind(this)
    if (window.innerWidth < 1200) {
      this.state = { end: this.props.searchItems.length, showAll: true }
      this.props.setPageNumber(1)
      if (this.props.enableUrl) {
        insertBrowserHistory({ showAll: true, ...getURLParameters() }, 1)
      }
    }
    window.addEventListener('resize', this.mobileCheck, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.mobileCheck)
  }

  resetPage(stateParams) {
    this.props.setPageNumber(1)
    this.setState({ page: 1, start: 0, end: stateParams.showAll ? this.props.searchItems.length : this.props.pageSize, hasMore: this.props.searchItems.length > this.props.pageSize, totalPages: this.props.searchItems ? Math.ceil(this.props.searchItems.length / this.props.pageSize) : 1, ...stateParams })
    if (this.props.enableUrl) {
      insertBrowserHistory({ ...getURLParameters(), showAll: stateParams.showAll }, 1)
    }
    if (stateParams.showAll) {
      window.removeEventListener('scroll', this.handleLazyLoad)
    }
  }

  mobileCheck() {
    if (window.innerWidth < 1200 && !this.state.mobile) {
      this.resetPage({ showAll: true, mobile: true })
    } else if (window.innerWidth >= 1200 && this.state.mobile) {
      this.resetPage({ showAll: false, mobile: false })
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.searchItems) !== JSON.stringify(prevProps.searchItems)) {
      if (this.props.enableUrl) {
        let showAll = getURLParameters().showAll
        let pageNumberFromUrl = getPageNumber() ? parseInt(getPageNumber()) : 1
        if (window.innerWidth < 1200 || (window.innerWidth >= 1200 && showAll)) {
          this.resetPage({ showAll: true })
        } else {
          this.loadItems(pageNumberFromUrl)
        }
      } else {
        this.resetPage({ showAll: this.state.showAll })
      }
    }
  }

  componentDidMount() {
    if (window.innerWidth >= 1200) {
      window.addEventListener('scroll', this.handleLazyLoad)
    }
    if (this.props.searchItems.length > 0) {
      if (this.state.showAll) {
        this.props.setPageNumber(1)
        this.setState({ page: 1, end: this.props.pageSize })
      } else {
        let pageNumberFromUrl = getPageNumber() ? parseInt(getPageNumber(), 10) : 1
        this.loadItems(pageNumberFromUrl)
      }
    }
    this.mobileCheck();
  }

  loadItems(pageNumber) {
    if (window.innerWidth >= 1200) {
      let hasMore = true
      let start = this.props.pageSize * (pageNumber - 1)
      let end = start + this.props.pageSize
      if (end >= this.props.searchItems.length) {
        hasMore = false
        end = this.props.searchItems.length
      }
      this.setState({
        page: pageNumber,
        hasMore,
        start,
        end,
        totalPages: this.props.searchItems ? Math.ceil(this.props.searchItems.length / this.props.pageSize) : 1
      })
      this.props.setPageNumber(pageNumber)
      if (this.props.enableUrl) {
        insertBrowserHistory(getURLParameters(), pageNumber)
      }
    }
  }

  results() {
    let searchItemCards = this.props.searchItems.length > 0 ? this.props.searchItems.slice(this.state.start, this.state.end).map((item, index) => {
      let card
      if (this.props.flow === 'classes') {
        card = <div className='provider-box' key={index}><ClassesCard id={`provider-card-${index}`} class={item} /></div>
      } else if (this.props.flow === 'fad' || this.props.flow === 'sad') {
        card = <div className='provider-box' key={index}>
          <ScheduleADoctorProviderCardConnector
            id={`provider-card-${item.npi}`}
            options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
            provider={item} />
        </div>
      } else if (this.props.flow === 'cin') {
        if (this.state.mobile) {
          card = <div className='provider-box' key={index}>
            <ScheduleADoctorProviderCardConnector
              id={`provider-card-${item.npi}`}
              options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
              provider={item} />
          </div>
        } else {
          const profileClick = () => {
            if (this.props.providerCardOptions && this.props.providerCardOptions.enableUrl && sort !== 'Random') {
              insertBrowserHistory(getURLParameters(), pageNumber, item.npi)
            }
          }

          let profilePageURL
          if (!this.props.providerCardOptions.profileRoot) {
            let rootUrl = URLS.rootUrl
            profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${window.location.hostname === 'localhost' ? 'localhost' : rootUrl}${URLS.findADoctorUrl}${URLS.profileUrl}/`, item.title, item.npi)
          } else {
            profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${this.props.providerCardOptions.profileRoot}/`, item.title, item.npi)
          }
          const practices = Object.values(item.practices).join(', ')
          const address = item.address ? `${item.address.adr} ${item.address.c}, ${item.address.s} ${item.address.z}` : ''
          card = <tr key={`provider-row-${index}`}>
            <td>
              <a className='title-profile-url' href={profilePageURL} onClick={profileClick} target='_self'>
                <div className={'title'}><b>{item.title}</b></div>
              </a>
            </td>
            <td>{item.specialties.join(', ')}</td>
            <td>
              <span data-tooltip-id={`practices-tooltip-${index}`} data-tooltip-content={practices}>{`${practices.slice(0, 20)}${practices.length > 20 ? '...' : ''}`}</span>
              <Tooltip id={`practices-tooltip-${index}`} />
            </td>
            <td><a href={`tel:${item.phone}`}><b>{item.phone}</b></a></td>
            <td>
              <span data-tooltip-id={`address-tooltip-${index}`} data-tooltip-content={address}>{`${address.slice(0, 20)}${address.length > 20 ? '...' : ''}`}</span>
              <Tooltip id={`address-tooltip-${index}`} /></td>
          </tr>
        }
      } else if (this.props.flow === 'location' || this.props.flow === 'mam') {
        card = <div className='provider-box' key={index}>
          <LocationCardConnector
            id={`location-card-${item.Id}`}
            options={{ ...this.props.providerCardOptions, enableUrl: this.props.enableUrl }}
            location={item} />
        </div>
      } else if (this.props.flow === 'service') {
        card = <div className='service-box' key={index}><ServiceCard service={item} /></div>
      } else if (this.props.flow === 'insurance') {
        card = <div className='provider-box' key={index}><InsuranceCard id={`provider-card-${index}`} plan={item} /></div>
      }
      return card
    }) : []

    return searchItemCards
  }

  move(key) {
    let page = key === '-' ? this.state.page - 1 : this.state.page + 1
    this.props.changeDesktopState(false, false)
    this.loadItems(page)
    this.props.handleStickyElements()
  }

  onCurrentPageKeyUp(event) {
    if (event.keyCode === 13) {
      let input = document.querySelector('.next-button')
      if (input) input.blur()
    }
    this.setState({ showAll: false })
    let newPage = event.target.value !== '' ? parseInt(event.target.value) : 0
    newPage = newPage > this.state.totalPages ? this.state.totalPages : newPage
    newPage = newPage > 0 ? newPage : 1
    this.loadItems(newPage)
  }

  addPage() {
    let hasMore = true
    let start = 0
    let nextPageNumber = this.state.page + 1
    let end = start + (this.props.pageSize * nextPageNumber)
    if (end >= this.props.searchItems.length) {
      hasMore = false
      end = this.props.searchItems.length
    }
    this.setState({
      page: nextPageNumber,
      hasMore,
      start,
      end,
      totalPages: this.props.searchItems ? Math.ceil(this.props.searchItems.length / this.props.pageSize) : 1
    })
    this.props.setPageNumber(nextPageNumber)
  }

  handleLazyLoad() {
    if (this.state.showAll && window.innerWidth >= 1200) {
      let bufferNumber = 200
      let footerTop = document.getElementsByClassName('footer')[0] ? document.getElementsByClassName('footer')[0].getBoundingClientRect().top : null
      if (footerTop - bufferNumber < window.innerHeight && this.state.end < this.props.searchItems.length) {
        this.addPage()
      }
    }
  }

  toggleAffiliated() {
    this.setState({ page: 1 })
    this.props.setPageNumber(1)
    if (this.props.enableUrl) {
      insertBrowserHistory({ ...getURLParameters(), showAll: this.state.showAll }, 1)
    }
    this.props.toggleAffiliatedDoctors()
  }

  lazyLoadAll() {
    if (window.innerWidth >= 1200) {
      let showAll = !this.state.showAll
      this.props.setPageNumber(1)
      if (showAll) {
        this.setState({ page: 1, start: 0, showAll: showAll, hasMore: this.props.searchItems.length > this.props.pageSize, end: this.state.page * this.props.pageSize })
        if (this.props.enableUrl) {
          insertBrowserHistory({ ...getURLParameters(), showAll: true }, 1)
        }
      } else {
        this.setState({
          page: 1,
          start: 0,
          end: showAll ? this.props.searchItems.length : this.props.pageSize,
          showAll: showAll,
          hasMore: this.props.searchItems.length > this.props.pageSize,
          totalPages: this.props.searchItems ? Math.ceil(this.props.searchItems.length / this.props.pageSize) : 1
        })
        if (this.props.enableUrl) {
          let params = getURLParameters()
          params.showAll = null
          insertBrowserHistory(params, 1)
        }
      }
    }
  }

  render() {
    let expanded = this.state.page > 9
    let showAllClass = `view-all ${this.state.showAll ? 'less' : 'all'}`
    showAllClass += this.props.searchItems.length / this.props.pageSize <= 1 ? ' display-none' : ''
    return (
      <div className='doctor-search-provider-results pagination'>
        {
          this.props.searchItems && this.props.searchItems.length > this.props.pageSize
            ? <div className={`page-controls top hide-for-not-desktop ${this.props.isBlock ? 'hide-view-all' : ''}`}>
              <div>
              {this.props.flow === 'cin' && (
                <button
                  className='btn apply-button desktop'
                  onClick={() => generatePdf(this.props.searchItems.length > 0 ? this.props.searchItems : [])}
                  disabled={this.props.searchItems && this.props.searchItems.length > 500}
                  data-tooltip-id={this.props.searchItems && this.props.searchItems.length>500?`pdf-btn-tooltip`:''}
                  data-tooltip-content={'Search needs to be narrowed in order to print'}>
                  Print
                </button>
              )}
                <Tooltip id={`pdf-btn-tooltip`} />
              </div>
              {!this.state.showAll && <a className={`prev-button ${this.state.page > 1 ? '' : 'disabled'}`} name='Previous Page Button' onClick={() => { if (this.state.page > 1) this.move('-') }}>
                <SVGInline accessibilityDesc='Previous Page Arrow' svg={ArrowRight} />
              </a>}
              {!this.state.showAll && <div className='page-numbers'>
                <input className={`current-page ${expanded ? 'expanded' : ''}`} title='Page Number Input' type='number' value={this.state.page} onBlur={this.onCurrentPageKeyUp} onKeyUp={(e) => { e.keyCode === 13 && this.onCurrentPageKeyUp(e) }} /> of <span className='total'>{this.state.showAll ? '1' : this.state.totalPages}</span>
              </div>}
              {!this.state.showAll && <a className={`next-button ${this.state.hasMore ? '' : 'disabled'}`} name='Next Page Button' onClick={() => { if (this.state.hasMore) this.move() }}>
                <SVGInline accessibilityDesc='Next Page Arrow' svg={ArrowRight} />
              </a>}
              <div aria-hidden='true' className={showAllClass}><a aria-hidden='true' onClick={this.lazyLoadAll}>View {this.state.showAll ? 'Less' : 'All'}</a></div>
            </div>
            : <div className='page-controls top small-results hide-for-not-desktop'>
              {
                this.props.flow === 'cin' && this.props.searchItems && this.props.searchItems.length <= 500
                  ? <div>
                    <button className='btn apply-button desktop' onClick={() => generatePdf(this.props.searchItems.length > 0 ? this.props.searchItems : [])}>Print</button>
                  </div>
                  : null
              }
            </div>
        }
        <div className='loaded-results-container'>
          {
            !this.state.mobile && this.props.flow === 'cin'
              ? <table className={'table'}>
                <tr>
                  <th>Physician Name</th>
                  <th>Specialties</th>
                  <th>Practices</th>
                  <th>Phone</th>
                  <th>Address</th>
                </tr>
                {this.results()}
              </table>
              : this.results()
          }
          {
            !this.state.hasMore && this.props.showAffiliatedProviderCard &&
            <div className='provider-box'>
              <AffiliatedProviderCard enableAffiliatedDoctors={this.toggleAffiliated} />
            </div>
          }
          {this.state.hasMore && <div className='show-more mobile-show-more' onClick={() => { this.addPage() }}><span>Show more <SVGInline svg={ArrowRight} /></span></div>}
        </div>
        {
          this.props.searchItems && this.props.searchItems.length > this.props.pageSize &&
          <div className='page-controls bottom hide-for-not-desktop'>
            {!this.state.showAll && <a className={`prev-button ${this.state.page > 1 ? '' : 'disabled'}`} name='Lower Previous Page Button' onClick={() => { if (this.state.page > 1) this.move('-'); scrollToLocation('.page-controls') }}>
              <SVGInline accessibilityDesc='Previous Page Arrow' svg={ArrowRight} />
            </a>}
            {!this.state.showAll && <div className='page-numbers'>
              <input className={`current-page ${expanded ? 'expanded' : ''}`} title='Lower Page Number Input' type='number' value={this.state.page} onBlur={this.onCurrentPageKeyUp} onKeyUp={(e) => { if (e.keyCode === 13) { this.onCurrentPageKeyUp(e); scrollToLocation('.page-controls') } }} /> of <span className='total'>{this.state.showAll ? '1' : this.state.totalPages}</span>
            </div>}
            {!this.state.showAll && <a className={`next-button ${this.state.hasMore ? '' : 'disabled'}`} name='Lower Next Page Button' onClick={() => { if (this.state.hasMore) this.move(); scrollToLocation('.page-controls') }}>
              <SVGInline accessibilityDesc='Next Page Arrow' svg={ArrowRight} />
            </a>}
          </div>
        }
      </div>
    )
  }
}
