import AlertMessageBar from '../AlertMessageBar'
import SVGInline from 'react-svg-inline'
import errorIcon from '../../../assets/error-icon.svg'
import './error-message-bar.scss'

const ErrorMessageBar = ({text, retryHandler}) => (
  <AlertMessageBar className='error-message' text={text} actionText={'Try Again'} actionHandler={retryHandler} >
    <SVGInline svg={errorIcon} />
  </AlertMessageBar>
)

export default ErrorMessageBar
