import { connect } from 'react-redux'
import { PreviousPatientConnector } from '../../../../shared/components/PreviousPatient'
import SuperLocationCardAlt from './SuperLocationCardAlt.jsx'

const mapStateToProps = (state, ownProps) => {
  if (state.doctorSearch.disableFirstAvailable) {
    ownProps.options.displayAvailability = false
  }
  return {
    flow: state.persistentData.schedulingType,
  }
}

const mapDispatchToProps = (dispatch) => ({
  profileLinkClick: (provider) => {
    dispatch({ type: 'SET_DOCTOR_DETAILS', value: provider })
    dispatch({ type: 'FETCH_PROVIDER_COMMENTS', providerNPI: provider.npi })
  },
  scheduleLinkClick: (url) => {
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: PreviousPatientConnector })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SuperLocationCardAlt)
