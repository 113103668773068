import React from 'react'
import './checkbox.scss'
import {guid} from '../../utility-functions/guid.js'

const Checkbox = ({ value, onChangeHandler, label, checked, subfilter = undefined, parentfilter = undefined }) => {
  let checkboxId = guid()
  let checkboxClass = `shared-checkbox-label ${value ? value.replace(/ +/g, '-').toLowerCase() : ''}`
  let subfilterMainContainer = ''

  if (subfilter) {
    checkboxClass += ' subFilterClass'
    subfilterMainContainer = 'subFilter-checkbox-container'
  }
  return <div className={subfilterMainContainer}>
    <input type='checkbox'
      className='shared-checkbox'
      id={checkboxId}
      value={value}
      checked={checked}
      onChange={onChangeHandler}
      subfilter={subfilter}
      parentfilter={parentfilter}
    />
    <label htmlFor={checkboxId} className={checkboxClass}>
      {label}
    </label>
  </div>
}

export default Checkbox
