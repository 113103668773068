import React from 'react'
import SVGInline from 'react-svg-inline'
import CloseIcon from '../../../../shared/assets/x.svg'
import Modal from '../../../../shared/components/Modal'
import './press-ganey-modal.scss'

export default class PressGaneyModal extends React.Component {
  render () {
    const { show, handleClose } = this.props
    return (

      <span className='press-ganey-outer'>
        <Modal showModal={show} hideCloseButton customClass={'no-bg-color'} hideFunction={handleClose}>
          <div className='ganey-close-button' onClick={handleClose}>
            <SVGInline className='ganey-close-button' svg={CloseIcon} />
          </div>
          <div className='press-ganey-modal'>

            <div className='press-ganey-logo' />
            <p className='caption-text explain'>
            Our Patient Satisfaction Rating is an average of all responses to care provider-related questions from our nationally-recognized Press Ganey Patient Satisfaction Survey.
            </p>
            <p className='caption-text rating'>
            Responses are measured on a scale of 1 to 5 with 5 being the best score.
            </p>
            <a className='display-inline' href='/about-press-ganey'>Learn More</a>
          </div>
        </Modal>

      </span>
    )
  }
}
