import { connect } from 'react-redux'
import FindPage from '../FindPage.jsx'
import { getURLParameters } from '../../shared/utility-functions/searchParams.js'
import { isEmptyObject } from '../../shared/utility-functions'
import { getFilteredLocationsCount, getFilteredLocations, getLocationFilterCount } from '../selectors/location-search-filter-selectors'
import { getFilterLoadingState } from '../selectors/search-filter-selectors'

const mapStateToProps = (state, ownProps) => {
  return {
    flow: ownProps.flow,
    searchType: 'location',
    displayType: ownProps.displayType,
    showCityFilter: ownProps.searchOptions ? ownProps.searchOptions.showCityFilter : false,
    searchOptions: ownProps.searchOptions,
    filterOptions: ownProps.filterOptions,
    orgID: ownProps.searchOptions.orgID,
    itemIds: ownProps.searchOptions.locationIds,
    latitude: ownProps.searchOptions.latitude,
    longitude: ownProps.searchOptions.longitude,
    distance: ownProps.searchOptions.distance,
    headline: ownProps.headline,
    urlParameters: getURLParameters(),
    searchItems: getFilteredLocations(state),
    searchItemCount: getFilteredLocationsCount(state),
    filteredProviderCount: getFilteredLocationsCount(state),
    affiliatedProviderCount: 0,
    filtersLoading: getFilterLoadingState(state),
    filtersOpen: state.locationSearch.filtersOpen,
    dataLoaded: state.locationSearch.dataLoaded,
    searched: state.locationSearch.searched,
    fetchCallFailed: state.locationSearch.callFailed,
    filters: state.locationSearch.filters,
    filterCount: getLocationFilterCount(state),
    questionnaireCompleted: ownProps.questionnaireCompleted
  }
}

const mapDispatchToProps = (dispatch) => ({
  setPage: (flow) => dispatch({
    type: 'SET_SCHEDULING_TYPE',
    value: flow
  }),
  setSort: (sortOrder) => dispatch({
    type: 'SET_LOCATION_SORT_ORDER',
    value: sortOrder
  }),
  setVisitType: (visitTypeId) => dispatch({
    type: 'SET_VISIT_TYPE',
    id: visitTypeId
  }),
  clearPage: () => dispatch({ type: 'CLEAR_STORE' }),
  runMamSearch: (params) => dispatch({
    type: 'GET_MAM_LOCATIONS',
    params: params
  }),
  runLocationSearch: (params) => dispatch({
    type: 'GET_LOCATIONS',
    params: params
  }),
  setFilters: (params) => dispatch({
    type: 'SET_LOCATION_FILTERS',
    params: params
  }),
  resetFilters: () => dispatch({
    type: 'RESET_LOCATION_FILTERS'
  }),
  setPageNumber: (number) => dispatch({
    type: 'SET_LOCATION_CURRENT_PAGE',
    number: number
  }),
  fetchAutocompleteData: () => dispatch({
    type: 'GET_LOCATIONS_AUTOCOMPLETE_DATA',
    ids: ''
  }),
  hideSchedulingModal: () => {
    dispatch({ type: 'HIDE_SCHEDULING_MODAL' })
  }
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  runSearch: (params) => {
    if (stateProps.flow === 'mam') {
      dispatchProps.setSort('A TO Z')
      dispatchProps.runMamSearch(params)
    } else {
      dispatchProps.runLocationSearch(params)
    }
  },
  initializePage: () => {
    if (stateProps.flow === 'mam') {
      dispatchProps.setSort('A TO Z')
      dispatchProps.setPage(stateProps.flow)
      let visitType = window.sessionStorage.getItem('VisitTypeID')
      dispatchProps.runMamSearch(searchCheck({ 'VisitTypeId': visitType }, stateProps.searchOptions))
      if (!isEmptyObject(stateProps.urlParameters.filters)) {
        dispatchProps.setFilters(stateProps.urlParameters.filters)
      }
      if (visitType) {
        dispatchProps.setVisitType(visitType)
      }
    } else {
      dispatchProps.fetchAutocompleteData()
      dispatchProps.setPage('location')
      stateProps.flow = 'location'
      let sort = (stateProps.searchOptions && stateProps.searchOptions.defaultSort) ? stateProps.searchOptions.defaultSort : 'A TO Z'
      dispatchProps.setSort(sort)
      dispatchProps.setFilters({})
      let searchParam = {}
      if (stateProps.orgID) {
        searchParam.org = stateProps.orgID
      }
      if (stateProps.itemIds && stateProps.itemIds.length) {
        searchParam.ids = stateProps.itemIds
      }
      if (stateProps.latitude || stateProps.longitude) {
        searchParam.lat = stateProps.latitude
        searchParam.lng = stateProps.longitude
        searchParam.dist = stateProps.distance || 50
        dispatchProps.setSort('Distance')
        stateProps.searchOptions.defaultSort = 'Distance'
      }
      searchParam = searchCheck(searchParam, stateProps.searchOptions)
      dispatchProps.runLocationSearch(searchParam)
    }
  }

})

const searchCheck = (obj, searchOptions) => {
  if (searchOptions && searchOptions.enableUrl) {
    let search = getURLParameters().search
    for (var param in search) {
      if (search.hasOwnProperty(param) && search[param] !== '' && search[param]) {
        obj[param] = search[param]
      }
    }
  }
  return obj
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FindPage)
