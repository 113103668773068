import React from 'react'

export default class FilteredTooFar extends React.Component {
  constructor (props) {
    super(props)
    this.type = this.props.flow
    this.isLocationSelected = this.props.isLocationSelected ? true : false
  }

  render () {
    return (
      <div className='no-results-found-container'>
        <div className='opg-error-container'>
          <h4>We didn't find any results matching your filtering criteria {this.isLocationSelected ? 'at this location' : ''}.</h4>
          <div className='opg-message-container'>
            <div>Please consider:</div>
            <ul className='suggestions-opg'>
              {this.isLocationSelected && <li>Selecting a different location.</li>}
              <li>Clearing your filters.</li>
              <li>Entering a new search query.</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
