import React from 'react'
import URLS from '../../constants/urls'
import { scheduleQuestionEvent } from '../../utility-functions/googleTagManager'

export default ({hideFunction, doctorUrl}) => {
  const onClick = (answer) => {
    const dataLayerObject = {
      flow: 'fad',
      scheduleQuestionNumber: undefined,
      scheduleQuestion: 'Have you seen this doctor in the past three years?',
      scheduleQuestionAnswer: answer
    }

    scheduleQuestionEvent(dataLayerObject)
    if (answer === 'Yes') hideFunction()
  }

  return (
    <div className='scheduling-modal-inner-content'>
      <div className='modal-text'>
        <h2>Before You Schedule</h2>
        <div className='sub-heading'>
          Have you seen this doctor in the past three years?
        </div>
      </div>
      <div className='button-container'>
        <a id='previous_patient_no' target='_self' className='btn blue-button continue-button' href={doctorUrl} onClick={() => { onClick('No') }}>No</a>
        <a id='previous_patient_yes' className='btn blue-button continue-button' onClick={() => { onClick('Yes') }} href={URLS.mychartApptUrl} target='_blank'>
          YES
        </a>
      </div>
    </div>
  )
}
