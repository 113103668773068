import '../About/about.scss'
import { OHGCINLogo, MDAndersonLogo, MDAndersonUrl } from '../../../../../../shared/constants/affiliations.js'
import InnerHTMLHelper from '../../../../../../shared/components/InnerHTMLHelper/InnerHTMLHelper.jsx'
import sanitizeHtmlReact from 'sanitize-html-react'

const About = ({ provider, years }) => {
  const sanitizeHtml = (input) => {
    return sanitizeHtmlReact(input, { allowedTags: ['p', 'br'] }).replace(/\{line break\}/g, '')
  }
  const bioSanitized = provider ? sanitizeHtml(provider.bio) : ''

  const languages = () => {
    if (!provider.languages || !provider.languages.length > 0) return
    return (
      <p className='test-languages'><b>Additional Languages: </b>{provider.languages.join('~ ').replace(/~ ([^~]*)$/, ' and $1').replace('~', ',')}</p>
    )
  }
  const hospitals = () => {
    let keys = []
    provider.hospitals.forEach(hospital => {
      let found = keys.filter(key => key.status === hospital.Status)
      if (!found || !found.length) {
        keys.push({ status: hospital.Status, hospitals: provider.hospitals.filter(hosp => hosp.Status === hospital.Status) })
      }
    })
    return keys.map((key, index) => {
      if (key.hospitals && key.hospitals.length > 0) {
        return (
          <div key={index} className='about-hospital'>
            <div className=''>{key.status} at:</div>
            {
              key.hospitals.map((hospital, index2) => {
                return hospital.PageUrl
                  ? <div className='test-hospital' key={index2}><a className='alt' href={'http://' + window.location.hostname + '/' + hospital.PageUrl}>{hospital.Name}</a></div>
                  : <div className='test-hospital' key={index2}>{hospital.Name}</div>
              })
            }
          </div>
        )
      }
    })
  }

  const hasOHG = () => {
    return !!provider.affiliations.find(affiliation => affiliation.toLowerCase().indexOf('clinically integrated network') > -1)
  }

  const hasOHVI = () => {
    return !!provider.affiliations.find(affiliation => affiliation.toLowerCase().indexOf('ohiohealth vascular institute') > -1)
  }

  const hasMDA = () => {
    return !!provider.affiliations.find(affiliation => affiliation.toLowerCase().indexOf('md anderson') > -1)
  }

  return (
    <div>
      <h4 className='provider-about-header'>About</h4>
      <div className='provider-about-container'>
        {
          provider.bio &&
          <InnerHTMLHelper className='test-bio' tagName='p' html={bioSanitized} />
        }
        {
          provider.philosophy &&
          <p className='test-philosophy'><b>Philosophy of Care: </b>{provider.philosophy}</p>
        }
        {
          years !== null &&
          <p className='test-years'><b>Experience: </b>{years <= 1 ? '1 Year' : `${years} Years`}</p>
        }

        {languages()}

        {
          provider.hospitals && provider.hospitals.length > 0 &&
          <div>
            <h5>Hospital Affiliation</h5>
            {hospitals()}
          </div>
        }
        {
          provider.affiliations && provider.affiliations.length > 0 &&
          <div>
            <h5>Special Affiliations</h5>
            {
              hasOHG() &&
              <div className='special-affiliation-container'>
                <img src={OHGCINLogo} alt='ohiohealth clinically integrated network' class='provider-about-img' />
                <p className='show-for-print-only'>OhioHealth Clinically Integrated Network</p>
              </div>
            }
            {
              hasOHVI() &&
              <div className='special-affiliation-container'>
                <a href='https://www.ohiohealth.com/services/heart-and-vascular/ohiohealth-vascular-institute/' title='OhioHealth Vascular Institute' target='_blank' className='hnv special-affiliation'>OhioHealth Vascular Institute</a>
              </div>
            }
            {
              hasMDA() &&
              <div className='special-affiliation-container'>
                <a className='special-affiliation md-anderson' href={MDAndersonUrl} target='_blank'>
                  <img src={MDAndersonLogo} alt='MD Anderson Cancer Network certified physician' className='provider-about-img' />
                  <p className='show-for-print-only'>MD Anderson Cancer Network® certified physician</p>
                </a>
              </div>
            }
          </div>
        }
      </div>

      <div className='flex justify-center'>
        <div className='drawer-divider' />
      </div>
    </div>
  )
}

export default About
