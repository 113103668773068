export const DEFAULT_STATE = {
  doctorDetails: null
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_DOCTOR_DETAILS':
      return { ...state, ...{ doctorDetails: action.value } }
    case 'SET_PROVIDER_COMMENTS':
      return addProviderReviews(state, action.value)
    case 'LOADING_PROVIDER_COMMENTS':
      return { ...state, ...{ commentsLoading: action.value } }
    case 'LOADING_COMMENTS_FAILED':
      return { ...state, ...{ commentsCallFailed: action.value } }
  }
  return state
}

const addProviderReviews = (state, reviewData) => {
  let newDetails = state.doctorDetails || {}
  newDetails.reviews = reviewData
  return { ...state, ...{ doctorDetails: newDetails } }
}
