import { race, put, take, delay } from 'redux-saga/effects'
import { fetchData } from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { AVAILABLE_APPOINTMENTS_ENDPOINT } from '../../shared/constants/api-helpers.js'

export default function * availableApointmentsEndPointSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_AVAILABLE_APPOINTMENTS_ENDPOINT_DATA')
    let params = {}

    params.physicianId = '183736'
    params.endData = '2023-10-01'
    params.startDate = '2023-10-31'
    params.schedulingType = 'Open'
    params.endPoint = AVAILABLE_APPOINTMENTS_ENDPOINT

    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts) {
      yield put({ type: 'SET_AVAILABLE_APPOINTMENTS_ENDPOINT_DATA', value: posts })
    }

    if (action.resolve) action.resolve()
  }
}
