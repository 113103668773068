const formatAppointmentsForLocationFiltering = appointmentsResponse => {
  return appointmentsResponse.Dates.reduce((formattedDates, date) => {
    let formattedTimes = date.Times.reduce((allTimes, time) => {
      return { ...allTimes, ...{ [time.Time]: [] } }
    }, {})

    if (date.Times && date.Times.length) {
      date.Times.forEach(time => {
        if (!formattedTimes[time.Time].includes(time.DepartmentId)) {
          formattedTimes[time.Time].push(time.DepartmentId)
        }
      })
    }

    return { ...formattedDates, ...{ [date.Date]: formattedTimes } }
  }, {})
}

export { formatAppointmentsForLocationFiltering }
