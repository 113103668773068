import React from 'react'
import './skeleton-filters.scss'

const SkeletonFilters = ({ filterOptions, flow }) => {
  if (!filterOptions) filterOptions = {}
  return <div className='show-filters fake-filters'>
    {
      (flow === 'fad' && filterOptions.showAcceptingNewScheduling) &&
      <div>
        <div className='switch grey-box' />
        <div className='switch grey-box' />
        <hr />
      </div>
    }
    {
      filterOptions.showSpeciality &&
      <span id='specialty-box'>
        <div className='header grey-box' />
        <div className='clearfix' />
        <div className='grey-box checkbox' />
        <div className='grey-box' />
        <div className='clearfix' />
        <div className='grey-box checkbox' />
        <div className='grey-box' />
        <div className='clearfix' />
        <div className='grey-box checkbox' />
        <div className='grey-box' />
        <div className='clearfix' />
        <div className='grey-box checkbox' />
        <div className='grey-box' />
        <div className='clearfix' />
        <div className='grey-box checkbox' />
        <div className='grey-box' />
        <div className='clearfix' />
        <hr />
      </span>
    }

    {
      filterOptions.showDoctorType &&
      <span id='doctor-box'>
        <div className='header grey-box' />
        <hr />
      </span>
    }
    {
      filterOptions.showGenderPreference &&
      <span id='gender-box'>
        <div className='header grey-box' />
        <hr />
      </span>
    }
    {
      filterOptions.showAdditionalLanguages &&
      <span id='language-box'>
        <div className='header grey-box' />
        <hr />
      </span>
    }
    {
      filterOptions.showHospital &&
      <span id='hospital-box'>
        <div className='header grey-box' />
        <hr />
      </span>
    }
    {
      filterOptions.showAffiliation &&
      <div id='aff-box' className='header grey-box' />
    }
  </div>
}

export default SkeletonFilters
