export const DEFAULT_STATE = {
  loggedIn: false,
  schedulingType: 'fad'
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_SCHEDULING_TYPE':
      return Object.assign({}, state, { schedulingType: action.value })
    case 'SET_LOGIN_INFO':
      return Object.assign({}, state, { clientId: action.clientId, serverKey: action.serverKey, loggedIn: action.loggedIn })
    case 'LOGOUT':
      return Object.assign({}, state, { clientId: '', serverKey: '', loggedIn: false })
    case 'DISPLAY_USER_SESSION_TIMEOUT_ERROR':
      return Object.assign({}, state, { sessionExpiredError: true })
    case 'HIDE_USER_SESSION_TIMEOUT_ERROR':
      return Object.assign({}, state, { sessionExpiredError: false })
  }
  return state
}
