import { createSelector } from 'reselect'
import { classesSorter } from '../../../shared/sorters/classes-sorter'

const allItems = state => state.items
export const getFilters = state => state.filters
export const getSort = state => state.sort
export const sortOrder = state => state.sortOrder

// Filter Items
export const getSelectedLocations = state => state.filters.selectedLocations || []
export const getSelectedCategories = state => state.filters.selectedCategories || []
export const getSelectedClassType = state => state.filters.selectedClassType || []
export const getSelectedTags = state => state.filters.selectedTags || []

export const getClassByLocation = createSelector(allItems, getSelectedLocations, (allItems, selectedLocations) => {
  if (selectedLocations && selectedLocations.length) {
    return allItems.filter(item => {
      return selectedLocations.includes(item.Location)
    })
  }
  return allItems
})

export const getClassByCategory = createSelector(allItems, getSelectedCategories, (allItems, selectedCategories) => {
  if (selectedCategories && selectedCategories.length) {
    return allItems.filter(item => {
      return selectedCategories.includes(item.Category)
    })
  }
  return allItems
})

export const getClassByClassType = createSelector(allItems, getSelectedClassType, (allItems, selectedClassType) => {
  if (selectedClassType && selectedClassType.length) {
    return allItems.filter(item => {
      return (item.Tags.includes('Online Class') && selectedClassType.includes('Online Classes')) ||
      (item.IsFree && selectedClassType.includes('Free Classes'))
    })
  }
  return allItems
})

export const getClassByTags = createSelector(allItems, getSelectedTags, (allItems, selectedTags) => {
  if (selectedTags && selectedTags.length) {
    return allItems.filter(item => {
      return item.Tags.filter(tag => {
        return selectedTags.includes(tag)
      })[0]
    })
  }
  return allItems
})

export const getFilteredItems = createSelector(getClassByLocation, getClassByCategory, getClassByClassType, getClassByTags, sortOrder, allItems, (getClassByLocation, getClassByCategory, getClassByClassType, getClassByTags, sortOrder, allClasses) => {
  return classesSorter(allClasses.filter(clas => getClassByLocation.includes(clas) && getClassByCategory.includes(clas) && getClassByClassType.includes(clas) && getClassByTags.includes(clas)), sortOrder)
})

export const getFilterCount = createSelector(getSelectedLocations, getSelectedCategories, getSelectedClassType, getSelectedTags, (selectedLocations, selectedCategories, selectedClassType, selectedTags) => {
  let currentCount = 0
  currentCount += selectedLocations.length
  currentCount += selectedCategories.length
  currentCount += selectedClassType.length
  currentCount += selectedTags.length

  return currentCount
})

export const getFilteredItemCount = createSelector(getFilteredItems, (items) => {
  return items ? items.length : 0
})

export const getClassTypeFromAllClasses = createSelector(allItems, (classes) => {
  let ClassTypeArray = []
  for (let i = 0; i < classes.length; i++) {
    const currentClass = classes[i]
    // Check if free class and then add option
    if (currentClass.IsFree && !ClassTypeArray.includes('Free Classes')) {
      ClassTypeArray.push('Free Classes')
    }
    // Check if online class and then add option
    if (currentClass.Tags.includes('Online Class') && !ClassTypeArray.includes('Online Classes')) {
      ClassTypeArray.push('Online Classes')
    }
  }
  return ClassTypeArray.sort()
})

export const getCategoriesFromAllClasses = createSelector(allItems, (classes) => {
  return extractUniqueClassValues(classes).categories
})

export const getLocationsFromAllClasses = createSelector(allItems, (classes) => {
  return extractUniqueClassValues(classes).locations
})

export const getTagsFromAllClasses = createSelector(allItems, (classes) => {
  return extractUniqueClassValues(classes).tags
})

const extractUniqueClassValues = (allClasses) => {
  let categories = [...new Set(allClasses.map(currentClass => currentClass.Category).flatten())]
  let tags = [...new Set(allClasses.map(currentClass => currentClass.Tags && currentClass.Tags.map(currentTag => currentTag)).flatten())]
  let classNames = [...new Set(allClasses.map(currentClass => currentClass.Name).flatten())]
  let locations = [...new Set(allClasses.map(currentClass => currentClass.Location).flatten())]
  return {
    categories: categories.sort(),
    tags: tags.sort(),
    name: classNames.sort(),
    locations: locations.sort()
  }
}
