import React from 'react'
import './location-card.scss'
import SVGInline from 'react-svg-inline'
import mapMarker from '../../../../shared/assets/directions.svg'
import InfoBadge from '../../../../shared/components/InfoBadge'
import { cleanNumber } from '../../../../shared/utility-functions/phoneHelpers'
import URLS from '../../../../shared/constants/urls.js'
import { Link } from 'react-router-dom'
import { clickToCallEvent } from '../../../../shared/utility-functions/googleTagManager'

export class LocationCard extends React.Component {
  constructor (props) {
    super(props)
    this.locationSchedulingClick = this.locationSchedulingClick.bind(this)
    this.clickToCall = this.clickToCall.bind(this)
    this.loadNoImage = this.loadNoImage.bind(this)
    this.getName = this.getName.bind(this)
    this.getHttpsUrl = this.getHttpsUrl.bind(this)
  }

  clickToCall () {
    const dataLayerObject = {
      flow: this.props.flow,
      providerPhoneNumber: undefined,
      providerName: undefined,
      providerID: undefined,
      providerNPI: undefined,
      providerSpecialty: undefined,
      providerAddress: undefined,
      facilityName: this.props.location.Name,
      facilityAddress: this.props.location.Address + ', ' + this.props.location.City + ' ' + this.props.location.State + ' ' + this.props.location.ZipCode
    }

    clickToCallEvent(dataLayerObject)
  }

  locationSchedulingClick () {
    window.sessionStorage.setItem('sas-location', JSON.stringify(this.props.location))
    window.sessionStorage.setItem('time-frame', JSON.stringify(this.props.selectedTimeSlots))
    window.sessionStorage.setItem('filter-date', JSON.stringify(this.props.selectedDate))
    window.sessionStorage.setItem('search-path', window.location.href)
    window.location.href = URLS.mammSchedulingUrl
  }

  loadNoImage (event) {
    event.srcElement.src = URLS.defaultLocationImage
  }

  getName () {
    if (this.props.location.SearchNameOverride && this.props.location.SearchNameOverride.length > 0) {
      return this.props.location.SearchNameOverride
    }
    return this.props.location.Name
  }

  getHttpsUrl (url) {
    return url.replace(/^http:\/\//i, 'https://')
  }

  render () {
    return (
      <div className='provider-card-container location-card' id={`provider-card-${this.props.location.Id}`}>
        {
          this.props.schedulingFlow
            ? <Link to={`/choose-visit-type/` + this.props.location.Id} className='location-image-link'>
              <img alt={this.props.location.Name} className={this.props.location.PhotoURL ? 'doc-img' : 'doc-img default-photo'} src={this.props.location.PhotoURL ? this.getHttpsUrl(this.props.location.PhotoURL) : this.getHttpsUrl(URLS.defaultLocationImage)} onError={this.loadNoImage} /></Link>
            : this.props.location.Url != null
              ? <a href={this.getHttpsUrl(this.props.location.Url)} className='location-image-link' target='_self'>
                <img alt={this.props.location.Name} className={this.props.location.PhotoURL ? 'doc-img' : 'doc-img default-photo'} src={this.props.location.PhotoURL ? this.getHttpsUrl(this.props.location.PhotoURL) : this.getHttpsUrl(URLS.defaultLocationImage)} onError={this.loadNoImage} /></a>
              : <img alt={this.props.location.Name} className={this.props.location.PhotoURL ? 'doc-img' : 'doc-img default-photo'} src={this.props.location.PhotoURL ? this.getHttpsUrl(this.props.location.PhotoURL) : this.getHttpsUrl(URLS.defaultLocationImage)} onError={this.loadNoImage} />
        }
        <div className='badge-name-ratings-container extra-padding'>
          {(this.props.location.OpenSat || this.props.location.OpenSun) && <InfoBadge className='doctor-card-badge' text='Offers Weekend Hours' />}
          <div className='mam-location-title'>
            {
              this.props.schedulingFlow
                ? <Link to={`/choose-visit-type/` + this.props.location.Id} className='office-name-link'>{this.getName()}</Link>
                : this.props.location.Url != null
                  ? <a href={this.getHttpsUrl(this.props.location.Url)} className='office-name-link' target='_self'>{this.getName()}</a>
                  : this.getName()
            }
          </div>
        </div>
        <div className='address-container'>
          {this.props.location.Hours != null && this.props.showHours && <p className='location-hours'>{this.props.location.Hours}</p>}
          {
            this.props.location.Distance != null && this.props.location.Distance > 0 &&
            <div className='distance-container'>
              <SVGInline className='map-marker' svg={mapMarker} /><span className='distance'>{this.props.location.Distance} miles</span>
            </div>
          }
          <div>{this.props.location.Address}</div>
          <div>{this.props.location.City}, {this.props.location.State} {this.props.location.ZipCode}</div>
        </div>
        <div className='card-footer'>
          <div className='footer-container'>
            <div className='phone-number'>
              {this.props.location && this.props.location.Phone != null && this.props.location.Phone !== '' && <a href={`tel:${cleanNumber(this.props.location.Phone)}`}><span onClick={this.clickToCall}>{this.props.location.Phone}</span></a>}
            </div>
            {
              this.props.location.InAppScheduling &&
              <button type='button' className='schedule-link' onClick={this.locationSchedulingClick}>
                <span className='schedule-content'>SCHEDULE</span>
              </button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default LocationCard
