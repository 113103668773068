import { race, put, select, takeLatest, delay } from 'redux-saga/effects'
import moment from 'moment'
import { fetchAPIData } from '../../shared/services/fetchData'
import { AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT } from '../../shared/constants/api-helpers'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { getVisitTypeId } from '../selectors/search-input-selectors'
import { formatAppointmentsForLocationFiltering } from '../../shared/utility-functions/appointmentTransformer'
import { getSelectedDate } from '../selectors/location-search-filter-selectors'

export default function * watchForFetchLocationFilteringAppointments () {
  yield takeLatest('FETCH_LOCATION_FILTER_APPOINTMENTS', fetchLocationFilteringAppointments)
}

export function * fetchLocationFilteringAppointments () {
  yield put({ type: 'LOCATION_FILTER_ERROR', value: false })
  yield put({ type: 'LOCATION_FILTER_LOADING', value: true })
  const visitTypeId = yield select(getVisitTypeId)
  const selectedDate = yield select(getSelectedDate)
  const dateString = moment(selectedDate).format('YYYY-MM-DD')

  const options = { visitTypeId, startDate: dateString, endDate: dateString, endPoint: AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT }

  /* eslint-disable no-unused-vars */
  const {posts, timeout} = yield race({
    posts: fetchAPIData(options),
    timeout: delay(LONG_TIMEOUT)
  })

  if (posts && posts.Success) {
    yield put({ type: 'ADD_LOCATION_FILTER_APPOINTMENTS', value: formatAppointmentsForLocationFiltering(posts) })
  } else {
    yield put({ type: 'LOCATION_FILTER_ERROR', value: true })
  }
  yield put({ type: 'LOCATION_FILTER_LOADING', value: false })
}
