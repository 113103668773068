const DEFAULT_SORT_ORDER = 'First Available'

export const DEFAULT_STATE = {
  providers: [],
  searched: false,
  dataLoaded: false,
  callFailed: false,
  showAffiliatedDoctors: false,
  isOPG: false,
  sortOrder: DEFAULT_SORT_ORDER,
  overrideAPP: false,
  filters: {
    selectedAvailability: [],
    selectedRating: [],
    selectedSpecialty: [],
    selectedDoctorType: [],
    selectedGender: [],
    selectedLanguage: [],
    selectedHospital: [],
    selectedAffiliation: []
  },
  showFirstAvailModal: false,
  clearAll: false,
  currentPage: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_PROVIDERS':
      return { ...state, ...{ providers: action.value, dataLoaded: true } }
    case 'CLEAR_PROVIDERS':
      return { ...state, ...{ providers: DEFAULT_STATE.providers, dataLoaded: false } }
    case 'SEARCH_PROVIDERS':
      return { ...state, ...{ searched: true } }
    case 'SET_PROVIDERS_CALL_FAILED':
      return { ...state, ...{ callFailed: action.value } }
    case 'RESET_SCHEDULE_A_DOCTOR_FILTERS':
      return { ...state,
        ...{ filters: {
          selectedAvailability: [],
          selectedRating: [],
          selectedSpecialty: [],
          selectedDoctorType: [],
          selectedGender: [],
          selectedLanguage: [],
          selectedHospital: [],
          selectedAffiliation: []
        } } }
    case 'SET_OPG':
      return { ...state, ...{ isOPG: action.value } }
    case 'SET_FILTERS':
      return buildFiltersFromParamsObject(state, action.params)
    case 'SET_SELECTED_AVAILABILITY':
      return { ...state, ...{ filters: { ...state.filters, selectedAvailability: action.value } } }
    case 'SET_SELECTED_RATING':
      return { ...state, ...{ filters: { ...state.filters, selectedRating: action.value } } }
    case 'TURN_ON_ONLINE_SCHEDULING':
      return { ...state, ...{ overrideScheduling: true, filters: { ...state.filters, selectedAvailability: setAvailability(state.filters, 'add') } } }
    case 'TURN_OFF_ONLINE_SCHEDULING':
      return { ...state, ...{ overrideScheduling: false, filters: { ...state.filters, selectedAvailability: setAvailability(state.filters, 'remove') } } }
    case 'SET_SELECTED_SPECIALTY':
      return { ...state, ...{ filters: { ...state.filters, selectedSpecialty: action.value } } }
    case 'SET_SELECTED_DOCTOR_TYPE':
      return { ...state, ...{ filters: { ...state.filters, selectedDoctorType: action.value } } }
    case 'SET_SELECTED_GENDER':
      return { ...state, ...{ filters: { ...state.filters, selectedGender: action.value } } }
    case 'SET_SELECTED_LANGUAGE':
      return { ...state, ...{ filters: { ...state.filters, selectedLanguage: action.value } } }
    case 'SET_SELECTED_HOSPITAL':
      return { ...state, ...{ filters: { ...state.filters, selectedHospital: action.value } } }
    case 'SET_SELECTED_AFFILIATION':
      return { ...state, ...{ filters: { ...state.filters, selectedAffiliation: action.value } } }
    case 'SET_DOCTOR_SEARCH_SORT_ORDER':
      return { ...state, ...{ sortOrder: checkSort(state, action.value) } }
    case 'RESET_SCHEDULE_A_DOCTOR_SORT_ORDER':
      return { ...state, ...{ sortOrder: checkSort(state, DEFAULT_SORT_ORDER) } }
    case 'SET_DOCTOR_CURRENT_PAGE':
      return { ...state, ...{ currentPage: action.number } }
    case 'SET_AUTOCOMPLETE_DATA':
      return { ...state, ...{ autoCompleteData: action.value, autoCompleteDoctors: action.value.names } }
    case 'TOGGLE_AFFILIATED_DOCTORS':
      return { ...state, ...{ showAffiliatedDoctors: !state.showAffiliatedDoctors } }
    case 'RESET_AFFILIATED_DOCTORS':
      return { ...state, ...{ showAffiliatedDoctors: DEFAULT_STATE.showAffiliatedDoctors } }
    case 'DISPLAY_FIRST_PCP_MODAL':
      return { ...state, ...{ showFirstAvailModal: action.value, clearAll: action.clearAll } }
    case 'RESET_CLEAR_ALL':
      return { ...state, ...{ clearAll: false } }
    case 'APP_OVERRIDE':
      return { ...state, ...{ overrideAPP: true } }
  }
  return state
}

const setAvailability = (filters, key) => {
  let newFilters = []
  if (key === 'add') {
    newFilters.push('Doctors with Online Scheduling')
  }
  let found = filters && filters.length ? filters.filter(f => f === 'Doctors Accepting New Patients')[0] : null
  if (found) {
    newFilters.push('Doctors Accepting New Patients')
  }
  return newFilters
}

const checkSort = (state, newSort) => {
  if (state.sortOrder !== 'Random') {
    return newSort
  } else {
    return 'Random'
  }
}

const buildFiltersFromParamsObject = (state, params) => {
  let newFilters = {
    selectedAvailability: [],
    selectedRating: [],
    selectedSpecialty: [],
    selectedDoctorType: [],
    selectedGender: [],
    selectedLanguage: [],
    selectedHospital: [],
    selectedAffiliation: []
  }
  if (params.selectedAvailability && params.selectedAvailability.length) newFilters.selectedAvailability = params.selectedAvailability
  if (params.selectedRating && params.selectedRating.length) newFilters.selectedRating = params.selectedRating
  if (params.selectedSpecialty && params.selectedSpecialty.length) newFilters.selectedSpecialty = params.selectedSpecialty
  if (params.selectedDoctorType && params.selectedDoctorType.length) newFilters.selectedDoctorType = params.selectedDoctorType
  if (params.selectedGender && params.selectedGender.length) newFilters.selectedGender = params.selectedGender
  if (params.selectedLanguage && params.selectedLanguage.length) newFilters.selectedLanguage = params.selectedLanguage
  if (params.selectedHospital && params.selectedHospital.length) newFilters.selectedHospital = params.selectedHospital
  if (params.selectedAffiliation && params.selectedAffiliation.length) newFilters.selectedAffiliation = params.selectedAffiliation
  return { ...state, ...{ filters: newFilters } }
}
