const linkList = [
  'Primary Care',
  'Family Medicine',
  'Cardiologist',
  'Orthopedics',
  'Internal Medicine',
  'Heart and Vascular',
  'Obstetrics/Gynecology',
  'Neurologist',
  'Gynecology',
  'Cancer'
]

const locationList = [
  'Urgent Care',
  'Emergency Departments',
  'Primary Care',
  'Orthopedics',
  'Heart and Vascular',
  'Neurology',
  'Gynecology',
  'Cancer',
  'Health and Fitness',
  'Imaging and Radiology',
  'Laboratory Services'
]

const serviceList = [
  'Urgent Care',
  'Emergency and Trauma',
  'Primary Care',
  'Orthopedics',
  'Heart and Vascular',
  'Neuroscience',
  'Mammography',
  'Cancer',
  'Diabetes',
  'Imaging and Radiology',
  'Laboratory Services'
]

export default linkList
export { locationList, serviceList }
