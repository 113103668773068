import React from 'react'
import SVGInline from 'react-svg-inline'
import mapmarker from '../../../shared/assets/directions.svg'
import './side-bar.scss'
import { cleanNumber, scrollToLocation, formatPhoneNumber } from '../../../shared/utility-functions'
import WaitTimes from '../../../shared/components/WaitTimes/WaitTimes.jsx'
import { Link } from 'react-router-dom'
import FirstAvailable from '../../../shared/components/FirstAvailable/FirstAvailable.jsx'
import { clickToCallEvent } from '../../../shared/utility-functions/googleTagManager'
import { clickToCallTransformer } from '../../../shared/data-mappers/click-to-call-transformer'

export default class SideBar extends React.Component {
  constructor (props) {
    super(props)
    this.markersLength = this.props.results ? this.props.results.length : 0
    this.state = {
      selectedCard: -1
    }
    this.clickToCall = this.clickToCall.bind(this)
  }

  clickToCall(e, locationInfo) {
    e.stopPropagation()

    const facilityName = locationInfo.SearchNameOverride ? locationInfo.SearchNameOverride : locationInfo.SearchName
    const facilityAddress = {
        Address: locationInfo.Address,
        City: locationInfo.City,
        State: locationInfo.State,
        ZipCode: locationInfo.ZipCode
    }
    const facilityPhoneNumber = formatPhoneNumber(locationInfo.Phone)

    clickToCallEvent(clickToCallTransformer(this.props.flow, null, facilityName, facilityAddress, facilityPhoneNumber))
  }

  cards () {
    return this.props.results.sort((card, previousCard) => {
      if (this.props.selectedLocation != null && window.screen.width < 768) {
        return card.Id === this.props.selectedLocation.Id ? -1 : previousCard.Id === this.props.selectedLocation.Id ? 1 : 0
      }
    }).map((card, index) => {
      return <div onClick={() => { this.props.selectionHandler(card); scrollToLocation('.find-a-doctor-page') }} key={index} className={`micro-card ${this.props.selectedLocation && card.Id === this.props.selectedLocation.Id ? 'selected' : ''}`} id={`locationId-${index}`}>
        {
          this.props.schedulingFlow
            ? <Link to={`/choose-visit-type/` + card.Id}><h3>{card.SearchNameOverride ? card.SearchNameOverride : card.SearchName}</h3></Link>
            : <a href={card.Url} target='_self' onClick={(e) => e.stopPropagation()}><h3>{card.SearchNameOverride ? card.SearchNameOverride : card.SearchName}</h3></a>
        }
        {card.Distance && <p className='distance'>{card.Distance} miles away</p>}
        {
          card.Address &&
          <div>
            <a className='location-address-link' onClick={(e) => e.stopPropagation()} href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(card.City + ', ' + card.State + ' ' + card.ZipCode + ' ' + card.Address)}`} target='_blank'>
              {card.Address} <br />{card.City}, {card.State} {card.ZipCode}
            </a>
          </div>
        }
          <br />
          {card.Phone && <a className='card-phone-number' onClick={(e) => this.clickToCall(e, card)} href={`tel:${cleanNumber(card.Phone)}`}>{formatPhoneNumber(card.Phone)}</a>}
        {
          ((card.LocationTypes && card.LocationTypes.includes(62)) || this.props.schedulingFlow ) && <FirstAvailable departmentID={this.props.schedulingFlow ? card.DepartmentId : card.DepartmentID} resourceID={this.props.schedulingFlow ? card.ResourceId : card.ResourceID} />
        }
        {
          card.WaitTimeID && <WaitTimes waitTimeId={card.WaitTimeID} />
        }
        <span className='map-marker-icon'>
          <SVGInline svg={mapmarker} />
        </span>
      </div>
    })
  }

  render () {
    return <div className='micro-results-list'>
      {!this.markersLength && <p className='selectMsg text-center'>No locations found for your search</p>}
      <div className='sidebar-card-results-container'>
        {this.props.results && this.props.results.length > 0 && this.cards()}
      </div>
    </div>
  }
}
