const serviceTransformer = (services) => {
  return services.map(service => {
    return {
      Name: service.name,
      Url: service.url,
      Description: service.desc,
      Id: service.id
    }
  })
}

export default serviceTransformer
