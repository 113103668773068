import React from 'react'
import Chip from '../../../shared/components/Chip'
// import SVGInline from 'react-svg-inline'
// import noticeIcon from '../../../shared/assets/notice.svg'
import './reason-for-visit-modal.scss'
import { OBGYNReasonsForVisit } from '../../../shared/constants/messages'
import { clickToCallEvent } from '../../../shared/utility-functions/googleTagManager'

const ReasonForVisitModal = ({ addReason, removeReason, phone, numberOfReasons, submissionHandler, doctor }) => {
  const clickToCall = () => {
    const dataLayerObject = {
      flow: 'OBGYN',
      providerPhoneNumber: doctor.phone,
      providerName: doctor.title,
      providerID: doctor.id,
      providerNPI: doctor.npi,
      providerSpecialty: doctor.specialties ? doctor.specialties.join(', ') : undefined,
      providerAddress: doctor.address ? doctor.address.adr + ', ' + doctor.address.c + ' ' + doctor.address.s + ' ' + doctor.address.z : undefined,
      facilityName: undefined,
      facilityAddress: undefined
    }

    clickToCallEvent(dataLayerObject)
  }

  return (
    <div className='reason-for-visit-modal'>
      <div className='title'>Reason For Visit</div>
      <p className='explanation'>Select all that apply.</p>{}
      <div className='reasons'>
        <Chip text={OBGYNReasonsForVisit.ABNORMAL_BLEEDING} selectHandler={addReason} deselectHandler={removeReason} />
        <Chip text={OBGYNReasonsForVisit.ANNUAL_VISIT} selectHandler={addReason} deselectHandler={removeReason} />
        <Chip text={OBGYNReasonsForVisit.BIRTH_CONTROL} selectHandler={addReason} deselectHandler={removeReason} />
        <Chip text={OBGYNReasonsForVisit.BREAST_PAIN} selectHandler={addReason} deselectHandler={removeReason} />
        <Chip text={OBGYNReasonsForVisit.MENOPAUSE} selectHandler={addReason} deselectHandler={removeReason} />
        <Chip text={OBGYNReasonsForVisit.PELVIC_PAIN} selectHandler={addReason} deselectHandler={removeReason} />
        {/* <Chip text={OBGYNReasonsForVisit.PREGNANT} selectHandler={addReason} deselectHandler={removeReason} /> */}
        <Chip text={OBGYNReasonsForVisit.STD} selectHandler={addReason} deselectHandler={removeReason} />
        <Chip text={OBGYNReasonsForVisit.VAGINAL_ISSUES} selectHandler={addReason} deselectHandler={removeReason} />
      </div>
      <div className='warning'>
          Don't see your reason listed or have additional questions?
        {
          phone
            ? <span> Please call us at <a className='reason-for-visit-phone' href={`tel:${phone}`}><span onClick={() => clickToCall()}>{phone}</span></a></span>
            : <span> Please call the office</span>
        }
      </div>
      <button className='btn btn-primary continue-button' onClick={() => submissionHandler({ reasonComplete: true })} disabled={numberOfReasons < 1}>Continue</button>
    </div>
  )
}

export default ReasonForVisitModal
