import { connect } from 'react-redux'
import FADMap from './FADMap.jsx'
import { getFilterLoadingState, getDataLoaded, getCount } from '../../selectors/search-filter-selectors.js'
import { getSchedulingType } from '../../../shared/selectors/shared-scheduling-selectors'
import { insertBrowserHistory } from '../../state/history'
import { getURLParameters, insertEmptyFilters } from '../../../shared/utility-functions'

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    searchQuery: state.searchInput.searchQuery,
    searchLocation: state.searchInput.searchLocation,
    fadSearchedBounds: (state.fadMap && state.fadMap.searchedBounds),
    fadSearchedBoundsObj: (state.fadMap && state.fadMap.searchedBoundsObj) ? state.fadMap.searchedBoundsObj : null,
    autoSearchedLocation: (state.fadMap && state.fadMap.autoSearchedLocation) ? state.fadMap.autoSearchedLocation : null,
    searchOptions: ownProps.searchOptions,
    filtersLoading: getFilterLoadingState(state),
    lookingFor: getURLParameters().search.q ? getURLParameters().search.q : state.searchInput.searchQuery,
    address: getURLParameters().search.locq ? getURLParameters().search.locq : state.searchInput.searchLocation.address,
    latitude: getURLParameters().search.lat ? Number(getURLParameters().search.lat) : state.searchInput.searchLocation.latitude,
    longitude: getURLParameters().search.lng ? Number(getURLParameters().search.lng) : state.searchInput.searchLocation.longitude,
    within: getURLParameters().search.dist ? Number(getURLParameters().search.dist) : state.searchInput.searchLocation.within,
    numberOfSearchItems: getCount(state),
    dataLoaded: getDataLoaded(state),
    showAffiliatedDoctors: state.doctorSearch.showAffiliatedDoctors,
    flow: getSchedulingType(state),
    sort: state.doctorSearch.sortOrder,
    orgID: ownProps.orgID,
    addressKeys: ownProps.addressKeys,
    itemIds: ownProps.itemIds,
    app: ownProps.app,
    visitTypeId: state.searchInput.visitTypeId
})

const mapDispatchToProps = (dispatch) => ({
    dispatchSearch: (params, isBlock, noLoad, bounds) => dispatch({
        type: 'GET_FAD_PROVIDERS',
        params: params,
        isBlock: isBlock,
        noLoad: noLoad,
        bounds: bounds
    }),
    resetFilters: () => dispatch({
        type: 'RESET_SCHEDULE_A_DOCTOR_FILTERS'
    }),
    setSort: (sortOrder) => dispatch({
        type: 'SET_DOCTOR_SEARCH_SORT_ORDER',
        value: sortOrder
    }),
})

const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    runSearch: (params, bounds) => {
        params.org = stateProps.orgID
        params.app = stateProps.app
        params.addKey = stateProps.addressKeys
        params.ids = stateProps.itemIds
        if (stateProps.flow === 'fad') {
            if (stateProps.sort === 'Distance') dispatchProps.setSort('A TO Z')
            let noLoad = true
            dispatchProps.dispatchSearch(params, stateProps.searchOptions.isBlock, noLoad, bounds)
        }
    }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FADMap)
