const DEFAULT_SORT_ORDER = 'A TO Z'

export const DEFAULT_STATE = {
  services: [],
  searched: false,
  dataLoaded: false,
  callFailed: false,
  sortOrder: DEFAULT_SORT_ORDER,
  currentPage: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_SERVICES':
      return { ...state, ...{ services: action.value, dataLoaded: true } }
    case 'CLEAR_SERVICES':
      return { ...state, ...{ services: DEFAULT_STATE.services, dataLoaded: false } }
    case 'SEARCH_SERVICES':
      return { ...state, ...{ searched: true } }
    case 'SET_SERVICES_CALL_FAILED':
      return { ...state, ...{ callFailed: action.value } }
    case 'SET_SERVICE_SORT_ORDER':
      return { ...state, ...{ sortOrder: action.value } }
    case 'RESET_SCHEDULE_A_SERVICE_SORT_ORDER':
      return { ...state, ...{ sortOrder: DEFAULT_SORT_ORDER } }
    case 'SET_SERVICE_CURRENT_PAGE':
      return { ...state, ...{ currentPage: action.number } }
    case 'SET_SERVICE_AUTOCOMPLETE_DATA':
      return { ...state, ...{ autoCompleteDataServices: action.value } }
  }
  return state
}
