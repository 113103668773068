import { connect } from 'react-redux'
import LocationCard from './LocationCard.jsx'
import { getSelectedDate, getSelectedTimeSlots } from '../../../selectors/location-search-filter-selectors'

const mapStateToProps = (state) => ({
  selectedDate: getSelectedDate(state),
  selectedTimeSlots: getSelectedTimeSlots(state),
  flow: state.persistentData.schedulingType
})

export default connect(mapStateToProps)(LocationCard)
