import React from 'react'
import SVGInline from 'react-svg-inline'
import SelectedIcon from '../../../../../shared/assets/radio-selected.svg'
import UnselectedIcon from '../../../../../shared/assets/radio-unselected.svg'
import './custom-dropdown.scss'

export default class ReviewsDrawer extends React.Component {
// const CustomDropdown = ({ title, options, currentValue, showPrompt = false, selectOptionHandler, disabled = false, promptText = 'PLEASE SELECT', promptDisabled = false, promptHidden = false, customClass = '', inputId = undefined }) => {
  constructor (props) {
    super(props)
    this.state = {
      showDropdown: false
    }
  }

  render () {
    const { title, options, currentValue, currentOption, selectOptionHandler } = this.props
    const isSelected = (opt) => {
      if (opt.value === currentValue) {
        return true
      }
      return false
    }
    const renderOption = (opt, index) => {
      return <a className='dropdown-option' onClick={() => { this.setState({ showDropdown: false }); selectOptionHandler(opt) }} value={opt.value} key={index}>
        <span>{opt.label}</span>{isSelected(opt) ? <SVGInline className='selected' svg={SelectedIcon} /> : <SVGInline className='unselected' svg={UnselectedIcon} />}
      </a>
    }

    return (
      <div className='dropdown'>
        <div className='dropdown-label'>{title}</div>
        <div className='dropdown-select' onClick={() => { this.setState((prevState) => ({ showDropdown: !prevState.showDropdown })) }} ><span>{currentOption.label}</span></div>
        {(this.state.showDropdown)
          ? <div className={`dropdown-content ${currentValue && currentValue.length ? currentValue.replace(/\s/g, '-').toLowerCase() : ''}`} aria-label='dropdown'>
            {options && options.length && options.map((opt, index) => renderOption(opt, index))}
          </div>
          : null
        }
      </div>)
    /* <div className={`dropdown ${customClass}`}>
      <div className='dropdown-label'>{title}</div>
      <select className={`dropdown-select ${currentValue && currentValue.length ? currentValue.replace(/\s/g, '-').toLowerCase() : ''}`} value={currentValue} onChange={(event) => changeClick(event, selectOptionHandler)} disabled={disabled} aria-label='dropdown' id={inputId}>
        {
          showPrompt && <option className='default-select' value='' disabled={promptDisabled} hidden={promptHidden}>{promptText}</option>
        }
        {options && options.length && options.map(renderOption)}
      </select>
    </div>/** */
  }
}
// export default CustomDropdown
