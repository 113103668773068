import { race, put, take, delay } from 'redux-saga/effects'
import fetchData from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { LOCATION_AUTOCOMPLETE_ENDPOINT } from '../../shared/constants/api-helpers.js'

export default function * autocompleteLocationSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_LOCATIONS_AUTOCOMPLETE_DATA')
    let params = {}
    params.ids = action.ids ? action.ids : ''
    params.endPoint = LOCATION_AUTOCOMPLETE_ENDPOINT

    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts) {
      yield put({ type: 'SET_LOCATION_AUTOCOMPLETE_DATA', value: posts })
    }
  }
}
