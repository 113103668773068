export const DEFAULT_STATE = {
  items: [],
  searched: false,
  dataLoaded: false,
  callFailed: false,
  filters: {
    selectedClassType: [],
    selectedCategories: [],
    selectedLocations: [],
    selectedTags: []
  },
  sort: 'A TO Z',
  currentPage: 1,
  autoCompleteDataClasses: []
}

export const DEFAULT_FILTERS = {
  selectedClassType: [],
  selectedCategories: [],
  selectedLocations: [],
  selectedTags: []
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_ITEMS':
      return { ...state, ...{ items: action.value, dataLoaded: true } }
    case 'CLEAR_ITEMS':
      return { ...state, ...{ items: DEFAULT_STATE.items, dataLoaded: false } }
    case 'RESET_FILTERS':
      return { ...state, ...{ filters: DEFAULT_STATE.filters } }
    case 'SEARCH_CLASSES':
      return { ...state, ...{ searched: true } }
    case 'SET_CALL_FAILED':
      return { ...state, ...{ callFailed: action.value } }
    case 'SET_SORT':
      return { ...state, ...{ sort: action.value } }
    case 'SET_CURRENT_PAGE':
      return { ...state, ...{ currentPage: action.number } }
    case 'SET_CLASSES_AUTOCOMPLETE_DATA':
      return { ...state, ...{ autoCompleteDataClasses: action.value } }
    case 'SET_SELECTED_CLASS_LOCATION':
      return { ...state, ...{ filters: { ...state.filters, selectedLocations: action.value } } }
    case 'SET_SELECTED_CLASS_CATEGORY':
      return { ...state, ...{ filters: { ...state.filters, selectedCategories: action.value } } }
    case 'SET_SELECTED_CLASS_TYPE':
      return { ...state, ...{ filters: { ...state.filters, selectedClassType: action.value } } }
    case 'SET_SELECTED_CLASS_TAG':
      return { ...state, ...{ filters: { ...state.filters, selectedTags: action.value } } }
    case 'SET_FILTERS':
      return buildFiltersFromParamsObject(state, action.value)
  }
  return state
}

export const buildFiltersFromParamsObject = (state, params) => {
  let newFilters = {
    selectedClassType: [],
    selectedCategories: [],
    selectedLocations: [],
    selectedTags: []
  }
  if (params.selectedClassType && params.selectedClassType.length) newFilters.selectedClassType = params.selectedClassType
  if (params.selectedCategories && params.selectedCategories.length) newFilters.selectedCategories = params.selectedCategories
  if (params.selectedLocations && params.selectedLocations.length) newFilters.selectedLocations = params.selectedLocations
  if (params.selectedTags && params.selectedTags.length) newFilters.selectedTags = params.selectedTags
  return { ...state, ...{ filters: newFilters } }
}
