import { race, put, take, delay } from 'redux-saga/effects'
import { fetchProviderComments } from '../services/ReviewService'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import doctorTransform from '../../shared/data-mappers/doctor-details-transformer.js'

export default function * fetchPhysicianCommentsSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('FETCH_PROVIDER_COMMENTS')

    yield put({ type: 'LOADING_COMMENTS_FAILED', value: false })

    if ((window.currentDoctor && window.currentDoctor.npi) || action.providerNPI) {
      let physicianNPI = null
      if (action.providerNPI) {
        physicianNPI = action.providerNPI
      } else if (window.currentDoctor && window.currentDoctor.npi) {
        const physician = doctorTransform(window.currentDoctor)
        physicianNPI = physician.npi
      }
      yield put({ type: 'LOADING_PROVIDER_COMMENTS', value: true })

      const { posts } = yield race({
        posts: fetchProviderComments(physicianNPI),
        timeout: delay(waitTime)
      })

      if (posts && posts.Success) {
        yield put({ type: 'SET_PROVIDER_COMMENTS', value: { comments: posts.Comments, overallRating: posts.OverallRating, ratingCategories: posts.RatingCategories } })
      } else {
        yield put({ type: 'LOADING_COMMENTS_FAILED', value: true })
      }
      yield put({ type: 'LOADING_PROVIDER_COMMENTS', value: false })
    }
  }
}
