import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import rootReducer from '../state/root-reducer'
import FindAServiceConnector from './FindAServiceConnector.jsx'
import serviceSearchSaga from '../sagas/service-search-saga'
import fetchServiceAutocompleteDataSaga from '../sagas/fetch-services-autocomplete-data-saga'

export default class FALRoot extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.sasStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
    sagaMiddleware.run(serviceSearchSaga)
    sagaMiddleware.run(fetchServiceAutocompleteDataSaga)
  }

  render () {
    return (
      <Provider store={this.sasStore}>
        <div>
          <FindAServiceConnector displayType={this.props.displayType} searchOptions={this.props.searchOptions} headline={this.props.headline} filterOptions={this.props.filterOptions} />
        </div>
      </Provider>
    )
  }
}
