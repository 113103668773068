import React from 'react'
import './wait-times.scss'

export class WaitTimes extends React.Component {
  constructor (props) {
    super(props)
    let getWaitTimes = require('../../utility-functions/waitTimes.js').default
    this.state = {
      waitTimes: getWaitTimes()
    }
    this.currentWaitTime = this.currentWaitTime.bind(this)
    this.convertMinutesToHoursAndMinutes = this.convertMinutesToHoursAndMinutes.bind(this)
    this.focus = this.focus.bind(this)
    window.addEventListener('waitTimesUpdated', (e) => {
      this.setState({ waitTimes: getWaitTimes() })
    }, {passive: true})
  }

  convertMinutesToHoursAndMinutes (minutes) {
    if (typeof minutes !== 'number' || minutes < 0) {
      return null
    }

    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    let result = ''

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''}`
    }

    if (remainingMinutes > 0) {
      if (result.length > 0) {
        result += ' and '
      }
      result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`
    }

    if (result.length === 0) {
      result = '0 minutes'
    }

    return result
  }

  currentWaitTime () {
    const result = this.state.waitTimes.find(time => time.id === Number(this.props.waitTimeId))
    const minutes = result ? result.waitTime : null

    return this.convertMinutesToHoursAndMinutes(minutes)
  }

  focus () {
    if (document.querySelector('.circle') != null) {
      document.querySelector('.circle').focus()
    }
  }

  render () {
    const currentWaitTime = this.currentWaitTime()

    return (
      <React.Fragment>
        {currentWaitTime ? (
          <h4 className={this.props.contactCard ? `card-wait-times contact-card-wait-times` : `card-wait-times`}>
            <span className='message'>Approx Walk-In Wait Time:</span>{' '}
            <span className='time'>{currentWaitTime}</span>
          </h4>) : null}
      </React.Fragment>
    )
  }
}

export default WaitTimes
