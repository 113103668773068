import SuperResultsTimes from './SuperResultsTimes.jsx'
import { PreviousPatientConnector, PreviousPatientCardioConnector } from '../../../../../shared/components/PreviousPatient'
import { BeforeYouScheduleOBGYNConnector } from '../../../../../shared/components/SchedulingModal/OBGYN'
import { connect } from 'react-redux'
import { isOBGYN } from '../../../../../shared/utility-functions/isOBGYN.js'

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isOBS: window.sessionStorage.getItem('isOBS') ? true : false,
  flow: ownProps.provider && ownProps.provider.specialtyIds && isOBGYN(ownProps.provider.specialtyIds) ? 'OBGYN' : state.persistentData.schedulingType,
  renderTimeSlots: window.sessionStorage.getItem("isOBS") && isOBGYN(ownProps.provider.specialtyIds) ? false : true,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedLocation: (location) => {
    dispatch({ type: 'SET_SELECTED_LOCATION', value: location })
  },
  setTime: (setAppointmentAction) => {
    dispatch(setAppointmentAction)
  },
  scheduleLinkClick: (url) => {
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: PreviousPatientConnector })
  },
  cardioScheduleLinkClick: (url) => {
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: PreviousPatientCardioConnector })
  },
  obgynScheduleLinkClick: (url, phone, provider) => {
    dispatch({ type: 'SET_DOCTOR', value: provider })
    dispatch({ type: 'SET_DOCTOR_PHONE', value: phone })
    dispatch({ type: 'SET_DOCTOR_URL', value: url })
    dispatch({ type: 'SHOW_SCHEDULING_MODAL' })
    dispatch({ type: 'SHOW_CONTENT', value: BeforeYouScheduleOBGYNConnector })
  }
})

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SuperResultsTimes)
