import React from 'react'
import QuickLink from './QuickLink/QuickLink.jsx'
import SVGInline from 'react-svg-inline'
import LookingForConnector from '../../Search/components/SearchBar/LookingFor/LookingForConnector.jsx'
import LocationInput from '../../Search/components/SearchBar/LocationInput/LocationInput.jsx'
import LocationWithin from '../../Search/components/SearchBar/LocationWithin/LocationWithin.jsx'
import StethoscopeIcon from '../../shared/assets/stethoscope-new.svg'
import MyChartIcon from '../../shared/assets/mychart.svg'
import CalendarIcon from '../../shared/assets/calendar.svg'
import CareersIcon from '../../shared/assets/careers.svg'
import WalletIcon from '../../shared/assets/wallet.svg'
import {getParameterByName} from '../../shared/utility-functions/searchParams'
import './fad-home.scss'

export default class FADHome extends React.Component {
  constructor (props) {
    super(props)
    this.searchCalls = 0
    this.props.initializePage()

    this.setLookingFor = this.setLookingFor.bind(this)
    this.setLocation = this.setLocation.bind(this)
    this.setWithin = this.setWithin.bind(this)
    this.setAcceptingNewPatients = this.setAcceptingNewPatients.bind(this)
    this.enterSubmit = this.enterSubmit.bind(this)

    this.state = {
      lookingFor: '',
      address: '',
      latitude: '',
      longitude: '',
      within: '25',
      acceptingNewPatients: ''
    }
  }

  componentDidMount () {
    this.setState({
      lookingFor: getParameterByName('q') || '',
      address: getParameterByName('locq') || '',
      latitude: getParameterByName('lat') || '',
      longitude: getParameterByName('lng') || '',
      distance: getParameterByName('dist') || '25',
      acceptingNewPatients: ''
    })
  }

  setAcceptingNewPatients (e) {
    if (e.target.checked) {
      this.setState({ acceptingNewPatients: 'anp' })
    } else {
      this.setState({ acceptingNewPatients: '' })
    }
  }

  setLookingFor (lookingFor) {
    this.setState({ lookingFor: lookingFor })
  }

  setLocation (value, lat, lng) {
    if (!value) {
      lat = ''
      lng = ''
    }
    let modifiedValue = value
    if (value) modifiedValue = value.replace(", USA", "")
    this.setState({ address: modifiedValue, latitude: lat, longitude: lng })
  }

  setWithin (value) {
    this.setState({ within: parseInt(value) })
  }

  applyClicked () {
    if (this.state.address && this.state.address !== '' && (!this.state.latitude || !this.state.longitude)) {
      this.searchCalls += 1
      if (this.searchCalls < 20) {
        setTimeout(() => {
          this.applyClicked()
        }, 100)
        return
      } else {
        this.searchCalls = 0
      }
    } else {
      this.searchCalls = 0
    }

    let urlbase = window.location.pathname[window.location.pathname.length - 1] === '/' ? window.location.pathname.substr(0, window.location.pathname.length - 1) : window.location.pathname

    window.location.href = `http://${window.location.hostname}${urlbase}/find-a-doctor/results?${this.state.lookingFor ? 'q=' + encodeURIComponent(this.state.lookingFor) + '&' : ''}${this.state.acceptingNewPatients ? 'accpat=' + this.state.acceptingNewPatients + '&' : ''}${this.state.address ? 'locq=' + this.state.address + '&' : ''}${this.state.latitude ? 'lat=' + this.state.latitude + '&' : ''}${this.state.longitude ? 'lng=' + this.state.longitude + '&' : ''}${this.state.within && (this.state.address || this.state.latitude || this.state.longitude) ? 'dist=' + this.state.within + '&' : ''}type=Doctor&referrer=homepage`
  }

  validateEnter () {
    if (this.props.type !== 'services') {
      if (this.state.lookingFor) {
        return true
      } else if (this.state.address) {
        return true
      }
    } else {
      if (this.state.lookingFor) {
        return true
      }
    }
    return false
  }

  enterSubmit (event) {
    let enableSearch = false
    enableSearch = this.validateEnter()

    if (enableSearch) {
      if (event.keyCode === 13) {
        this.applyClicked()
      }
    }
  }

  render () {
    return (
      <div className='fad-home-container'>
        <div className='fad-title-container'>
          <div>Find A <SVGInline className='stethoscope' svg={StethoscopeIcon} /></div>
          <div className='doctor-text'>Doctor</div>
        </div>
        <div className='fad-form-container'>
          <div className='fad-input-row'>
            <LookingForConnector setLookingFor={this.setLookingFor} lookingFor={this.state.lookingFor} enterSubmit={this.enterSubmit} />
          </div>
          <div className='fad-input-row'>
            <LocationInput address={this.state.address} latitude={this.state.latitude} longitude={this.state.longitude} within={this.state.within} setLocation={this.setLocation} enterSubmit={this.enterSubmit} />
            </div>
          <div className='fad-input-row checkbox-row'>
            <span><input type='checkbox' onClick={this.setAcceptingNewPatients} checked={this.state.acceptingNewPatients} autocomplete='off' /> Doctors accepting new patients</span>
          </div>
          <div className='fad-input-row submit-row'>
            <input type='submit' value='Search' className='fad-submit' onClick={() => this.applyClicked()} />
          </div>
        </div>
        <div className='fad-links-container'>
          <h4>Quick links</h4>
          <div className='fad-links-row'>
            <QuickLink icon={MyChartIcon} title={this.props.linkOneText} url={this.props.linkOneUrl} target={this.props.linkOneTarget} />
            <QuickLink icon={CalendarIcon} title={this.props.linkTwoText} url={this.props.linkTwoUrl} target={this.props.linkTwoTarget} />
          </div>
          <div className='fad-links-row'>
            <QuickLink icon={WalletIcon} title={this.props.linkThreeText} url={this.props.linkThreeUrl} target={this.props.linkThreeTarget} />
            <QuickLink icon={CareersIcon} title={this.props.linkFourText} url={this.props.linkFourUrl} target={this.props.linkFourTarget} />
          </div>
        </div>
      </div>
    )
  }
}
