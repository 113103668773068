import React from 'react'

export default class SkeletonCard extends React.Component {
  render () {
    return <div className='class-card skeleton-box'>
      <div>
        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
    </div>
  }
}
