import React from 'react'
import Map from './Map/Map.jsx'
import SideBar from './Sidebar/Sidebar.jsx'
import SVGInline from 'react-svg-inline'
import LocationCard from '../../Search/components/SearchCards/LocationCard/LocationCard.jsx'
import closeIcon from '../../shared/assets/x.svg'
import './multi-location-map.scss'

export default class MultiLocationMap extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedLocation: null
    }
    this.offClick = this.offClick.bind(this)
    this.selectionHandler = this.selectionHandler.bind(this)
    window.addEventListener('click', this.offClick, {passive: true})
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.offClick)
  }

  componentWillReceiveProps (props) {
    if (props.results && props.results.length === 0) {
      this.setState({ selectedLocation: null })
    }
  }

  offClick (e) {
    if (this.state.selectedLocation && window.screen.width > 767) {
      try {
        let isMapOverlay = e.target.className.indexOf('shown') > -1
        let notLocationCard = e.target.parentElement.className.indexOf('location-card') === -1
        if (notLocationCard && isMapOverlay) {
          this.setState({ selectedLocation: null })
        }
      } catch (error) { }
    }
  }

  selectionHandler (location) {
    this.setState({ selectedLocation: location })
  }

  render () {
    return <div id='locationResults' className='multi-container'>
      <div className={`overlay ${this.state.selectedLocation ? 'shown' : ''} `} />
      {
        this.props.results && this.props.results.length > 0 &&
        <div className='component-wrapper map-wrapper'>
          <div className='epi-blocks'>
            <div className='map-container epi-block' role='application'aria-label="Map of locations">
              <Map className='map' results={this.props.results} selectedLocation={this.state.selectedLocation} selectionHandler={this.selectionHandler} />
            </div>
            <SideBar results={this.props.results} selectedLocation={this.state.selectedLocation} selectionHandler={this.selectionHandler} schedulingFlow={this.props.schedulingFlow} />
          </div>
        </div>
      }
      {
        this.state.selectedLocation !== null &&
        <div className='close-container show-for-medium'>
          <button className='location-card-close' onClick={() => { this.setState({ selectedLocation: null }) }}><SVGInline svg={closeIcon} /></button>
          <LocationCard onBlur={() => { this.setState({ selectedLocation: null }) }} location={this.state.selectedLocation} />
        </div>
      }
    </div>
  }
}
