import './SuperSortMobile.scss'
import React from 'react'
import Modal from '../../../../shared/components/Modal'

class SuperSortMobile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedRadioVal: this.props.currentValue ? this.props.currentValue : null
        }
        this.renderOptions = this.renderOptions.bind(this)
        this.changeClick = this.changeClick.bind(this)
        this.sortClick = this.sortClick.bind(this)
        this.cancelClick = this.cancelClick.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentValue !== prevProps.currentValue && this.props.currentValue !== this.state.checkedRadioVal) {
            this.setState({ checkedRadioVal: this.props.currentValue})
        }
    }

    renderOptions(option, index) {
        if (typeof option === 'object') {
            if (option.disabled) return null
            return (<div className={`search-radio-item`}>
                <input type='radio' value={option.value} key={index} name='superSearchSort' disabled={option.disabled} checked={this.state.checkedRadioVal === option.value} onChange={(event) => this.changeClick(event)}/>
                <div className='super-sort-radio-label'>{option.label}</div>
            </div>)
        }
        return (<div className={`search-radio-item`}>
            <input type='radio' value={option} key={index} name='superSearchSort' checked={this.state.checkedRadioVal === option} onChange={(event) => this.changeClick(event)}/>
            <div className='super-sort-radio-label'>{option}</div>
        </div>)
    }

    changeClick (event) {
        this.setState({ checkedRadioVal: event.target.value })
    }

    sortClick (selectOptionHandler) {
        if (this.state.checkedRadioVal) selectOptionHandler(this.state.checkedRadioVal)
        this.props.setShowModal(false)
    }

    cancelClick() {
        this.props.setShowModal(false)
        this.setState({ checkedRadioVal: this.props.currentValue ? this.props.currentValue : null })
    }

    render() {
        return (
            <div className='super-sort-container-mobile'>
            <Modal hideFunction={() => {this.cancelClick()}} showModal={this.props.showModal}>
              <div className='super-modal-container'>
                <h4>Sort</h4>
                <div className='super-sort-div' value={this.props.currentValue} disabled={this.props.disabled} aria-label='dropdown' >
                    {this.props.options && this.props.options.length && this.props.options.map(this.renderOptions)}
                </div>
                <div className='super-modal-footer'>
                  <span onClick={() => {this.cancelClick()}}>Cancel</span>
                  <button className='btn apply-button fad-search-button desktop' onClick={() => this.sortClick(this.props.selectOptionHandler)} >Sort</button>
                </div>
              </div>
            </Modal>
            </div>
        )
    }
}

export default SuperSortMobile
