import moment from 'moment'

const providerSorter = (filteredProviders, sortOrder) => {
  const noRating = { Rating: null, NumberOfRatings: 0 }
  switch (sortOrder) {
    case 'A TO Z':
      return filteredProviders.sort((a, b) => {
        let aa = a.lastName + ', ' + a.firstName
        let bb = b.lastName + ', ' + b.firstName

        return aa.toUpperCase() > bb.toUpperCase() ? 1 : -1
      })
    case 'Z TO A':
      return filteredProviders.sort((a, b) => {
        let aa = a.lastName + ', ' + a.firstName
        let bb = b.lastName + ', ' + b.firstName

        return aa.toUpperCase() > bb.toUpperCase() ? -1 : 1
      })
    case 'Top Rated':
      return filteredProviders.sort((a, b) => {
        const providerA = a.rating ? a.rating : noRating
        const providerB = b.rating ? b.rating : noRating
        if (providerA.Rating === providerB.Rating && !a.distance) {
          return lastnameAlphaHelper(a, b)
        } else if (providerA.Rating === providerB.Rating && a.distance) {
          return distanceComparatorHelper(a, b)
        } else {
          return (providerA.Rating > providerB.Rating) ? -1 : 1
        }
      })
    case 'Most Reviewed':
      return filteredProviders.sort((a, b) => {
        const providerA = a.rating ? a.rating : noRating
        const providerB = b.rating ? b.rating : noRating
        if (parseInt(providerA.NumberOfRatings) === parseInt(providerB.NumberOfRatings) && !a.distance) {
          return lastnameAlphaHelper(a, b)
        } else if (parseInt(providerA.NumberOfRatings) === parseInt(providerB.NumberOfRatings) && a.distance) {
          return distanceComparatorHelper(a, b)
        } else {
          return (parseInt(providerA.NumberOfRatings) > parseInt(providerB.NumberOfRatings)) ? -1 : 1
        }
      })
    case 'Distance':
      return filteredProviders.sort((a, b) => distanceComparatorHelper(a, b))
    case 'Random':
      return randomize(filteredProviders)
    case 'First Available':
        const providersWithoutSchedStartDates = filteredProviders.filter(provider => !provider.schedStartDates || !provider.schedStartDates.length)
        const providersWithSchedStartDates = filteredProviders.filter(provider => provider.schedStartDates && provider.schedStartDates.length)

        providersWithSchedStartDates.sort((a, b) => {
            return dateComparatorHelper(a.schedStartDates[0], b.schedStartDates[0])
        })
        return providersWithSchedStartDates.concat(providersWithoutSchedStartDates)
    default:
      return filteredProviders
  }
}

function randomize (array) {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const dateComparatorHelper = (a, b) => {
  let aDate = moment(a.Date + ' ' + a.Time, 'YYYY-MM-DD hh:mm:ss')
  let bDate = moment(b.Date + ' ' + b.Time, 'YYYY-MM-DD hh:mm:ss')
  if (aDate > bDate) {
    return 1
  } else if (aDate < bDate) {
    return -1
  } else {
    return 0
  }
}

const distanceComparatorHelper = (a, b) => {
  if (Number(a.distance) > Number(b.distance)) {
    return 1
  } else if (Number(a.distance) < Number(b.distance)) {
    return -1
  } else {
    return a.lastName.toUpperCase() > b.lastName.toUpperCase() ? 1 : -1
  }
}

const lastnameAlphaHelper = (a, b) => {
  let aa = a.lastName + ', ' + a.firstName
  let bb = b.lastName + ', ' + b.firstName

  return (aa.toUpperCase() < bb.toUpperCase()) ? -1 : (aa.toUpperCase() > bb.toUpperCase()) ? 1 : 0
}

export { providerSorter }
