const formatIncomingLocationData = (locations) => {
  return locations.map(location => {
    return {
      'address': formatAddress(location.Address),
      'departmentId': location.DepartmentId,
      'departmentName': location.DepartmentName,
      'facilityName': location.FacilityName,
      'phoneNumber': formatPhoneNumber(location.PhoneNumber),
      'distance': location.Distance,
      'latitude': location.Latitude,
      'longitude': location.Longitude
    }
  })
}

const formatPhoneNumber = (phoneNumber) => {
  const splitPhoneNumber = phoneNumber.match(/^(\+1)(\d{3})(\d{3})(\d{4})$/)
  if (splitPhoneNumber && splitPhoneNumber.length >= 4) {
    return `(${splitPhoneNumber[2]}) ${splitPhoneNumber[3]}.${splitPhoneNumber[4]}`
  } else {
    return ''
  }
}

const formatAddress = (address) => {
  const splitAddress = address.split('\n')

  let streetAddress = splitAddress[0]
  if (splitAddress.length > 2) {
    streetAddress += ' ' + splitAddress[1]
  }

  return {
    street: streetAddress,
    cityStateZip: formatCityAndZip(splitAddress[splitAddress.length - 1])
  }
}

const formatCityAndZip = (addressToTransform) => {
  const cityAndZip = addressToTransform.match(/^(.+), (.+) (\d{5})/)
  const city = cityAndZip[1]
  const state = cityAndZip[2]
  const zipCode = cityAndZip[3]

  return `${city}, ${state} ${zipCode}`
}

export { formatIncomingLocationData, formatPhoneNumber, formatAddress }
