import React from 'react'
import moment from 'moment'
import SVGInline from 'react-svg-inline'
import BackArrow from '../../../../shared/assets/arrow-left.svg'
import InfoIcon from '../../../../shared/assets/info-reviews-drawer.svg'
import CloseIcon from '../../../../shared/assets/x.svg'
import StarBar from '../../../../shared/components/StarBar'
import CustomDropdown from './CustomDropdown'
import PressGaneyModal from '../PressGaneyModal'
import RatingCategories from './RatingCategories'
import './reviews-drawer.scss'
import Modal from '../../../../shared/components/Modal'
import '../SkeletonDrawer/skeleton-drawer.scss'

export default class ReviewsDrawer extends React.Component {
  constructor (props) {
    super(props)
    this.reviewDrawerRef = React.createRef()
    this.handleClickOutsideDrawer = this.handleClickOutsideDrawer.bind(this)
    this.state = {
      sort: 'Date',
      sortOption: { value: 'Date', label: 'Most Recent' },
      pressGaneyShow: false
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutsideDrawer)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutsideDrawer)
  }

  handleClickOutsideDrawer (event) {
    if (this.reviewDrawerRef.current && !this.reviewDrawerRef.current.contains(event.target)) {
      this.props.handleCloseAll()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.provider !== this.props.provider) {
      if (!this.props.provider.reviews) {
        this.props.fetchProviderComments()
      }
    }
  }

  render () {
    const { provider, fadeOutType, from, show, handleClose, handleCloseAll, onAnimationEnd } = this.props
    const sortOptions = [{ value: 'Date', label: 'Most Recent' }, { value: 'Rating+', label: 'Highest Ratings' }, { value: 'Rating-', label: 'Lowest Ratings' }]

    const sortComments = (comments) => {
      switch (this.state.sort) {
        case 'Date':
          return comments.sort((a, b) => {
            if (a.Date > b.Date) {
              return -1
            } else if (a.Date === b.Date) {
              return a.Rating > b.Rating ? -1 : 1
            } else {
              return 1
            }
          })
        case 'Rating+':
          return comments.sort((a, b) => {
            if (a.Rating > b.Rating) {
              return -1
            } else if (a.Rating === b.Rating) {
              return a.Date > b.Date ? -1 : 1
            } else {
              return 1
            }
          })
        case 'Rating-':
          return comments.sort((a, b) => {
            if (a.Rating > b.Rating) {
              return 1
            } else if (a.Rating === b.Rating) {
              return a.Date > b.Date ? -1 : 1
            } else {
              return -1
            }
          })
      }
    }
    const comment = (rating, date, text, index) => {
      return (
        <div className='comment-block filled-comment' key={index}>
          <div className='star-date-container'>
            <div className='star-container'>
              <StarBar rating={rating} />
            </div>
            <div className='display-inline review-date'>Reviewed on {moment(date).format('MMM DD, YYYY')}</div>
          </div>
          <p className='comment-text'>{text}</p>
          <div className='comment-seperator' />
        </div>
      )
    }
    let comments = () => {
      const list = provider.reviews.comments
      return sortComments(list).map((c, index) => comment(c.Rating, c.Date, c.Comment, index))
    }
    let customClass = 'bg-over-drawer'
    if (from === 'card') {
      customClass = `${customClass} animate-in-slide`
    }
    if (fadeOutType === 'reviews') {
      customClass = `${customClass} animate-out`
    } else if (fadeOutType === 'all') {
      customClass = `${customClass} animate-out-all-top`
    }

    return (
      <span className='skeleton-drawer-outer' onAnimationEnd={() => { onAnimationEnd() }}>
        <Modal showModal={show} hideCloseButton hideFunction={handleClose} customClass={customClass} >
          <div className='reviews-drawer-container' ref={this.reviewDrawerRef}>
            <div className='blue-rectangle'>
              <div className='close-buttons'>
                <div className='back-to-profile' onClick={handleClose}><SVGInline className='back-arrow' svg={BackArrow} /><span className='back-to-profile-text' >Profile</span></div>

                <div className='close-all' onClick={handleCloseAll} ><SVGInline className='close-all-x' svg={CloseIcon} /></div>
              </div>
            </div>

            {provider && provider.rating && provider.rating.Rating &&
              <div className='ratings-and-reviews-section'>
                <div className='section-title'>Ratings and Reviews<SVGInline className='info-icon' svg={InfoIcon} onClick={() => { this.setState({ pressGaneyShow: true }) }} /></div>
                { this.state.pressGaneyShow
                  ? <PressGaneyModal show={this.state.pressGaneyShow} handleClose={() => { this.setState({ pressGaneyShow: false }) }} />
                  : null
                }
                <div className='sub-section-title'>Overall Rating</div>
                <div className='overall-rating-container'>
                  <div className='provider-starbar'>
                    <StarBar rating={provider.rating.Rating} />
                  </div>
                  <div className='provider-ratings' >{provider.rating.Rating}</div>
                </div>

                {!provider.reviews ? <div className='skeleton' > <div className='grey-box' /><div className='grey-box' /><div className='grey-box' /> </div> : null}
                {
                  provider.reviews && provider.reviews.ratingCategories && provider.reviews.ratingCategories.length > 0 &&
                  <RatingCategories ratingCategories={provider.reviews.ratingCategories} />
                }
                {
                  provider.reviews && provider.reviews.comments && provider.reviews.comments.length > 0 &&
                  <div className='comments-container'>
                    <div className='row-container'>
                      <div className='medium-12'>
                        <div className='sort-dropdown'>
                          <CustomDropdown className='float-right' selectOptionHandler={(option) => { this.setState({ sort: option.value, sortOption: option }) }} title='Sort By:' options={sortOptions} currentValue={this.state.sort} currentOption={this.state.sortOption} showPrompt={false} />
                        </div>
                      </div>
                    </div>
                    {
                      comments()
                    }
                  </div>
                }
              </div>
            }
          </div>
        </Modal>
      </span>
    )
  }
}

// export default ReviewsDrawer
