import { connect } from 'react-redux'
import FilteredTooFar from './FilteredTooFar.jsx'
import { getURLParameters, insertEmptyFilters } from '../../../shared/utility-functions/searchParams.js'
import { insertBrowserHistory } from '../../state/history'
import { getSort } from '../../selectors/search-filter-selectors.js'

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  sort: getSort(state)
})

const mapDispatchToProps = (dispatch) => ({
  clearFilters: () => dispatch({ type: 'RESET_SCHEDULE_A_DOCTOR_FILTERS' }),
  resetSort: () => dispatch({ type: 'SET_DOCTOR_SEARCH_SORT_ORDER', value: 'A TO Z' })
})

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    clearFilters: () => {
      if (stateProps.enableUrl) {
        let params = getURLParameters()
        if (params.sort === 'First Available') {
          params.sort = 'A TO Z'
        }
        insertBrowserHistory(insertEmptyFilters(params))
      }
      dispatchProps.clearFilters()
      if (stateProps.sort === 'First Available') {
        dispatchProps.resetSort()
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FilteredTooFar)
