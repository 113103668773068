import React from 'react'
import './filter.scss'
import Checkbox from '../../../../shared/components/Checkbox'
import { insertBrowserHistory } from '../../../state/history'
import { getURLParameters, insertFiltersIntoURLParameters, scrollToLocation } from '../../../../shared/utility-functions'
import SVGInline from 'react-svg-inline'
import emptyStarIcon from '../../../../shared/assets/empty_star.svg'
import fullStarIcon from '../../../../shared/assets/full_star.svg'

export default class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.applyFilter = this.applyFilter.bind(this)
    this.checkboxes = this.checkboxes.bind(this)
    this.ratings = this.ratings.bind(this)
    this.popModal = this.popModal.bind(this)
  }

  popModal() {
    this.props.showAlertModal(true)
  }

  applyFilter(event) {
    let value = event.target && event.target.value ? event.target.value : null
    let checked = event.target && event.target.checked ? event.target.checked : false
    if (value === 'Doctors with Online Scheduling') {
      this.props.setSearchOrder('First Available')
      insertBrowserHistory({ ...getURLParameters(), sort: 'First Available' })
    }
    if (value === 'Doctors with Online Scheduling' && !checked && this.props.sort === 'First Available') {
      event.target.checked = true
      this.popModal()
    } else if (this.props.title === 'RATING') {
      this.props.setFilter([value])
      if (this.props.enableUrl) {
        insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), this.props.dispatchType, [value]))
      }
    } else {
      let currentFilters
      if (checked) {
        if (this.props.filters[this.props.filterKey]) {
          currentFilters = this.props.filters[this.props.filterKey].concat(value)
        } else {
          currentFilters = [value]
        }
      } else {
        currentFilters = this.props.filters[this.props.filterKey].filter(item => item !== value)
      }
      this.props.setFilter(currentFilters)
      if (this.props.enableUrl) {
        insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), this.props.dispatchType, currentFilters))
      }
      scrollToLocation('.find-a-doctor-page')
    }
  }

  clearFilters(e) {
    e.stopPropagation()
    this.props.setFilter([])
    if (this.props.enableUrl) {
      insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), this.props.dispatchType, []))
    }
    scrollToLocation('.find-a-doctor-page')
  }

  renderStars(num) {
    const stars = Array.from({ length: 5 }, (_, index) => {
      return index < num ? <SVGInline className='full-star' svg={fullStarIcon} /> : <SVGInline className='empty-star' svg={emptyStarIcon} />
    })

    return stars
  }

  ratings() {
    if (this.props.options.length < 1) return
    let maxRating = Math.floor(Math.max(...this.props.options))
    if (maxRating > 4) maxRating = 4
    const numInputs = Array.from({ length: maxRating }, (_, index) => index + 1)

    return numInputs.reverse().map((option, index) => {
      let checkToggle = false
      if (option) {
        checkToggle = this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].includes(option.toString())
      }

      return (
        <div className='rating--container' key={index}>
          <input type='radio' checked={checkToggle} value={option} onChange={this.applyFilter} name='mobile-rating' />
          <label htmlFor={`super-radio-${option}`}>
            <div className='star-rating'>{this.renderStars(option)}</div>
            <span>{option} Star{option > 1 ? 's' : ''} & Up</span>
          </label>
        </div>
      )
    })
  }

  checkboxes(key) {
    let checkboxLength

    if (this.props.collapse) {
      checkboxLength = 0
    } else if (!this.props.showMore && this.props.options.length > 5) {
      checkboxLength = key ? this.props.options.length : 5
    } else {
      checkboxLength = this.props.options.length
    }
    return this.props.options.slice(0, checkboxLength).map((option, index) => {
      let checkToggle = false
      if (option) {
        if (typeof option === 'object') {
          checkToggle = this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].includes(option.value)
        } else {
          checkToggle = this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].includes(option)
          option = { value: option, label: option }
        }
        if (key) {
          return checkToggle ? <div key={index}>
            <Checkbox checked={checkToggle} onChangeHandler={this.applyFilter} value={option.value} label={option.label} />
          </div> : null
        } else if (this.props.options && this.props.options.length <= 5) {
          return <div key={index}><Checkbox checked={checkToggle} onChangeHandler={this.applyFilter} value={option.value} label={option.label} /></div>
        } else {
          return checkToggle ? null : <div key={index}>
            <Checkbox checked={checkToggle} onChangeHandler={this.applyFilter} value={option.value} label={option.label} />
          </div>
        }
      }
    })
  }

  render() {
    return this.props.options.length === 0 ? <span className='hide-filter' /> : (
      <div className={`show-filter ${this.props.className ? this.props.className : ''}`}>
        <div onClick={() => this.props.toggleCollapse(this.props.filterKey)} className={`filter-title ${this.props.title ? this.props.title.replace(/ +/g, '-').toLowerCase() : ''}`}>
          {
            this.props.title &&
            <div className='filter-title-container'>
              <div className='filter-title-text'>{this.props.title}</div>
              <div className='collapse-text'>
                {this.props.collapseable ? <img src={`/ClientResources/Website/images/${this.props.collapse ? 'expand' : 'minimize'}.png`} alt={this.props.collapse ? 'expand filter toggle' : 'minimize filter toggle'} /> : ''}
              </div>
            </div>
          }
        </div>
        {
          !this.props.title === 'RATING' &&
          <div className={this.props.filters[this.props.filterKey] && this.props.filters[this.props.filterKey].length > 0 && this.props.options.length > 4 && this.props.title !== '' ? 'clear-filter-tag' : 'hide-filter'}>
            <span>{this.props.filters[this.props.filterKey] ? this.props.filters[this.props.filterKey].length : ''} Selected</span>
            <span className='clear-filter' onClick={(e) => this.clearFilters(e)}>x</span>
          </div>
        }
        <div className={this.props.collapse === true ? 'collapsed-filters' : 'open-filters'}>
          <div className='filter-checkbox-container'>
            {this.props.options && this.props.options.length > 5 && this.checkboxes('checked')}
            {this.props.title === 'RATING' ? this.ratings() : this.checkboxes()}
          </div>
          <div onClick={() => this.props.showHideOptions(this.props.filterKey)} className={(this.props.options.length <= 5 || this.props.title === 'RATING') ? 'hide-filter' : 'show-hide-filters'}>
            {this.props.showMore ? 'Show Less' : 'Show More'}
          </div>
        </div>
      </div>
    )
  }
}
