const searchCount = (count, flow, options) => {
  let item = null
  if (!options) {
    options = {}
  }
  switch (flow) {
    case 'fad':
      item = options.app && options.isBlock ? 'Provider' : 'Doctor'
      break
    case 'cin':
      item = 'Provider'
      break
    case 'sad':
      item = 'Doctor'
      break
    case 'mam':
      item = 'Location'
      break
    case 'location':
      item = 'Location'
      break
    case 'classes':
      item = count === 1 ? 'Class' : 'Classe'
      break
    case 'service':
      item = 'Service'
  }
  return `${options.affiliated ? 'Affiliated ' : ''}${item != null ? item : ''}${count != null && count !== 1 ? 's' : ''}`
}

export default searchCount
