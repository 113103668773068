import React from 'react'
import './SuperLocationCard.scss'
import URLS from '../../../../shared/constants/urls.js'
import DirectionsLink from '../../../../shared/components/DirectionsLink/index'
import { cleanNumber } from '../../../../shared/utility-functions'


const SuperLocationCard = ({ location, id }) => {
    let type = location.AddressKey ? 'loc-feed' : 'fad-feed'
    let imageURL = null
    if (location.ImageUrl && Array.from(location.ImageUrl)[0] !== '/') { imageURL = `/${location.ImageUrl}` }
    let name = location.Name ? location.Name : location.lbl
    if (location.SearchNameOverride && location.SearchNameOverride !== '') { name = location.SearchNameOverride}
    let dist = location.Distance ? location.Distance : location.d ? location.d : null
    let addressLine1 = location.AddressLine1 ? location.AddressLine1 : location.adr
    let addressLine2 = location.AddressLine2 ? location.AddressLine2 : null
    let cityState = location.City ? `${location.City}, ${location.State}` :  `${location.c}, ${location.s}`
    let phone = location.Phone ? location.Phone : null
    if (location.cr && location.cr.length > 0) {
        if (location.cr[0].type === 'Phone') { phone = location.cr[0].Number}
    }
    let specialty = null
    if(location.Specialties && location.Specialties.length > 0) {
        specialty = location.Specialties[0].Text
    }
    const getHttpsUrl  = (url) => {
        return url.replace(/^http:\/\//i, 'https://')
    }

    const loadNoImage = (event) => {
        event.srcElement.src = URLS.defaultLocationImage
    }

    const addressBuilder = (address) => {
        if (type === 'loc-feed') {
            return {
                street: address.AddressLine1,
                cityStateZip: `${address.City}, ${address.State} ${address.ZipCode}`
            }
        }
        else {
            return {
                street: address.adr,
                cityStateZip: `${address.c}, ${address.s} ${address.z}`
            }
        }
    }

    return (
        <div className='super-location-card'>
            <div className='location-photo'>
                <a  className='location-image-link' target='_self'>
                <img alt={name} className={imageURL ? 'doc-img' : 'doc-img default-photo'} src={imageURL ? getHttpsUrl(imageURL) : getHttpsUrl(URLS.defaultLocationImage)} onError={loadNoImage}  /></a>
              </div>
            <div className='location-info'>
                <div className='location-title'>{name}</div>
                {specialty ? <div className='specialty-container'>{specialty}</div> : null}
                <div className='address-container notMobile'>
                    <div>{(dist !== null && dist > 0) ? `${dist} miles - `  : null}{addressLine1} {cityState}</div>
                </div>
                <div className='address-container mobile'>
                    <div>{addressLine1} {cityState}</div>
                </div>
                { addressLine2 !== null 
                    ? <div className='address-container line-2'>
                        <div>{addressLine2}</div>
                    </div>
                    : null
                }
                <div className='directions-link'>
                {
                    addressLine1 &&
                    <DirectionsLink address={addressBuilder(location)} text='Directions' displayIcon={false} />
                }
                </div>
                <div className='phone-number'>
                    { phone != null && phone !== '' && <a href={`tel:${cleanNumber(phone)}`}><span>{phone}</span></a> }
                </div>
            </div>
        </div>
    )

}

export default SuperLocationCard
