const serviceSorter = (services, sortOrder) => {
  switch (sortOrder) {
    case 'A TO Z':
      return services.sort((a, b) => {
        if (a.Name && b.Name) {
          return a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1
        } else {
          return -1
        }
      }).map(a => a)
    case 'Z TO A':
      return services.sort((a, b) => {
        if (a.Name && b.Name) {
          return a.Name.toUpperCase() > b.Name.toUpperCase() ? -1 : 1
        } else {
          return -1
        }
      }).map(a => a)
    default:
      return services
  }
}

export default serviceSorter
