export const clickToCallTransformer = (flowType, provider = null, facilityName = null, facilityLocation = null, facilityPhoneNumber = null) => {
    let obj = Object.is(provider, null) || provider === undefined ?
    {
        providerPhoneNumber: facilityPhoneNumber === null ? undefined : facilityPhoneNumber,
        providerName: undefined,
        providerID: undefined,
        providerNPI: undefined,
        providerSpecialty: undefined,
        providerAddress: undefined
    } :
    {
        providerPhoneNumber: provider.phone,
        providerName: provider.title,
        providerID: provider.epicId === null || provider.epicId === undefined
            ? provider.id
            : provider.epicId,
        providerNPI: provider.npi,        
        providerSpecialty: provider.specialties === null ? undefined : provider.specialties.join(', '),
        providerAddress: provider.address === null
            ? undefined
            : `${provider.address.adr.trim()}, ${provider.address.c}, ${provider.address.s} ${provider.address.z}`
    }

    obj['flow'] = flowType === null ? undefined : flowType
    obj['facilityName'] = facilityName === null ? undefined : facilityName
    obj['facilityAddress'] = facilityLocation === null
        ? undefined
        : `${facilityLocation.Address}, ${facilityLocation.City} ${facilityLocation.State} ${facilityLocation.ZipCode}`

    return obj
}
