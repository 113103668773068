import React from 'react'
import StarBar from '../../../../../shared/components/StarBar'
import './rating-categories.scss'

const RatingCategories = ({ ratingCategories }) => {
  const ratingCategory = (category, index) => {
    return (
      category &&
        <div className='rating-category' key={index}>
          <div className='category-name'>{category.Name}</div>
          <div className='category-rating'>
            <div className='category-stars'><StarBar rating={category.Rating} /></div>
            <div className='rating-text'>{category.Rating}</div>
          </div>
        </div>
    )
  }
  return (
    <div className='rating-categories-container'>
      {ratingCategories.map((category, index) => ratingCategory(category, index))}
    </div>
  )
}

export default RatingCategories
