export const getFilledTimesData = (timesData, startDate, endDate, filledTimesOptions) => {
    const dateSpan = getDateSpan(startDate, endDate, filledTimesOptions)
    const results = []

    dateSpan.forEach((d) => {
        const dateData = timesData.find(i => i.Date.getTime() === d.getTime())

        results.push(dateData ?
            dateData :
            {
                Date: d,
                Times: []
            }
        )
    })

    return results
}

export const DayOptions = Object.freeze({
    Sunday: [0],
    Monday: [1],
    Tuesday: [2],
    Wednesday: [3],
    Thursday: [4],
    Friday: [5],
    Saturday: [6],
    Weekday: [1, 2, 3, 4, 5],
    Weekend: [0, 6],
    FullWeek: [0, 1, 2, 3, 4, 5, 6]
})

export const getDateSpan = (startDate, endDate, dayOptionsList) => {
    const dates = []
    const useDays = [...new Set(dayOptionsList.flat())]
    let currentDate = new Date(startDate)

    while (currentDate <= endDate) {
        if (useDays.includes(currentDate.getDay())) {
            let tempDate = new Date(currentDate)

            tempDate.setHours(0, 0, 0, 0)
            dates.push(tempDate)
        }
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
}