import moment from 'moment'

const classesSorter = (filteredClasses, sortOrder) => {
  switch (sortOrder) {
    case 'A TO Z':
      return filteredClasses.sort((a, b) => {
        return a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1
      })
    case 'Z TO A':
      return filteredClasses.sort((a, b) => {
        return a.Name.toUpperCase() > b.Name.toUpperCase() ? -1 : 1
      })
    case 'Random':
      return randomize(filteredClasses)
    default:
      return filteredClasses.sort((a, b) => {
        return a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1
      })
  }
}

function randomize (array) {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const dateComparatorHelper = (a, b) => {
  let aDate = moment(a.Date + ' ' + a.Time, 'YYYY-MM-DD hh:mm:ss')
  let bDate = moment(b.Date + ' ' + b.Time, 'YYYY-MM-DD hh:mm:ss')
  if (aDate > bDate) {
    return 1
  } else if (aDate < bDate) {
    return -1
  } else {
    return 0
  }
}

export { classesSorter }
