const locationTransformer = (locations) => {
  return locations.map(location => {
    let newPage = {
      Address: location.AddressLine1 + (location.AddressLine2 ? (', ' + location.AddressLine2) : ''),
      PhotoURL: 'http://' + window.location.hostname + '/' + location.ImageUrl,
      City: location.City,
      State: location.State,
      ZipCode: location.ZipCode,
      Name: nameBuilder(location),
      SearchName: setSearchName(location),
      Phone: location.Phone,
      WaitTimeID: location.WaitTimeID,
      DepartmentID: location.DepartmentID,
      ResourceID: location.ResourceID,
      LocationTypes: location.LocationTypes,
      Hours: location.Hours,
      Id: location.Id,
      Latitude: location.Latitude,
      Longitude: location.Longitude,
      Distance: location.Distance ? Number(location.Distance).toFixed(2) : null,
      Url: location.Url ? getLocationUrl(location.Url) : null,
      SearchNameOverride: location.SearchNameOverride
    }
    return newPage
  })
}

const getLocationUrl = (url) => {
    if(url.includes('https')) {
      return url
    } else {
      return 'http://' + window.location.hostname + '/' + url
    }
}

const setSearchName = (location) => {
  if (location.SearchNameOverride && location.SearchNameOverride.length > 0) {
    return location.SearchNameOverride
  }
  return location.Name
}

const nameBuilder = (location) => {
  if (location.Specialties && location.Specialties.length) {
    return location.Specialties.reduce((a, b) => {
      if (!a || a.length === 0) {
        a = b.Text
      } else {
        a += ` | ${b.Text}`
      }
      return a
    }, '')
  } else {
    return location.Name
  }
}

export default locationTransformer
