import SVGInline from 'react-svg-inline'
import React from 'react'
import loader from '../../assets/loading.svg'
import loaderInverse from '../../assets/loading-inverse.svg'
import './loading-element.scss'

const LoadingElement = ({ className = '', displayText = '', inverse = false }) => (
  <div className={'loading-element-container ' + className}>
    <div className='loading-svg' >
      <SVGInline svg={inverse ? loaderInverse : loader} />
    </div>
    <div className='display-text'>{displayText}</div>
  </div>
)

export const LoadingElementCSS = () => (
  <div className='loading-indicator-container'>
    <a className='loading-indicator'>
      <div className='sk-fading-circle'>
        <div className='sk-circle2 sk-circle' />
        <div className='sk-circle3 sk-circle' />
        <div className='sk-circle1 sk-circle' />
        <div className='sk-circle4 sk-circle' />
        <div className='sk-circle5 sk-circle' />
        <div className='sk-circle6 sk-circle' />
        <div className='sk-circle7 sk-circle' />
        <div className='sk-circle8 sk-circle' />
      </div>
    </a>
  </div>
)

export default LoadingElement
