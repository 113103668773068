import React from 'react'
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import '../../../../shared/sass/calendar-date-picker.scss'
import './calendar-date-picker-widget.scss'
import { insertBrowserHistory } from '../../../state/history'
import moment from 'moment'
import { getURLParameters, insertFiltersIntoURLParameters, isDateDayAheadAndNotOnTheWeekend } from '../../../../shared/utility-functions'
import SVGInline from 'react-svg-inline'
import Calendar from '../../../../shared/assets/calendar.svg'

class DateFilter extends React.Component {
  constructor (props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
    this.today = new Date()
    const dobString = window.sessionStorage.getItem('qDate') ? window.sessionStorage.getItem('qDate') : ""
    const formattedDob = moment(dobString, 'MM-DD-YYYY')
    const age = moment().diff(formattedDob.valueOf(), 'months')
    this.firstAvailAptDate = new Date(Date.now() + 1 * 86400000)
    if(age <= 479){
      let dob = new Date(formattedDob);
      dob.setHours(this.today.getHours(), this.today.getMinutes(), this.today.getSeconds())
      this.filteredDate = new Date(this.today.getFullYear(), dob.getMonth(), dob.getDate())
      this.firstAvailAptDate = new Date(this.filteredDate.getTime() + 1 * 86400000) 
    }
    this.maxMonth = new Date()
    this.maxMonth = new Date(this.maxMonth.setMonth(this.maxMonth.getMonth() + 7))
    this.isMammMobile = this.props.flow === 'mam' && this.props.mobile
  }

  componentDidMount () {
    let day = getURLParameters().filters.selectedAppointmentDate
    if (day) {
      this.props.setFilter(day)
      insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), 'SET_SELECTED_DATE', moment(day, 'YYYY-MM-DD').format('YYYY-MM-DD')))
    }
  }

  handleDayClick (day) {
    if (String(day) === String(this.props.selectedDate)) {
      this.props.setFilter('')
      insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), 'SET_SELECTED_DATE', ''))
    } else if (Math.floor((day - this.firstAvailAptDate) / (1000 * 60 * 60 * 24)) >= -1) {
      this.props.setFilter(day)
      insertBrowserHistory(insertFiltersIntoURLParameters(getURLParameters(), 'SET_SELECTED_DATE', moment(day, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')))
    }
  }

  renderDayPicker () {
    return (
      <div className='date-picker-container'>
        <DayPicker className='DayPicker-Enabled'
          month={this.firstAvailAptDate}
          fromMonth={this.today}
          toMonth={this.maxMonth}
          disabledDays={{
            before: this.firstAvailAptDate
          }}
          selectedDays={moment(this.props.selectedDate, 'YYYY-MM-DDTHH:mm:ss')._d || []}
          weekdaysShort={['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']}
          onDayClick={this.handleDayClick}
          modifiers={{
            firstDateForFunctionals: (day) => isDateDayAheadAndNotOnTheWeekend(day)
          }}
        />
      </div>
    )
  }

  renderDayPickerInput () {
    return (
      <div className='date-picker-input-container'>
        <div className='calendar' onClick={() => { this.input.state.showOverlay ? this.input.hideDayPicker() : this.input.showDayPicker() }}><SVGInline svg={Calendar} /></div>
        <div className='calendar-input-overlay' onClick={() => { this.input.state.showOverlay ? this.input.hideDayPicker() : this.input.showDayPicker() }} />
        <DayPickerInput
          value={this.props.selectedDate ? moment(this.props.selectedDate, 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YYYY') : ''}
          onDayChange={this.handleDayClick}
          placeholder={'MM/DD/YYYY'}
          title={'Date Filter'}
          ref={input => { this.input = input }}
          dayPickerProps={{
            fixedWeeks: false,
            month: this.firstAvailAptDate,
            fromMonth: this.today,
            toMonth: this.maxMonth,
            selectedDays: moment(this.props.selectedDate, 'YYYY-MM-DDTHH:mm:ss')._d || [],
            weekdaysShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
            disabledDays: {
              before: this.firstAvailAptDate
            }
          }}
        />
      </div>
    )
  }

  render () {
    return (
      <div className='show-filter show-date-filter'>
        <div className='filter-title'>
          <div className='filter-title-container'>
            <div className='filter-title-text'>DATE</div>
          </div>
        </div>
        <div className='calendar-date-picker'>
          {this.isMammMobile ? this.renderDayPickerInput() : this.renderDayPicker()}
        </div>
      </div>
    )
  }
}

export default DateFilter
