const DEFAULT_SORT_ORDER = 'A TO Z'

export const DEFAULT_STATE = {
  locations: [],
  searched: false,
  dataLoaded: false,
  locationsByAppointmentDateTime: [],
  loadingFilters: false,
  locationFilterError: false,
  callFailed: false,
  sortOrder: DEFAULT_SORT_ORDER,
  filters: {
    selectedAppointmentDate: '',
    selectedTimeSlots: [],
    selectedWeekendHours: [],
    selectedCity: ''
  },
  currentPage: 1
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'SET_LOCATIONS':
      return { ...state, ...{ locations: action.value, dataLoaded: true } }
    case 'CLEAR_LOCATIONS':
      return { ...state, ...{ locations: DEFAULT_STATE.locations, dataLoaded: false } }
    case 'LOCATION_FILTER_LOADING':
      return { ...state, ...{ loadingFilters: action.value } }
    case 'LOCATION_FILTER_ERROR':
      return { ...state, ...{ locationFilterError: action.value } }
    case 'SEARCH_LOCATIONS':
      return { ...state, ...{ searched: true } }
    case 'SET_LOCATIONS_CALL_FAILED':
      return { ...state, ...{ callFailed: action.value } }
    case 'RESET_LOCATION_FILTERS':
      return { ...state, ...{ filters: DEFAULT_STATE.filters } }
    case 'SET_LOCATION_FILTERS':
      return buildFilters(state, action.params)
    case 'SET_LOCATION_AUTOCOMPLETE_DATA':
      return { ...state, ...{ autoCompleteDataLocations: action.value } }
    case 'ADD_LOCATION_FILTER_APPOINTMENTS':
      return { ...state, ...{ locationsByAppointmentDateTime: { ...state.locationsByAppointmentDateTime, ...action.value } } }
    case 'SET_SELECTED_WEEKEND_HOURS':
      return { ...state, ...{ filters: { ...state.filters, selectedWeekendHours: action.value } } }
    case 'SET_SELECTED_DATE':
      return { ...state, ...{ filters: { ...state.filters, selectedAppointmentDate: action.value } } }
    case 'SET_SELECTED_TIME_SLOTS':
      return { ...state, ...{ filters: { ...state.filters, selectedTimeSlots: action.value } } }
    case 'SET_SELECTED_CITY':
      return { ...state, ...{ filters: { ...state.filters, selectedCity: action.value } } }
    case 'SET_LOCATION_SORT_ORDER':
      return { ...state, ...{ sortOrder: action.value } }
    case 'RESET_LOCATION_SORT_ORDER':
      return { ...state, ...{ sortOrder: DEFAULT_SORT_ORDER } }
    case 'SET_LOCATION_CURRENT_PAGE':
      return { ...state, ...{ currentPage: action.number } }
  }
  return state
}

const buildFilters = (state, params) => {
  let newFilters = {
    selectedAppointmentDate: '',
    selectedTimeSlots: [],
    selectedWeekendHours: [],
    selectedCity: ''
  }

  if (params.selectedAppointmentDate) newFilters.selectedAppointmentDate = params.selectedAppointmentDate
  if (params.selectedTimeSlots && params.selectedTimeSlots.length) newFilters.selectedTimeSlots = params.selectedTimeSlots
  if (params.selectedWeekendHours && params.selectedWeekendHours.length) newFilters.selectedWeekendHours = params.selectedWeekendHours
  if (params.selectedCity && params.selectedCity.length) newFilters.selectedCity = params.selectedCity
  return { ...state, ...{ filters: newFilters } }
}
