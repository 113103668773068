import { connect } from 'react-redux'
import ChestPain from './ChestPain.jsx'
import PainAtRestConnector from '../PainAtRest'
import ContinueToSchedulingConnector from '../ContinueToScheduling'

const mapDispatchToProps = dispatch => ({
  yesButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: PainAtRestConnector}),
  noButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: ContinueToSchedulingConnector})
})

export default connect(null, mapDispatchToProps)(ChestPain)
