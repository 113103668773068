import { connect } from 'react-redux'
import NoResultsFound from './NoResultsFound.jsx'
import { getLookingFor } from '../../../shared/utility-functions/searchParams.js'

const mapStateToProps = (state, ownProps) => ({
  lookingFor: getLookingFor() ? getLookingFor() : state.searchInput.searchQuery,
  flow: ownProps.flow,
  fadSearchedBounds: (state.fadMap && state.fadMap.searchedBounds),
  isSuperResults: ownProps.isSuperResults ? true : false
})

export default connect(mapStateToProps)(NoResultsFound)
