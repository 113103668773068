import { connect } from 'react-redux'
import LookingFor from './LookingFor.jsx'

const mapStateToProps = (state, ownProps) => ({
  setLookingFor: ownProps.setLookingFor,
  lookingFor: ownProps.lookingFor,
  autoCompleteData: state.doctorSearch.autoCompleteData,
  placeholder: ownProps.flow === 'cin' ? 'Name, Specialty, or Practice Name' : 'Doctor, condition or service',
  enterSubmit: ownProps.enterSubmit,
  isMobile: ownProps.mobile
})

export default connect(mapStateToProps)(LookingFor)
