import React from 'react'
import './input-text.scss'

const InputText = ({ value, onFocusHandler, title, onBlurHandler, onChangeHandler, onKeyUpHandler, maxLength = undefined, placeholder = '', inputRef = () => {}, inputId = undefined }) => {
  return <div className='shared-input-text'>
    <input type='text'
      value={value}
      title={title}
      onFocus={onFocusHandler}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      onKeyUp={onKeyUpHandler}
      maxLength={maxLength}
      placeholder={placeholder}
      ref={inputElement => inputRef(inputElement)}
      aria-label={'enter ' + placeholder}
      id={inputId}
    />
  </div>
}

export default InputText
