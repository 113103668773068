import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import multi from 'redux-multi'
import { doctorRoot } from '../../Search/state/root-reducer'
import autocompleteSaga from '../../Search/sagas/fetch-autocomplete-data-saga'
import FADHomeConnector from './FADHomeConnector.jsx'

export default class FADHomeRoot extends React.Component {
  constructor (props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.fadStore = createStore(doctorRoot, composeWithDevTools(applyMiddleware(sagaMiddleware, multi)))
    sagaMiddleware.run(autocompleteSaga)
  }

  render () {
    return (
      <Provider store={this.fadStore}>
        <FADHomeConnector
          linkOneText={this.props.linkOneText} linkOneUrl={this.props.linkOneUrl} linkOneTarget={this.props.linkOneTarget}
          linkTwoText={this.props.linkTwoText} linkTwoUrl={this.props.linkTwoUrl} linkTwoTarget={this.props.linkTwoTarget}
          linkThreeText={this.props.linkThreeText} linkThreeUrl={this.props.linkThreeUrl} linkThreeTarget={this.props.linkThreeTarget}
          linkFourText={this.props.linkFourText} linkFourUrl={this.props.linkFourUrl} linkFourTarget={this.props.linkFourTarget}
        />
      </Provider>
    )
  }
}
