import { race, put, take, delay } from 'redux-saga/effects'
import fetchData from '../../shared/services/fetchData'
import { LONG_TIMEOUT } from '../../shared/constants/wait-times'
import { FAD_AUTOCOMPLETE_ENDPOINT, CIN_AUTOCOMPLETE_ENDPOINT } from '../../shared/constants/api-helpers.js'

export default function * autocompleteSaga (waitTime = LONG_TIMEOUT) {
  while (true) {
    let action = yield take('GET_AUTOCOMPLETE_DATA')
    let params = {}
    if (action.cin) {
      params.endPoint = CIN_AUTOCOMPLETE_ENDPOINT
    } else {
      params.org = action.org ? action.org : ''
      params.addKey = action.addKey ? action.addKey : ''
      params.ids = action.ids ? action.ids : ''
      params.app = action.app ? action.app : false
      params.endPoint = FAD_AUTOCOMPLETE_ENDPOINT
    }

    const { posts } = yield race({
      posts: fetchData(params),
      timeout: delay(waitTime)
    })

    if (posts) {
      yield put({ type: 'SET_AUTOCOMPLETE_DATA', value: posts })
    }

    if (action.resolve) action.resolve()
  }
}
