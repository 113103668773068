import React from 'react'
import './locationWithin.scss'
import Dropdown from '../../../../shared/components/Dropdown'

const RANGE_OPTIONS = [
  { label: '5 Miles', value: 5 },
  { label: '10 Miles', value: 10 },
  { label: '25 Miles', value: 25 },
  { label: '50 Miles', value: 50 },
  { label: '100 Miles', value: 100 },
  { label: '200 Miles', value: 200 }
]

export default class LocationWithin extends React.Component {
  render () {
    const id = this.props.mobile ?  'LOCATION_WITHIN_MOBILE_'+''+this.props.within : 'LOCATION_WITHIN_'+''+this.props.within
    return (
      <div className='within-dropdown'>
        <Dropdown options={RANGE_OPTIONS} currentValue={this.props.within} selectOptionHandler={(value) => this.props.setWithin(value)} disabled={!this.props.address} inputId={id} />
      </div>
    )
  }
}