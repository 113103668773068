import React from 'react'
import SVGInline from 'react-svg-inline'
import './star-bar.scss'
import fullstar from '../../assets/full_star.svg'
import halfstar from '../../assets/half_star.svg'
import emptystar from '../../assets/empty_star.svg'

const MIN_RATING_FOR_HALF_STAR = 0.2
const MAX_RATING_FOR_HALF_STAR = 0.9

export default class StarBar extends React.Component {
  setRating (starRating) {
    let numberOfFullStars = Math.floor(starRating)
    let decimal = Math.round((starRating - numberOfFullStars) * 10) / 10
    this.halfStar = (decimal > MIN_RATING_FOR_HALF_STAR) && (decimal < MAX_RATING_FOR_HALF_STAR)

    if (decimal >= MAX_RATING_FOR_HALF_STAR) { numberOfFullStars++ }

    this.fullStars = numberOfFullStars
    this.emptyStars = 5 - (this.fullStars + (this.halfStar ? 1 : 0))
  }

  render () {
    this.setRating(this.props.rating)

    return (
      <div className='starbar-container'>
        {this.fullStars >= 1 ? <SVGInline className='fullstar' svg={fullstar} /> : ''}
        {this.fullStars >= 2 ? <SVGInline className='fullstar' svg={fullstar} /> : ''}
        {this.fullStars >= 3 ? <SVGInline className='fullstar' svg={fullstar} /> : ''}
        {this.fullStars >= 4 ? <SVGInline className='fullstar' svg={fullstar} /> : ''}
        {this.fullStars === 5 ? <SVGInline className='fullstar' svg={fullstar} /> : ''}
        {this.halfStar ? <SVGInline className='halfstar' svg={halfstar} /> : ''}
        {this.emptyStars >= 1 ? <SVGInline className='emptystar' svg={emptystar} /> : ''}
        {this.emptyStars >= 2 ? <SVGInline className='emptystar' svg={emptystar} /> : ''}
        {this.emptyStars >= 3 ? <SVGInline className='emptystar' svg={emptystar} /> : ''}
        {this.emptyStars >= 4 ? <SVGInline className='emptystar' svg={emptystar} /> : ''}
        {this.emptyStars === 5 ? <SVGInline className='emptystar' svg={emptystar} /> : ''}
      </div>
    )
  }
}
