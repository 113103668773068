import { connect } from 'react-redux'
import Modal from '../Modal'

const mapStateToProps = state => ({
  showModal: state.schedulingModal.showSchedulingModal
})

const mapDispatchToProps = dispatch => ({
  hideFunction: () => {
    dispatch({ type: 'HIDE_SCHEDULING_MODAL' })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
