export const NO_LOCATIONS_MESSAGE = 'No Locations Available'
export const NO_DOCTORS_MESSAGE = 'No Doctors Available'

export const LOCATION_ERROR_MESSAGE = 'We were unable to retrieve service locations.'
export const APPOINTMENT_ERROR_MESSAGE = 'We were unable to retrieve the appointment information.'
export const DOCTOR_ERROR_MESSAGE = 'We were unable to retrieve doctors.'
export const PATIENT_ERROR_MESSAGE = 'We were unable to retrieve your information.'

export const LOADING_LOCATIONS_MESSAGE = 'Loading Service Locations...Please Wait'
export const LOADING_PATIENTS_MESSAGE = 'Loading Patients... Please Wait'
export const LOADING_DOCTORS_MESSAGE = 'Loading Doctors... Please Wait'

export const MAMM_NO_LOCATIONS_FOUND_ERROR_MESSAGE = window.MammogramErrorMessage ? window.MammogramErrorMessage : ''
export const MAMM_NOT_FOUND_LOCATIONS = window.MammogramNotFoundLocations ? window.MammogramNotFoundLocations : ''
export const MAMM_DOB_PROMPT = 'In order to schedule a mammogram online, you must first confirm eligibility.'
export const MAMM_DOB_TEXT = 'Note: This tool is designed to schedule annual screening mammograms. If you would like to schedule a mammogram within 11 months of our previous screening, please contact your physician or insurance provider directly.'

export const APP_MESSAGE_URGENT_CARE = 'On-the-go access to Urgent Care wait times'
export const APP_MESSAGE_DEFAULT = 'On-the-go access to your health'

export const OBGYNReasonsForVisit = {
  'ANNUAL_VISIT': 'Annual Wellness Visit',
  'ABNORMAL_BLEEDING': 'Abnormal Bleeding',
  'BIRTH_CONTROL': 'Birth Control Options',
  'BREAST_PAIN': 'Breast Pain/Lump',
  'MENOPAUSE': 'Menopause',
  'PELVIC_PAIN': 'Pelvic Pain',
  'PREGNANT': 'Positive Pregnancy Test',
  'STD': 'STD/Infection',
  'VAGINAL_ISSUES': 'Vaginal Issues'
}

export const FAKE_OPEN_SCHED_RESPONSE = {
  'ErrorMessage': null,
  'Success': true,
  'Duration': '30',
  'PatientInstructions': 'Please bring a photo ID, insurance card, all medication bottles, and method of payment for any co-pay and or co-insurances.'
}

export const FAKE_MAMM_SCHED_RESPONSE = {
  'ErrorMessage': null,
  'Success': true,
  'Duration': '15',
  'PatientInstructions': 'Please arrive for your procedure 15 minutes prior to your appointment time.\u000a \u000a OhioHealth does not provide childcare services. Children are not permitted in room during procedures.\u000a \u000a On the day of the exam, the patient should not wear underarm deodorant, lotions or powder on the breast or underarm area. She should also wear a two piece outfit and be prepared to give information about prior breast procedures including mammograms, \u000a biopsies or surgeries.\u000a '
}
