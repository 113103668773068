import { connect } from 'react-redux'
import { getLocationCities, getCityFilter } from '../../../selectors/location-search-filter-selectors'
import Dropdown from '../../../../shared/components/Dropdown'

const mapStateToProps = (state, ownProps) => {
  let filterOptions = {}
  if (ownProps.filterType === 'City') {
    filterOptions.options = getLocationCities(state)
    filterOptions.currentValue = getCityFilter(state)
    filterOptions.dispatchType = 'SET_SELECTED_CITY'
    filterOptions.promptText = ownProps.filterType
  }
  return {
    ...filterOptions,
    customClass: 'simple-filter',
    showPrompt: true,
    title: 'Filter by',
    initialValue: 'default-select'
  }
}

const mapDispatchToProps = dispatch => ({
  setKeyFilter: (value, type) => dispatch({ type, value })
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  selectOptionHandler: (value) => {
    dispatchProps.setKeyFilter(value, stateProps.dispatchType)
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Dropdown)
