import { create } from 'apisauce'
const api = create({
  baseURL: window.mohWebServicesUrl
})

const websiteAPI = create({
  baseURL: window.location.origin
})

const mychartServices = create({
  baseURL: window.mychartURL,
  headers: { 'Accept': 'text/html' }
})

export { api, websiteAPI, mychartServices }
export default api
