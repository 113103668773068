import React, { Component } from 'react'
import { fetchAPIData } from '../../../shared/services/fetchData'
import { AVAILABLE_APPOINTMENTS_ENDPOINT } from '../../../shared/constants/api-helpers.js'
import './first-available.scss'

class FirstAvailable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      appointment: null
    }

    this.fetchData = this.fetchData.bind(this)
    this.convertTimeFormat = this.convertTimeFormat.bind(this)
  }

  componentDidMount () {
    if (this.props.departmentID && this.props.resourceID) {
      this.fetchData()
    }
  }

  fetchData () {
    (async () => {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = yyyy + '-' + mm + '-' + dd

      const params = {
        endPoint: AVAILABLE_APPOINTMENTS_ENDPOINT,
        schedulingType: 'Open',
        startDate: today,
        endDate: today,
        departmentId: this.props.departmentID,
        physicianId: this.props.resourceID,
        visitTypeId: '32104'
      }

      const data = await fetchAPIData(params)

      if (data && data.Dates && data.Dates.length) {
        const time = data.Dates[0].Times[0].Time
        const convertedTime = this.convertTimeFormat(time)

        this.setState({ appointment: convertedTime })
      }
    })()
  }

  convertTimeFormat (time) {
    const timeArray = time.split(':')
    const hours24 = parseInt(timeArray[0])
    const hours12 = hours24 % 12 || 12
    const minutes = timeArray[1]
    const ampm = hours24 >= 12 ? 'PM' : 'AM'

    return `${hours12}:${minutes} ${ampm}`
  }

  render () {
    const { appointment } = this.state

    return (
      <React.Fragment>
        {appointment ? (
          <h4 className={this.props.contactCard ? `card-first-available contact-card-first-available` : `card-first-available`}>
            <span className='message'>Next Available Appt:</span>{' '}
            <span className='time'>{appointment}</span>
          </h4>
        ) : <div />
        }
      </React.Fragment>
    )
  }
}

export default FirstAvailable
