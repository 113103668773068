import { connect } from 'react-redux'
import DateFilter from './DateFilter.jsx'
import { getSelectedDate } from '../../../selectors/location-search-filter-selectors'

const mapStateToProps = (state) => ({
  selectedDate: getSelectedDate(state)
})

const mapDispatchToProps = (dispatch) => ({
  setFilter: (date) => dispatch([{
    type: 'SET_SELECTED_DATE',
    value: date
  }, {
    type: 'SCHEDULE_A_SERVICE_DATE_FILTER_CHANGED', value: date
  }])
})

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
