import React, {useEffect} from 'react'
import useToggle from '../../../shared/custom-hooks/useToggle'
import './chip.scss'

const Chip = ({ text, selectHandler, deselectHandler }) => {
  const [selected, toggleSelected] = useToggle(false)

  useEffect(() => {
    selected ? selectHandler(text) : deselectHandler(text)
  }, [selected])

  return <button className={`chip ${selected ? 'selected' : ''}`} onClick={() => toggleSelected()}>{text}</button>
}

export default Chip
