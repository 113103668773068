import React from 'react'
import './no-results-found.scss'
import SVGInline from 'react-svg-inline'
import searchUrl from '../../../shared/utility-functions/searchUrl'
import linkList, { locationList, serviceList } from '../../../shared/constants/commonlySearched'
import MagnifyingGlass from '../../../shared/assets/MagnifyingGlass.svg'

export default class NoResultsFound extends React.Component {
  constructor (props) {
    super(props)
    this.fadNoResults = this.fadNoResults.bind(this)
    this.fadSearchThisAreaNoResults = this.fadSearchThisAreaNoResults.bind(this)
    this.opgNoResults = this.opgNoResults.bind(this)
    this.aptNoResults = this.aptNoResults.bind(this)
    this.type = this.props.flow
    switch (this.props.flow) {
      case 'mam':
        this.type = 'location'
        break
      case 'cin':
        this.type = 'doctor'
        break
      case 'fad':
        this.type = 'doctor'
        break
      case 'sad':
        this.type = 'doctor'
        break
    }
    this.linkList = this.type === 'doctor' ? linkList : (this.type === 'location' ? locationList : serviceList)
  }

  fadNoResults () {
    return (
      <div className='fad-error-container'>
        {this.props.isSuperResults ? <SVGInline className='super-search-icon' svg={MagnifyingGlass} /> : null}
        <h4>No results found {this.props.flow !== 'sad' ? `for "${this.props.lookingFor}"` : ''}</h4>
        <div className='please-consider'>Please consider the following:</div>
        <ul className='fad-suggestions'>
          {this.props.isSuperResults ? '' : <li>Try another term</li>}
          <li>Consider using more general language</li>
          <li>Check spelling</li>
          <li>Select from the list of {this.type === 'location' ? 'Commonly Searched Locations' : (this.type === 'service' ? 'Commonly Searched Services' : 'commonly searched terms')}:</li>
        </ul>

        <ul id='commonly-searched'>
          {
            this.linkList.map((link, index) => {
              return (
                <li key={index}>
                  <a target='_self' href={searchUrl(link)}>{link}</a>
                </li>
              )
            })
          }
          </ul>

        <div className='clearfix' />
      </div>
    )
  }

  fadSearchThisAreaNoResults () {
    return (
      <div className='fad-error-container searched-bounds'>
        {this.props.isSuperResults ? <SVGInline className='super-search-icon' svg={MagnifyingGlass} /> : null}
        <h4>No results found {this.props.flow !== 'sad' ? 'in your search area' : ''}</h4>
        <div className='please-consider'>Please consider the following:</div>
        <ul className='fad-suggestions searched-bounds'>
          <li>Changing or removing some filters</li>
          <li>Adjusting your search area</li>
        </ul>
        <div className='clearfix' />
      </div>
    )
  }

  opgNoResults () {
    return (
      <div className='opg-error-container'>
        <h4>We're sorry! We didn't find any active providers matching your search criteria.</h4>
        <div className='opg-message-container'>
          <div>Remember you are only searching for OhioHealth Physician Group providers, their specialties or practice locations.</div>
          <div>Please consider modifying your search:</div>
          <ul className='suggestions-opg'>
            <li>Check your spelling.</li>
            <li>Enter a term that is valid for the specialty or practice location you are searching within.</li>
            <li>Enter a more general term for a condition (such as "heart" instead of "advanced pediatric heart defects").</li>
          </ul>
        </div>
      </div>
    )
  }

  aptNoResults () {
    return (
      <div className='apt-error-container'>
        <h4>No Results Found</h4>
        <ul className='suggestions-apt' id='search-suggestions'>
          <li>Try another address or zip code</li>
          <li>Try selecting a new date or time filter</li>
          <li>Consider a wider search radius</li>
          <li>Check spelling</li>
        </ul>
      </div>
    )
  }

  render () {
    return (
      <div className='no-results-found-container'>
        {this.props.isBlock ? this.opgNoResults() : (this.props.flow === 'mam' ? this.aptNoResults() : (this.props.fadSearchedBounds !== true) ? this.fadNoResults() : this.fadSearchThisAreaNoResults())}
      </div>
    )
  }
}
