import { connect } from 'react-redux'
import ReviewsDrawer from './ReviewsDrawer.jsx'

const mapStateToProps = (state, ownProps) => {
  if (state.profile && state.profile.doctorDetails) {
    return {
      ...ownProps,
      loadingComments: state.profile.commentsLoading,
      provider: state.profile.doctorDetails,
      commentCallError: state.profile.commentsCallFailed
    }
  } else {
    return {
      ...ownProps,
      provider: null
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProviderComments: () => dispatch({ type: 'FETCH_PROVIDER_COMMENTS' })
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsDrawer)
