import React from 'react'
import SVGInline from 'react-svg-inline'
import checkmarkIcon from '../../../assets/checkmark-in-circle.svg'
import LoadingElement from '../../LoadingElement'

export default class ContinueToScheduling extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showLoader: false
    }
    this.displayLoader = this.displayLoader.bind(this)
  }

  displayLoader () {
    this.setState({ showLoader: true })
  }

  thankYouMessage () {
    return (
      <div className='text-center'>
        <SVGInline style={{color: '#64A70B'}} svg={checkmarkIcon} />
        <div className='modal-text'>
          <h2>Thank you!</h2>
          <div className='sub-heading'>Your answers help us to make sure you get the care you need.</div>
        </div>
        <div className='button-container'>
          <a target='_self' className='btn blue-button continue-button' href={this.props.doctorUrl} onClick={this.displayLoader}>CONTINUE TO SCHEDULING</a>
        </div>
      </div>
    )
  }

  generateLoader () {
    return (
      <div className='modal-text'>
        <LoadingElement />
        <h2>Loading Available Times...</h2>
      </div>
    )
  }

  render () {
    return (
      <div className='scheduling-modal-inner-content'>
        {
          this.state.showLoader ? this.generateLoader() : this.thankYouMessage()
        }
      </div>
    )
  }
}
