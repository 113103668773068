import React from 'react'

export default ({hideFunction, continueButtonFunction}) => (
  <div className='scheduling-modal-inner-content'>
    <div className='modal-text'>
      <h2>Before You Schedule</h2>
      <div className='sub-heading'>
        We have a few questions to ensure you receive the appropriate care.
      </div>
    </div>
    <div className='button-container'>
      <div className='btn gray-button cancel-button' onClick={hideFunction}>
        CANCEL
      </div>
      <div className='btn blue-button continue-button' onClick={continueButtonFunction}>
        CONTINUE
      </div>
    </div>
  </div>
)
