import { getAffiliatedProviderCount, getFilteredProviderCount, getFADSortOptions } from './doctor-search-filter-selectors.js'
import { getServiceSortOptions, getServicesCount } from './service-search-selectors.js'
import { getMAMSortOptions, getFilteredLocationsCount } from './location-search-filter-selectors'

export const getFilterLoadingState = state => divideState(state).loadingFilters
export const getDataLoaded = state => divideState(state).dataLoaded
export const getFilters = state => divideState(state).filters
export const getSort = state => divideState(state).sortOrder
export const getSortOptions = state => helper(state, getFADSortOptions(state), getMAMSortOptions(state), getServiceSortOptions(state))
export const getItemCount = state => divideState(state)
export const getCount = (state) => helper(state, (state.doctorSearch.showAffiliatedDoctors ? getAffiliatedProviderCount(state) : getFilteredProviderCount(state)), getFilteredLocationsCount(state), getServicesCount(state))

const divideState = (state) => helper(state, state.doctorSearch, state.locationSearch, state.serviceSearch)

const helper = (state, doctorCallback, locationsCallback, servicesCallback) => {
  if (state && state.persistentData) {
    switch (state.persistentData.schedulingType) {
      case 'fad':
        return doctorCallback
      case 'sad':
        return doctorCallback
      case 'cin':
        return doctorCallback
      case 'mam':
        return locationsCallback
      case 'location':
        return locationsCallback
      case 'service':
        return servicesCallback
    }
  } else {
    return state
  }
}
