import './info-badge.scss'
import SVGInline from 'react-svg-inline'
import check from '../../assets/check.svg'

const InfoBadge = ({className, text}) => (
  <div className={'info-container ' + (className || '')}>
    <SVGInline className='icon' svg={check} />
    <span className='badge-label'>{text}</span>
  </div>
)

export default InfoBadge
