import { connect } from 'react-redux'
import PreviousPatient from './PreviousPatient.jsx'
import ChestPainConnector from '../SchedulingModal/ChestPain'
import URLS from '../../constants/urls'

const mapStateToProps = state => ({
  doctorUrl: state.schedulingModal.doctorUrl,
  appointmentSelectionPath: window.location.pathname.includes('profile') ? state.schedulingModal.doctorUrl : URLS.appointmentSelectionSADUrl,
  showCardioLoader: state.schedulingModal.showLoader
})

const mapDispatchToProps = dispatch => ({
  hideFunction: () => {
    dispatch({ type: 'HIDE_CARDIO_MODAL' })
  },
  continueButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: ChestPainConnector})
})

export default connect(mapStateToProps, mapDispatchToProps)(PreviousPatient)
