export const INITIAL_STATE = {
  data: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_AVAILABLE_APPOINTMENTS_ENDPOINT_DATA':
      return { ...state, data: action.value }
  }
  return state
}
