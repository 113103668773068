import React from 'react'
import './dropdown.scss'

const Dropdown = ({ title, options, currentValue, showPrompt = false, selectOptionHandler, disabled = false, promptText = 'PLEASE SELECT', promptDisabled = false, promptHidden = false, customClass = '', inputId = undefined, updateInitialValue = () => { return undefined }, initialValue = null }) => {
  return <div className={`dropdown ${customClass}`}>
    <div className='dropdown-label'>{title}</div>
    <select className={`dropdown-select ${currentValue && currentValue.length ? currentValue.replace(/\s/g, '-').toLowerCase() : ''}`} value={currentValue ? currentValue : initialValue} onChange={(event) => changeClick(event, selectOptionHandler, updateInitialValue)} disabled={disabled} aria-label='dropdown' id={inputId}>
      {
        showPrompt && <option className='default-select' value='default-select' selected disabled={promptDisabled} hidden={promptHidden}>{promptText}</option>
      }
      {options && options.length && options.map(renderOption)}
    </select>
  </div>
}

function changeClick (event, selectOptionHandler, updateInitialValue) {
  updateInitialValue()
  selectOptionHandler(event.target.value)
  if (event.target && event.target.blur) {
    event.target.blur()
  }
}

function renderOption (option, index) {
  if (typeof option === 'object') {
    return <option value={option.value} disabled={option.disabled} key={index}>{option.label}</option>
  }
  return <option value={option} key={index}>{option}</option>
}

export default Dropdown
