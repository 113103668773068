import './service-card.scss'

const ServiceCard = ({service}) => {
  return <div className='service-card'>
    <a href={`http://${window.location.hostname}/${service.Url}`}><h3 className='title'>{service.Name}</h3></a>
    <p className='service-description'>{service.Description}</p>
    <a className='card-footer' href={`http://${window.location.hostname}/${service.Url}`}>Learn More</a>
  </div>
}

export default ServiceCard
