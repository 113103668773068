import { connect } from 'react-redux'
import BeforeYouSchedule from './BeforeYouSchedule.jsx'
import ChestPainConnector from '../ChestPain'

const mapDispatchToProps = dispatch => ({
  hideFunction: () => dispatch({ type: 'HIDE_CARDIO_MODAL' }),
  continueButtonFunction: () => dispatch({type: 'SHOW_CONTENT', value: ChestPainConnector})
})

export default connect(null, mapDispatchToProps)(BeforeYouSchedule)
