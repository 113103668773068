import '../ProfileInfo/profile-info.scss'
import URLS from '../../../../../../shared/constants/urls'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import StarBar from '../../../../../../shared/components/StarBar'
import GreenCheckANP from '../../../../../../shared/assets/check_anp.svg'
import SVGInline from 'react-svg-inline'

const ProfileInfo = ({ provider, profilePageURL, cardOptions, years, displayType, reviewsDrawerClick }) => {
  const renderRatings = () => {
    return (provider.rating && provider.rating.Rating) ? (
      <div>
        {
          provider.rating && provider.rating.Rating &&
          <div className='provider-ratings-container'>
            <div className='provider-starbar'>
              <StarBar rating={provider.rating.Rating} />
            </div>
            <div className='provider-ratings' >
              <a className='clickable' onClick={() => { reviewsDrawerClick() }}>{provider.rating.Rating} ({provider.rating.NumberOfRatings})</a>
            </div>
          </div>
        }
      </div>
    ) : null
  }

  return (
    <div className={(displayType && displayType === 'condense') ? 'profile-container-condense' : ''}>
      {/* Profile Image  */}
      <div className={(displayType && displayType === 'condense') ? 'condense' : 'flex justify-center'}>
        <div className='profile-img-provider-wrapper'>
          {
            provider.photoUrl != null
              ? <LazyLoadImage
                class='profile-img-provider'
                alt={provider.title}
                src={provider.photoUrl}
                aria-label={provider.title + ' profile'} />
              : <img className='profile-img-provider' src={URLS.defaultPhotoUrl} />
          }
        </div>
      </div>

      {/* Profile Info  */}
      <div className={(displayType && displayType === 'condense') ? 'provider-info-container condense' : 'provider-info-container'}>
        {provider.isAcceptingPatients &&
          <div className='provider-anp'>
            <SVGInline className='green-check-anp' svg={GreenCheckANP} />
            <span className='provider-text'>Accepting New Patients</span>
          </div>
        }

        <div className='provider-title'>
          <a className='img-profile-url' id={provider.npi} href={profilePageURL} target='_self' aria-label={provider.title + ' profile'}>
            {provider.title}</a>
        </div>

        <div className='provider-specialty-container'>
          {
            provider.specialties && provider.specialties.length > 0 &&
            <span id='profile-info-specialties' className='provider-text specialty'>{provider.specialties.join(', ')}</span>
          }
          {
            years !== null &&
            <span id='profile-info-years' className='provider-text specialty'> • {years <= 1 ? '1 Year of Experience' : `${years} Years of Experience`}</span>
          }
        </div>

        {!cardOptions.hideRatings && renderRatings()}

        <div className='drawer-divider' />
      </div>
    </div>
  )
}

export default ProfileInfo
