import { api, websiteAPI, mychartServices } from './api'
import { PROXIES_AND_PERMISSIONS_ENDPOINT } from '../constants/api-helpers'
const fetchData = async (params) => {
  try {
    let results = await websiteAPI.get(params.endPoint, params)
    return results.data
  } catch (e) {
    return []
  }
}

const fetchDataPost = async (params) => {
  try {
    let results = await websiteAPI.post(params.endPoint, params)
    return results.data
  } catch (e) {
    return []
  }
}

const fetchAPIData = async (params) => {
  try {
    let results = await api.post(params.endPoint, params)
    return params.weekIdentifier ? {...results.data, weekIdentifier: params.weekIdentifier}
    : results.data;
  } catch (e) {
    return []
  }
}

const fetchAPIDataGet = async (params) => {
  try {
    let results = await api.get(params.endPoint, params)
    return results.data
  } catch (e) {
    return []
  }
}

const fetchMyChartData = async (params) => {
  try {
    let results = await mychartServices.get(params.endPoint, params)
    return results.data
  } catch (e) {
    return []
  }
}

const fetchMyChartDataPost = async (params) => {
  try {
    let results = await mychartServices.post(params.endPoint, params)
    return results.data
  } catch (e) {
    return []
  }
}

const fetchProxiesAndPermissions = async (authToken) => {
  let header = {'headers': {'AuthToken': authToken}}
  try {
    let proxyResults = await api.post(PROXIES_AND_PERMISSIONS_ENDPOINT, {}, header)

    return proxyResults.data
  } catch (e) {
    return []
  }
}

export default fetchData
export {fetchData, fetchDataPost, fetchAPIData, fetchAPIDataGet, fetchMyChartData, fetchMyChartDataPost, fetchProxiesAndPermissions}
