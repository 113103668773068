import { connect } from 'react-redux'
import ProviderDrawer from './ProviderDrawer.jsx'

const mapStateToProps = (state, ownProps) => {
  if (state.profile && state.profile.doctorDetails &&
    state.profile.doctorDetails.reviews && state.profile.doctorDetails.reviews.comments) {
    return {
      ...ownProps,
      numberOfReviews: state.profile.doctorDetails.reviews.comments.length || 0
    }
  } else {
    return {
      ...ownProps,
      numberOfReviews: -1
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchProviderComments: () => dispatch({ type: 'FETCH_PROVIDER_COMMENTS' })
})

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDrawer)
