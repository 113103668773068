import React from 'react'
import SVGInline from 'react-svg-inline'
import ArrowRight from '../../../shared/assets/arrow-right.svg'
import './error-modal.scss'

export default class ErrorModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.props.error
  }
  render () {
    return (
      <div className='error-modal'>
        {this.state.icon && <SVGInline svg={this.state.icon} className='error-icon' />}
        <div className='modal-text'>
          <div className='title'>{this.state.title ? this.state.title : 'Notice'}</div>
          <div className='subheader' dangerouslySetInnerHTML={{__html: this.state.text}} />
        </div>
        {this.state.url && this.state.urlTitle &&
          <div className='continue-link'>
            <a href={this.state.url} target='_self'><span className='text'>{this.state.urlTitle}</span> <SVGInline svg={ArrowRight} /></a>
          </div>
        }
      </div>
    )
  }
}
