import { connect } from 'react-redux'
import LookingFor from './LookingFor.jsx'

const mapStateToProps = (state, ownProps) => ({
  setLookingFor: ownProps.setLookingFor,
  lookingFor: ownProps.lookingFor,
  autoCompleteData: state.serviceSearch.autoCompleteDataServices,
  placeholder: 'Enter service, treatment or condition',
  enterSubmit: ownProps.enterSubmit,
  isMobile: ownProps.mobile
})

export default connect(mapStateToProps)(LookingFor)
