import { put, select, takeEvery } from 'redux-saga/effects'
import { locationFilteringDateFetched } from '../selectors/location-search-filter-selectors'

export default function * watchForLocationDateFilterChange () {
  yield takeEvery('SCHEDULE_A_SERVICE_DATE_FILTER_CHANGED', changeLocationFilteringDate)
}

export function * changeLocationFilteringDate (action) {
  if (!action.value) { return }

  const dayHasBeenFetched = yield select(locationFilteringDateFetched)

  if (dayHasBeenFetched) { return }

  yield put({ type: 'FETCH_LOCATION_FILTER_APPOINTMENTS' })
}
