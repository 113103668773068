const loadsData = ({ dataLoaded, dataFetchFailed, generateDataContent, generateLoadingContent, generateErrorContent }) => {
  if (dataFetchFailed) {
    return generateErrorContent()
  } else if (!dataLoaded) {
    return generateLoadingContent()
  } else {
    return generateDataContent()
  }
}

export default loadsData
