import React from 'react'
import './skeleton-service-card.scss'

const SkeletonProviderCard = () => {
  return (
    <div className='service-card skeleton-service'>
      <div className='skeleton' />
    </div>
  )
}

export default SkeletonProviderCard
