import ReactGA from 'react-ga'

ReactGA.initialize('UA-3377925-20')

export const addSearchEvent = (params) => {
  let search = ''
  if (params.q && params.q !== '') {
    search = params.q
  } else if (params.org && params.org !== '') {
    search = 'Org Id ' + params.org
  } else if (params.addKey && params.addKey !== '') {
    search = 'Address Key ' + params.addKey
  } else {
    search = 'no query specified'
  }

  ReactGA.event({
    category: 'Find A Doctor',
    action: 'Search',
    label: `${params.dist || 'Unset'} miles from ${params.locq || 'no address selected'} for ${search}`
  })
}
