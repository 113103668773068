import { connect } from 'react-redux'
import FADHome from './FADHome.jsx'

const mapStateToProps = (state, ownProps) => {
  return ({
    ...ownProps
  })
}

const mapDispatchToProps = (dispatch) => ({
  fetchDoctorData: () => dispatch({
    type: 'GET_AUTOCOMPLETE_DATA',
    org: '',
    addKey: '',
    ids: '',
    app: false
  })
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  initializePage: () => {
    dispatchProps.fetchDoctorData()
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FADHome)
