import '../AreasOfFocus/areas-of-focus.scss'

const AreasOfFocus = ({ provider }) => {
  const pronoun = provider && provider.gender ? (provider.gender.toLowerCase() === 'male' ? 'his' : 'her') : 'the'

  return (
    provider.areaOfFocus && provider.areaOfFocus.length > 0
      ? <div className=''>

        <h4 className='provider-aof-header'>Areas of Focus</h4>
        <div className='provider-aof-list'>
          <ul>
            {provider.areaOfFocus.slice(0, 5).map((element, index) => (<li key={index}>{element}</li>))}
          </ul>
          <p>This may not be a complete list for Dr. {provider.lastName}. Please call {pronoun} office if you have questions.</p>
        </div>

        <div className='flex justify-center'>
          <div className='drawer-divider' />
        </div>
      </div> : null
  )
}

export default AreasOfFocus
