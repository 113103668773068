import '../TimesSection/times-section.scss'
import { useState } from 'react'
import SVGInline from 'react-svg-inline'
import arrowUp from '../../../../../../shared/assets/arrow-up.svg'
import arrowDown from '../../../../../../shared/assets/arrow-down.svg'
import SelectLocationBox from '../../../SelectLocationBox'
import SuperResultsTimesConnector from '../../../../../../Search/components/SearchCards/SuperResultsCard/SuperResultsTimes/SuperResultsTimesConnector.jsx'
import { isOBGYN } from '../../../../../../shared/utility-functions/isOBGYN.js'

const TimesSection = ({ provider, locations, address, timesDrawerClick, preliminaryStatus, setPreliminaryStatus, handleCloseAll, from, setShowQuestions }) => {
  let initialselectedLocation = ''
  let locationList = []

  if (locations && locations.length > 0 && address !== null) {
    locationList = locations
    for (let location of locations) {
      if (Math.abs(address.lat - location.latitude) <= 0.03 && Math.abs(address.lng - location.longitude) <= 0.03) {
        initialselectedLocation = location
      }
    }
    if (initialselectedLocation === '') {
      initialselectedLocation = locations[0]
    }
  }

  const [showSelectLocationBox, setShowSelectLocBox] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(initialselectedLocation)

  return (
    <div className='available-times-container'>
      <h4 className='available-times-locations-header'>Available Appointments</h4>
      { ((preliminaryStatus != null) || (isOBGYN(provider.specialtyIds) === false)) &&
        <div>
          {(locationList && locationList.length > 0)
            ? <div className='location-holder'>
              <div className='available-times-location-label name'>{selectedLocation.departmentName}</div>
              <div className='available-times-location-label street'>{selectedLocation.address.street} {selectedLocation.address.cityStateZip}</div>
            </div>
            : null
          }
          {(locationList && locationList.length > 1)
            ? <div className=' clickable mb-16' onClick={() => { setShowSelectLocBox(!showSelectLocationBox) }}>
              <a className='clickable available-times-location-label change'>Change Location</a>
              {showSelectLocationBox
                ? <SVGInline className='dropdown-arrow' svg={arrowUp} />
                : <SVGInline className='dropdown-arrow' svg={arrowDown} />
              }
            </div>
            : null
          }
          <SelectLocationBox selected={selectedLocation} setShowSelectLocBox={() => setShowSelectLocBox(!showSelectLocationBox)} setLocation={(newLoc) => { setSelectedLocation(newLoc) }} show={showSelectLocationBox} locationList={locationList} />
        </div>
      }

      <SuperResultsTimesConnector provider={provider} from={from} setShowQuestions={setShowQuestions} timesDrawerClick={timesDrawerClick} selectedLocation={selectedLocation} preliminaryStatus={preliminaryStatus} setPreliminaryStatus={setPreliminaryStatus} handleCloseAll={handleCloseAll} />

      <div className='flex justify-center'>
        <div className='drawer-divider' />
      </div>

    </div>

  )
}

export default TimesSection
