import React from 'react'
import './skeleton-table-column.scss'

const SkeletonTableColumn = () => {
  return (
    <div className='skeleton-service'>
      <div className='skeleton' />
    </div>
  )
}

export default SkeletonTableColumn
