import { directionsUrlBuilder } from '../../utility-functions'
import SVGInline from 'react-svg-inline'
import mapMarkerHollow from '../../assets/map-marker.svg'
import mapMarkerSolid from '../../../shared/assets/directions.svg'

const DirectionsLink = ({ className = '', address, displayIcon, text, iconStyle = 'hollow' }) => (
  <a className={className + ' directions'} title={(address && address.street && address.cityStateZip) ? `${address.street} ${address.cityStateZip}` : 'location'} target='_blank' href={directionsUrlBuilder(address)}>{ displayIcon === true ? iconStyle === 'hollow' ? <SVGInline svg={mapMarkerHollow} /> : <SVGInline svg={mapMarkerSolid} /> : '' }{text}</a>
)

export default DirectionsLink
