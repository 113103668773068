import React from 'react'
import { clickToCallEvent } from '../../../utility-functions/googleTagManager'

export default function BeforeYouScheduleOBGYN ({continueButtonFunction, phone, doctor}) {
  const clickToCall = () => {
    const dataLayerObject = {
      flow: 'OBGYN',
      providerPhoneNumber: doctor ? doctor.phone : undefined,
      providerName: doctor ? doctor.title : undefined,
      providerID: doctor ? doctor.id : undefined,
      providerNPI: doctor ? doctor.npi : undefined,
      providerSpecialty: doctor && doctor.specialties ? doctor.specialties.join(', ') : undefined,
      providerAddress: doctor && doctor.address ? doctor.address.adr + ', ' + doctor.address.c + ' ' + doctor.address.s + ' ' + doctor.address.z : undefined,
      facilityName: undefined,
      facilityAddress: undefined
    }

    clickToCallEvent(dataLayerObject)
  }

  return (
    <div className='scheduling-modal-inner-content'>
      <div className='text-center'>
        <div className='modal-text'>
          <h2>Before You Schedule</h2>
          {
            phone
              ? <div className='sub-heading'>Online scheduling is currently only available for gynecology appointments. To schedule or reschedule a pregnancy-related visit with an OB-GYN, please call us at <a href={'tel:' + phone}><span onClick={() => clickToCall()}>{phone}</span></a>.</div>
              : <div className='sub-heading'>Online scheduling is currently only available for gynecology appointments. To schedule or reschedule a pregnancy-related visit with an OB-GYN, please call us.</div>
          }
        </div>
        <div className='button-container'>
          <a target='_self' className='btn blue-button continue-button' onClick={continueButtonFunction}>CONTINUE</a>
        </div>
      </div>
    </div>
  )
}
